import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyOwnerComponent } from '../property-owner/property-owner.component';
import { MatDialog } from '@angular/material/dialog';
import { ShareModalComponent } from '../share-modal/share-modal.component';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
})
export class PropertyCardComponent implements OnInit {
  @Input() listing: any;
  @Input() editButton: boolean;
  @Input()shareButton :boolean;
  data: any;
  id: number;
  isButtonEnabled: boolean = false;
  Images: string[] = [];
  pricePerUnit: any;
  isPopupOpen :boolean=false; 
  isLogedIn:any;
  constructor(
    private dialog: MatDialog,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @Input() payload: any;

  // get pricePerSqft(): string {
  //   const asking = Number(this.listing.asking);
  //   const sizeString = this.listing.size || '0';
  
  //   // Extract numerical value from sizeString
  //   const sizeMatch = sizeString.match(/[\d.]+/);
  //   const size = sizeMatch ? parseFloat(sizeMatch[0]) : 0;
  
  //   if (size > 0) {
  //     const pricePerUnit = Math.floor(asking / size);
  //     return `${pricePerUnit.toLocaleString()} ${sizeString.replace(/[\d.]+/, '').trim()}`;
  //   } else {
  //     return '0';
  //   }
  // }
  // calculatePricePerUnit() {
  //   if (this.listing && this.listing.size && this.listing.asking) {
  //     let askingInUnits = this.listing.asking;
  //     if (this.listing.feature.askingUnit === 'Cr') {
  //       askingInUnits *= 100; // 1 crore = 100 lacs
  //     }
  //     this.pricePerUnit = (askingInUnits / this.listing.size).toFixed(2);
  //     console.log(this.pricePerUnit);
      
  //   }
  // }
  
  
  showMore: boolean = false;

  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }
  askingValue:any
  ngOnInit(): void {
     this.isLogedIn = localStorage.getItem('auth-token');
    if (this.listing) {
      if (this.listing.feature.askingUnit=='Lac') {
        this.askingValue = this.listing.asking;
      } else if (this.listing.feature.askingUnit=='Cr' ) {
        this.askingValue = this.listing.asking ;
      }
    } else {
      console.warn('Feature data is not available');
    }
    if (this.listing && this.listing.mediaGroup) {
      this.Images = this.listing.mediaGroup.map((item) => item.url);
    }
  }

  
  defaultImage: string = 'https://example.com/path/to/default-image.jpg';
 openShareModal(listing:any): void {
  console.log('listing',listing);
  
  this.dialog.open(ShareModalComponent, {
    width: '440px',
    panelClass: 'custom-blue-background',
    data: {
      // listingUrl: this.listingUrl,
      listingTitle: this.listing.title,
      listingDescription: this.listing.description,
      listingArea:this.listing.area,
      listingPrice:this.listing.asking,
      listingaddress:this.listing.address,
      listingcityName:this.listing.cityName,
      listingpinCode:this.listing.pinCode,
      listingImage: this.Images.length > 0 ? this.Images[0] : this.defaultImage,
    }
  });
  }
  closeSharePopup() {
    this.isPopupOpen = false;
  }

  navigateToDetail(id: number): void {
    if(this.isLogedIn ){
      this.router.navigate(['/property-details'], { queryParams: { id: id } });
    }
    else{
      this.router.navigate(['/property-details'], { queryParams: { id: id } });
    }
  }
  editDetail(id: number){
    this.router.navigate(['/add-property'], { queryParams: { id: id } });
  }

}
