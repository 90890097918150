<div class="page-container">
  <app-header></app-header>

  <div class="container">
    <div class="faq-img row">
      <div class="faq-khubii-head col-md-6 col-lg-6 mb-6">
        <h3 mat-title class="text-center mb-4 pb-2 faq-text-color fw-bold" style="font-size: 25px; letter-spacing: 2px;">
          G3G FAQ
        </h3>
        <p mat-subtitle class="text-center" style="font-size: 20px; font-family: 'Roboto', sans-serif;">
          Everything you need to know so that you can use G3G as an Expert
        </p>
      </div>
      <div class="col-md-6 col-lg-6 mb-6">
        <img src="../../assets/images/faq-img.png" width="100%" alt="FAQ Image">
      </div>
    </div>

    <div class="p-5 row">
      <mat-accordion multi>
        <!-- Getting Started Section -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-quora faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;How to Sign up to G3G?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item (click)="toggleDropdown('dropdown1')">
                <h4 mat-line class="faq-text-color">Signup by clicking on the Signup option on the “TOP RIGHT” corner of the screen. In a new screen, fill in basic details, and after authentication, you can log in with new credentials.
                  In a new screen, fill in basic details, and after authentication, you can log in with new credentials.
                </h4>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-ravelry faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;Key steps to Quickly start using
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item (click)="toggleDropdown('dropdown1')">
                <ul>
                  <li>Login to the application</li>
                  <li>In the Update profile section add
                    <ul>
                      <li>Small and big profile image for Digital Web Page</li>
                      <li>Update Summary and Social media accounts</li>
                      <li>In the professional tab on the left side add
                        <ol>
                          <li>Bio</li>
                          <li>YouTube reel</li>
                          <li>Expertise</li>
                        </ol>
                      </li>
                    </ul>
                  </li>
                  <li>Upload Artwork</li>
                  <li>Your digital portfolio will automatically be created and available in the public portfolio tab when you click on your name</li>
                </ul>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-area-chart faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;G3G: For artists & brands alike.
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item (click)="toggleDropdown('dropdown3')">
                <h4 mat-line class="faq-text-color"> G3G supports both “BRAND & INDIVIDUAL” as both are talented and we only believe in supporting all talent.</h4>
              </mat-list-item>
            

            

            
              

            </mat-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-university faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;G3G boosts your talent
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item (click)="toggleDropdown('dropdown3')">
                <h4 mat-line class="faq-text-color"><p>G3G will help you by:</p>
                  <ul>
                    <li>Providing a dedicated web page for your talent which can be shared as a URL</li>
                    <li>Marketing your talent to a “GLOBAL AUDIENCE”</li>
                    <li>Connecting you to opportunities</li>
                    <li>Helping in expanding your network</li>
                  </ul></h4>
              </mat-list-item>
              
            </mat-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-grav faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;G3G offers brand packages!
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item (click)="toggleDropdown('dropdown3')">
                <h4 mat-line class="faq-text-color">
                  <p>
                    Unfortunately no. Yes, all BRANDs please connect with the support team at
                    <a href="mailto:info@G3G.com">info@G3G.com</a> and they will provide all information regarding the same.
                  </p>
                </h4>
              </mat-list-item>
              <div *ngIf="isOpen['dropdown3']" class="dropdown-content">
                <p>
                  G3G supports both “BRAND & INDIVIDUAL” as both are talented and we only believe in supporting all talent.
                </p>
              </div>

           

         
            </mat-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fas fa-user faq-text-color pe-2 " style="color: #004aad;"></i>&nbsp;&nbsp;Logic for artist
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item (click)="toggleDropdown('dropdown3')">
                <h4 mat-line class="faq-text-color">  <p>
                  Profile should have a complete profile image & summary & artwork / should have either
                  logged in or updated the profile in the last 7 days.
                </p></h4>
              </mat-list-item>
        
            </mat-list>
          </div>
        </mat-expansion-panel>
     
      </mat-accordion>
    </div>
  </div>

  <app-footer></app-footer>
</div>
