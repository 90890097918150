import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { HomeService } from '../_services/home.service';
@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.scss']
})
export class ProfileUpdateComponent implements OnInit {
  profilePic: string = '../assets/images/profile.png';
  updateform: FormGroup;
  submitted = false;
  isreadonly: boolean = false;
  aciveforgot = false;
  profileid: any
  constructor(private formBuilder: FormBuilder, private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    const profileid2 = localStorage.getItem('auth-user')
    const profileid1 = JSON.parse(profileid2)
    const profileids = JSON.parse(profileid1)
    this.profileid = profileids.id;
    this.getProfileById(this.profileid)
    this.initializeForm();
  }

  initializeForm() {
    this.updateform = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required]],
      password: [''],
      aboutMe: [''],
      userType: [null],
      deleteAccount: [0],
      accountEnabled: [1]
    });
  }

  forgotSwitch() {
    this.aciveforgot = true;
  }
  navigateToReset() {
    const email = this.updateform.get('email')?.value;
    this.router.navigate(['/resetpassword'], { queryParams: { email } });
  }


  get f() { return this.updateform.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.updateform.invalid) {
      return;
    }

    const payload = this.updateform.value;
    const userType = payload.userType;
    const userTypePayload = userType ? { [userType]: true } : {};
    console.log('userTypePayloaduserTypePayload', userTypePayload)
    delete payload.userType;
    delete payload.password;
    const finalPayload = {
      ...payload,
      ...userTypePayload
    };
    console.log("payload after cleaning", finalPayload);
    this.updateprofile(finalPayload);
  }
  getProfileById(profileid) {
    this.profileService.getProfileById(profileid).subscribe(
      response => {
        if (response['status'] === 'SUCCESS') {
          const parsedProfileData = response['payload'];
          let selectedUserType: string | null = null;
          if (parsedProfileData.builder) {
            selectedUserType = 'builder';
          } else if (parsedProfileData.agent) {
            selectedUserType = 'agent';
          } else if (parsedProfileData.individual) {
            selectedUserType = 'individual';
          }
          const aboutMeValue = parsedProfileData?.aboutMe ?? '';
          console.log("aboutMeValue", aboutMeValue);
          this.updateform.patchValue({
            fullName: parsedProfileData.fullName ?? '',
            email: parsedProfileData.email ?? '',
            mobileNumber: parsedProfileData.mobileNumber ?? '',
            aboutMe: aboutMeValue,
            userType: selectedUserType,
            deleteAccount: parsedProfileData.deleteAccount ?? 0,
            accountEnabled: parsedProfileData.accountEnabled ?? 1
          });
        }
      },
      error => {
        console.error('Error updating profile:', error);
      }
    );
  }

  updateprofile(payload: any) {
    this.profileService.updateprofile(payload).subscribe(
      response => {
        if (response['status'] === 'SUCCESS') {
          console.log('Profile updated successfully!');
          const updatedProfileData = {
            ...JSON.parse(localStorage.getItem('auth-user')),
            ...payload
          };
          localStorage.setItem('auth-user', JSON.stringify(updatedProfileData));

          Swal.fire({
            icon: 'success',
            title: 'Profile updated successfully!',
            showConfirmButton: false,
            timer: environmentbasic.timer
          })
          window.location.href = '/';
          // window.history.back();


        } else {
          Swal.fire({
            icon: 'error',
            title: response['message'],
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          console.error('Profile update failed:', response['message']);
        }
      },
      error => {
        console.error('Error updating profile:', error);
      }
    );
  }
  userLogout() {
    this.tokenStorage.signOut();
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }
}
