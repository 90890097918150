import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service'
@Component({
  selector: 'app-privacy-polity',
  templateUrl: './privacy-polity.component.html',
  styleUrls: ['./privacy-polity.component.scss']
})
export class PrivacyPolityComponent implements OnInit {
  privacyContent: string | null = null;
  projectName: string = "Property";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homeService: HomeService,
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.getprofession_details(this.projectName);
  }

  getprofession_details(projectName: string): void {
    this.loadingService.show()
    this.homeService.getprofession_details(projectName).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          const privacyPolicies = response.payload.filter((item: any) => item.type === 'PrivacyPolicy');
          const latestPrivacyPolicy = privacyPolicies[privacyPolicies.length - 1];
          this.loadingService.hide()
          if (latestPrivacyPolicy) {
            this.privacyContent = latestPrivacyPolicy.content;
          } else {
            console.error('No PrivacyPolicy found in the response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
}
