

<div class="wrapper">
  <app-header></app-header>
  <app-menu></app-menu>
  <div class="col-md-12 content">
    <div class="row justify-content-center align-items-center mt-4">
      <div class="col-md-9 d-flex justify-content-between justify-content-center align-items-center textstyle" *ngIf="showButton !== false">
        <h2 class="" >My Inventory</h2>
        <button class="btn btn-primary"   (click)="navigateToAddInventory()" >Add Inventory</button>
    </div>
    <div class="col-md-9 d-flex justify-content-between justify-content-center align-items-center textstyle" *ngIf="showButton == false">
      <h2 class="" >Project Inventory</h2>
  </div>
      <div class="col-12 col-md-10 text-center">
        <div class="search-container ">
          <div class="search-row ">
            <mat-icon class="search-icon">search</mat-icon>
            <input type="text" class="search-input" placeholder="Search Your Inventory" [(ngModel)]="searchValue"
              (keyup)="searchListings()" />
            <button class="search-button" (click)="searchListings()">Search</button>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-10 ">
        <ng-container *ngIf="filteredListings?.length > 0;">
          <div class="listings-container">
            <ng-container *ngIf="loading$ | async; else showListings">
              <app-loading></app-loading>
            </ng-container>
            <ng-template #showListings>
              <ng-container *ngFor="let cardinventory of filteredListings">
                <app-property-card [cardinventory]="cardinventory" [showEditDetailsButton]="false" [showViewDetailsButton]="true"
                [showInventoryButton]="false" [showbugget]="false" [showdetailsbutton]="false"
                [showEditInventoryButton]="showButton !== false">
              </app-property-card>
              </ng-container>
            </ng-template>

          </div>
          <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
            ⬆
          </div>
        </ng-container>



      </div>
    </div>

    <div *ngIf="!(loading$ | async) && filteredListings.length === 0">
      <app-not-found></app-not-found>
    </div>
    <br><br>

    <!-- <ng-template #noListings>
      <app-not-found></app-not-found>
    </ng-template> -->
    <div class="pagination-container" *ngIf="!(loading$ | async) && filteredListings.length > 0">
      <span class="page-info">Page {{ currentPage + 1 }} of {{ totalPages }}</span>

      <div class="pagination">
        <button class="page-link" (click)="changePage(currentPage - 1)" [disabled]="currentPage === 0">
          &lt; Previous Page
        </button>

        <ng-container *ngFor="let page of getPages()">
          <button class="page-number" [class.active]="currentPage === page" (click)="changePage(page)">
            {{ page + 1 }}
          </button>
        </ng-container>

        <button class="page-link" (click)="changePage(currentPage + 1)" [disabled]="currentPage + 1 >= totalPages">
          Next Page &gt;
        </button>
      </div>
    </div>
  </div>
  <br />


  <app-footer></app-footer>
</div>
