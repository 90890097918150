<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>
    <div class="master master12 py-1 px-xxl-4 px-3">

      <div class="page-heading d-flex justify-content-between align-items-center mt-4">
        <!-- <span class="text-start " style="color: #004AAD;  font-family: 'Roboto', sans-serif;">My Inventory</span> -->
        <h1 class="titles">My Inventory</h1>
        <button class="btn btn-primary ms-auto onlyhover " style=" font-family: 'Roboto', sans-serif;" (click)="navigateToAddInventory()">Add Inventory</button>
    </div>
    
    <!-- <div style="margin-top: 30px;">
      <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
    </div>
       -->
    <div style="cursor: pointer;" class="card-container" *ngIf="topList.length > 0">
      <ng-container *ngFor="let card of topList">
        <app-top-property-card
          [cardData]="card"
          [showEditDetailsButton]="false"
          [showViewDetailsButton]="true"
          [showInventoryButton]="false"
          [showbugget]="false"
          [showdetailsbutton]="false"
          [showEditInventoryButton]="showButton !== false">
        </app-top-property-card>
      </ng-container>
    </div>
    
        <div *ngIf="topList.length === 0">
          <app-not-found-invetory ></app-not-found-invetory>
          <!-- <p>No projects available.</p> -->
        </div>
  
      
  
  </div>
</div>
  <app-footer></app-footer>
</div>
