
import { Component, OnInit } from '@angular/core';
import { ContactSellerComponent } from '../contact-seller/contact-seller.component';
import { MatDialog } from '@angular/material/dialog';
import { TopProjectService } from '../_services/top-project.service';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../_services/home.service';
import { PropertyOwnerComponent } from '../property-owner/property-owner.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-inventory',
  templateUrl: './view-inventory.component.html',
  styleUrls: ['./view-inventory.component.scss']
})
export class ViewInventoryComponent implements OnInit {
  listingData: any;
  amenities: { icon: string; label: string; enabled: boolean }[] = [];
  projectId: string | null = null;
  listing: any;
  cardData: any;
  toplist: any;
  type2 = 2;

  title: any = '';
  showAll = false;
  fromPrice: number | null = null;
  toPrice: number | null = null;
  location: any = '';
  cityName: any = '';
  stateName: any = '';
  isExpanded: boolean = true;
  Images: string[] = [];
  inventoryType: any = '';
  status: any = '';
  loanAvailable: any = '';
  totalInventory: any = '';
  sizeOfProperty: any = '';
  titleDeed: any = '';
  area: any = '';
  soilQuality: any = '';
  physicalVisit: any = '';
  propertyLocation: any = '';
  size: any = '';
  pricing: any = '';
  projectName: any = '';
  reraApproved: any = '';
  reraCompliance: any = '';
  builder: any = '';
  connectivity: any = '';
  constructionApprovals: any = '';
  flatPurchaseChecklist: any = '';
  investmentPerspective: any = '';
  security: any = '';
  accessTo: any = '';
  zoningRisks: any = '';
  verification: any = '';
  modernFeatures: any = '';
  noObjectionCertificates: any = ''
  marketResearch: any = '';
  outdoor: any = '';
  smartHomes: any = '';
  infrastructure: any = '';
  highEndKitchens: any = '';
  floorPlan: any = '';
  description: any
  apartmentPossession: any = '';
  // carpetSize: any = '';
  totalSize: any = '';
  sizeUnit: any = '';
  isNegotiable: any = '';
  commercialPropertyTypes: any = '';
  realEstateDeveloper: any = '';
  leaseStructure: any = '';
  rightBuilder: any = '';
  riskAndReturn: any = '';
  valueAssessment: any = '';
  rentalIncome: any = '';
  tenantProfiles: any = '';
  serviceTax: any = ''
  encumbranceCertificate: any = '';
  propertyTaxes: any = '';
  uniformityOfThePlot: any = '';
  completionCertificate: any = '';
  accessToThePlot: any = '';
  avoidVerbalAgreement: any = '';
  naOrder: any = '';
  powerOfAttorney: any = '';
  waterElectricitySupply: any = '';
  approvals: any = '';
  dueDiligence: any = '';
  encumbrance: any = '';
  historyOfTheProperty: any = '';
  cashFlow: any = ''
  legalDocuments: any = '';
  occupancyCertificate: any = ''
  saleAgreement: any
  amenity: string = '';
  locationConsiderations: string = '';
  accessibility: string = '';
  createdAt:Date
  // budget: number = 0;
  homeLoan: string = '';
  constructionQuality: string = '';
  outdoorAmenities: string = '';
  privacyAndSpace: string = '';
  legalCompliance: string = '';
  builderReputation: string = '';
  documentsForPropertyUse: string = '';
  landDocuments: string = '';
  investmentGoals: any = '';
  financialConsiderations: any = '';
  taxPaymentStatus: any = '';
  propertyDocumentation: any = ''
  trustedDeveloper: any = ''
  resaleValue: any = ''
  landUsePermissions: any = ''
  builderBuyerAgreement: any = ''
  verbalAgreement: any = ''
  titleVerification: any = ''
  legalCheckOfProperty: any = ''
  reputedBuilders: any = ''
  bankNOC: any = '';
  areaOfFlat: any = '';
  budget: any = '';
  projectidinven:any
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private topProjectService: TopProjectService
  ) { }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.queryParamMap.get('id');
    
    if (this.projectId) {
      this.homeService.getInventoryById(this.projectId).subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS' && response.payload) {

            this.amenity = response.payload?.projectFeature?.amenity || '';
            console.log("this.amenity", this.amenity)
            this.initializeAmenities();
            
            this.title = response.payload.title || '';
            this.isNegotiable = response.payload.isNegotiable || '';
            this.inventoryType = response.payload?.inventoryType || '';
            this.totalSize = response.payload.totalSize || '';
            // this.carpetSize = response.payload.carpetSize || '';
            this.sizeUnit = response.payload.sizeUnit || '';
            if (response.payload.inventoryImage && response.payload.inventoryImage.length > 0) {
              this.Images = response.payload.inventoryImage.map((image: any) => image.url || '');
            }
            // feature plote
            this.titleDeed = response.payload?.projectFeature?.titleDeed || '';
            this.encumbranceCertificate = response.payload?.projectFeature?.encumbranceCertificate || '';
            this.propertyTaxes = response.payload?.projectFeature?.propertyTaxes || '';
            this.uniformityOfThePlot = response.payload?.projectFeature?.uniformityOfThePlot || '';
            this.completionCertificate = response.payload?.projectFeature?.completionCertificate || '';
            this.soilQuality = response.payload?.projectFeature?.soilQuality || '';
            this.accessToThePlot = response.payload?.projectFeature?.accessToThePlot || '';
            this.avoidVerbalAgreement = response.payload?.projectFeature?.avoidVerbalAgreement || '';
            this.builder = response.payload?.projectFeature?.builder || '';
            this.connectivity = response.payload?.projectFeature?.connectivity || '';
            this.constructionApprovals = response.payload?.projectFeature?.constructionApprovals || '';
            this.flatPurchaseChecklist = response.payload?.projectFeature?.flatPurchaseChecklist || '';
            this.investmentPerspective = response.payload?.projectFeature?.investmentPerspective || '';
            this.naOrder = response.payload?.projectFeature?.naOrder || '';
            this.powerOfAttorney = response.payload?.projectFeature?.powerOfAttorney || '';
            this.security = response.payload?.projectFeature?.security || '';
            this.waterElectricitySupply = response.payload?.projectFeature?.waterElectricitySupply || '';
            this.zoningRisks = response.payload?.projectFeature?.zoningRisks || '';
            this.approvals = response.payload?.projectFeature?.approvals || '';
            this.dueDiligence = response.payload?.projectFeature?.encumbrance || '';
            this.encumbrance = response.payload?.projectFeature?.encumbrance || '';
            this.historyOfTheProperty = response.payload?.projectFeature?.historyOfTheProperty || '';
            this.occupancyCertificate = response.payload?.projectFeature?.occupancyCertificate || '';
            this.saleAgreement = response.payload?.projectFeature?.saleAgreement || '';
            this.resaleValue = response.payload?.projectFeature?.resaleValue || '';
            this.landUsePermissions = response.payload?.projectFeature?.landUsePermissions || '';
            // feature flate

            this.encumbranceCertificate = response.payload?.projectFeature?.encumbranceCertificate || '';
            this.propertyTaxes = response.payload?.projectFeature?.propertyTaxes || '';
            this.location = response.payload?.projectFeature?.location || ''; // Ensure to set this
            this.uniformityOfThePlot = response.payload?.projectFeature?.uniformityOfThePlot || '';
            this.completionCertificate = response.payload?.projectFeature?.completionCertificate || '';
            this.soilQuality = response.payload?.projectFeature?.soilQuality || '';
            this.accessToThePlot = response.payload?.projectFeature?.accessToThePlot || '';
            this.avoidVerbalAgreement = response.payload?.projectFeature?.avoidVerbalAgreement || '';
            this.builder = response.payload?.projectFeature?.builder || '';
            this.connectivity = response.payload?.projectFeature?.connectivity || '';
            this.flatPurchaseChecklist = response.payload?.projectFeature?.flatPurchaseChecklist || '';
            this.naOrder = response.payload?.projectFeature?.naOrder || '';
            this.powerOfAttorney = response.payload?.projectFeature?.powerOfAttorney || '';
            this.waterElectricitySupply = response.payload?.projectFeature?.waterElectricitySupply || '';
            this.zoningRisks = response.payload?.projectFeature?.zoningRisks || '';
            this.approvals = response.payload?.projectFeature?.approvals || '';
            this.dueDiligence = response.payload?.projectFeature?.dueDiligence || ''; // Adjust as needed
            this.encumbrance = response.payload?.projectFeature?.encumbrance || '';
            // this.historyOfTheProperty = response.payload?.projectFeature?.historyOfTheProperty || '';
            this.serviceTax = response.payload?.projectFeature?.serviceTax || '';

            // feature Villas

            this.realEstateDeveloper = response.payload?.projectFeature?.realEstateDeveloper || '';
            this.locationConsiderations = response.payload?.projectFeature?.locationConsiderations || '';
            this.accessibility = response.payload?.projectFeature?.accessibility || '';
            this.budget = response.payload?.projectFeature?.budget || 0;
            this.propertyTaxes = response.payload?.projectFeature?.propertyTaxes || 0;
            this.homeLoan = response.payload?.projectFeature?.homeLoan || '';
            this.constructionQuality = response.payload?.projectFeature?.constructionQuality || '';
            this.modernFeatures = response.payload?.projectFeature?.modernFeatures || '';
            this.privacyAndSpace = response.payload?.projectFeature?.privacyAndSpace || '';
            this.legalCompliance = response.payload?.projectFeature?.legalCompliance || '';
            this.builderReputation = response.payload?.projectFeature?.builderReputation || '';
            this.documentsForPropertyUse = response.payload?.projectFeature?.documentsForPropertyUse || '';
            this.landDocuments = response.payload?.projectFeature?.landDocuments || '';
            this.noObjectionCertificates = response.payload?.projectFeature?.noObjectionCertificates || '';
            this.pricing = response.payload?.projectFeature?.pricing || 0;
            this.sizeOfProperty = response.payload?.projectFeature?.sizeOfProperty || '';
            this.projectidinven = response.payload?.project?.id || '';
            console.log("this.projectidinven",this.projectidinven)




            // feature commercial
            this.marketResearch = response.payload?.projectFeature?.marketResearch || '';
            this.commercialPropertyTypes = response.payload?.projectFeature?.commercialPropertyTypes || '';
            this.floorPlan = response.payload?.projectFeature?.floorPlan || '';
            this.leaseStructure = response.payload?.projectFeature?.leaseStructure || '';
            this.rightBuilder = response.payload?.projectFeature?.rightBuilder || '';
            this.riskAndReturn = response.payload?.projectFeature?.riskAndReturn || '';
            this.valueAssessment = response.payload?.projectFeature?.valueAssessment || '';
            this.rentalIncome = response.payload?.projectFeature?.rentalIncome || '';
            this.tenantProfiles = response.payload?.projectFeature?.tenantProfiles || '';
            // feature office
            this.investmentGoals = response.payload?.projectFeature?.investmentGoals || '';
            this.cashFlow = response.payload?.projectFeature?.cashFlow || '';
            this.legalDocuments = response.payload?.projectFeature?.legalDocuments || '';
            this.financialConsiderations = response.payload?.projectFeature?.financialConsiderations || '';
            this.infrastructure = response.payload?.projectFeature?.infrastructure || '';
            this.leaseStructure = response.payload?.projectFeature?.leaseStructure || '';
            this.marketResearch = response.payload?.projectFeature?.marketResearch || '';
            this.taxPaymentStatus = response.payload?.projectFeature?.taxPaymentStatus || '';
            this.valueAssessment = response.payload?.projectFeature?.valueAssessment || '';
            this.propertyDocumentation = response.payload?.projectFeature?.propertyDocumentation || '';
            this.rightBuilder = response.payload?.projectFeature?.rightBuilder || '';
            this.propertyLocation = response.payload?.projectFeature?.propertyLocation || '';
            this.createdAt = response.payload?.createdAt || '';
            

            // feature mansion
            this.trustedDeveloper = response.payload?.projectFeature?.trustedDeveloper || '';
            this.documentsForPropertyUse = response.payload?.projectFeature?.documentsForPropertyUse || '';
            this.highEndKitchens = response.payload?.projectFeature?.highEndKitchens || '';
            this.smartHomes = response.payload?.projectFeature?.smartHomes || '';



            this.fromPrice = response.payload.fromPrice || null;
            this.toPrice = response.payload.toPrice || null;
            this.cityName = response.payload.cityName || '';
            this.stateName = response.payload.stateName || '';
            this.inventoryType = response.payload?.inventoryType || '';

            this.loanAvailable = response.payload?.loanAvailable || '';

            this.totalInventory = response.payload?.totalInventory || '';
            this.area = response.payload?.projectBuilder?.area || '';
            this.soilQuality = response.payload?.projectfeatures?.soilQuality || '';
            this.physicalVisit = response.payload?.projectfeatures?.physicalVisit || '';
            this.size = response.payload?.projectfeatures?.size || '';
            this.projectName = response.payload?.projectName || '';
            this.reraCompliance = response.payload?.projectfeatures?.reraCompliance || '';
            this.reraApproved = response.payload?.reraApproved || '';
            this.outdoor = response.payload?.projectfeatures?.outdoor || '';
            // this.smartHomes = response.payload?.projectfeatures?.smartHomes || '';
            this.infrastructure = response.payload?.projectfeatures?.infrastructure || '';
            // this.floorPlan = response.payload?.projectfeatures?.floorPlan || '';
            this.builder = response.payload?.projectfeatures?.builder || '';
            this.connectivity = response.payload?.projectfeatures?.connectivity || '';
            this.flatPurchaseChecklist = response.payload?.projectfeatures?.flatPurchaseChecklist || '';
            this.accessTo = response.payload?.projectfeatures?.accessTo || '';
            this.zoningRisks = response.payload?.projectfeatures?.zoningRisks || '';
            this.verification = response.payload?.projectfeatures?.verification || '';
            this.noObjectionCertificates = response.payload?.projectfeatures?.noObjectionCertificates || '';
            // this.marketResearch = response.payload?.projectfeatures?.marketResearch || '';
            this.description = response.payload?.description || '';
            this.listingData = response.payload?.projectBuilder || '';
            this.apartmentPossession = response.payload?.projectfeatures?.apartmentPossession || '';


          }
        }
      );
    }

    console.log("Project ID:", this.projectId);
  }


  inventory(event: Event) {
    const propertyId = this.route.snapshot.queryParamMap.get('id');
    event.stopPropagation();
    this.router.navigate(['/project-invetory'], { queryParams: { projectId: propertyId } });
    // }
  }
  parseDate(dateString: string): Date {
    const parts = dateString.split(' ');
    const dateParts = parts[0].split('-');
    const timeParts = parts[1].split(':');
    return new Date(
      parseInt(dateParts[2], 10),
      parseInt(dateParts[1], 10) - 1,
      parseInt(dateParts[0], 10),
      parseInt(timeParts[0], 10),
      parseInt(timeParts[1], 10),
      parseInt(timeParts[2], 10)
    );

  }
  openContactDetailsDialog(listingData): void {
    const lastdate = localStorage.getItem('auth-user');
    if (lastdate) {
      try {
        const lastdatedata = JSON.parse(lastdate);
        const lastdatedata1 = JSON.parse(lastdatedata);
        const lastLoginString = lastdatedata1.lastLogin;
        console.log("lastLogin", lastLoginString);
        const lastLogin = this.parseDate(lastLoginString);
        const currentTime = new Date();
        if (lastLogin) {
          const adjustedTime = new Date(currentTime.getTime() - (5 * 60 * 60 * 1000) - (30 * 60 * 1000));
          const timeDifferenceInSeconds = Math.floor((adjustedTime.getTime() - lastLogin.getTime()) / 1000);
          console.log("count 0 to 1800", timeDifferenceInSeconds);
          if (timeDifferenceInSeconds <= 1800) {
            this.dialog.open(PropertyOwnerComponent, {

              data: listingData,
              width: '830px',
              height: '500px'
            });
          }
          else {
            console.error('Invalid date parsed:', lastLogin);
            this.router.navigate(['/login']);
          }
        }
      } catch (error) {
        console.error('Error parsing lastdate:', error);
        this.router.navigate(['/login']);
      }
    } else {
      console.log('User is not logged in.');
      this.router.navigate(['/login']);
    }
  }

  opencontactceller(): void {
    this.dialog.open(ContactSellerComponent, {
      width: '540px',
      height: '670px',
    });
  }
  initializeAmenities(): void {
    this.amenities = [
      { icon: 'pool', label: 'Swimming Pool', enabled: this.isAmenityEnabled('pool') },
      { icon: 'fitness_center', label: 'Gym', enabled: this.isAmenityEnabled('gym') },
      { icon: 'nature_people', label: 'Garden', enabled: this.isAmenityEnabled('garden') },
      { icon: 'local_parking', label: 'Parking', enabled: this.isAmenityEnabled('parking') },
      { icon: 'security', label: 'Security', enabled: this.isAmenityEnabled('security') },
      { icon: 'wifi', label: 'Wi-Fi', enabled: this.isAmenityEnabled('wifi') },
      { icon: 'sports', label: 'Fitness Centre', enabled: this.isAmenityEnabled('fitnesscentre') },
      { icon: 'sports_tennis', label: 'Billiards Table', enabled: this.isAmenityEnabled('lockers') },
      { icon: 'inbox', label: 'Package Lockers', enabled: this.isAmenityEnabled('walkingpaths') },
      { icon: 'child_friendly', label: 'Playgrounds', enabled: this.isAmenityEnabled('playgrounds') },
      { icon: 'directions_walk', label: 'Walking Paths', enabled: this.isAmenityEnabled('walkingpaths') },
      { icon: 'delete', label: 'Valet Trash', enabled: this.isAmenityEnabled('valet') },
      { icon: 'videocam', label: 'Surveillance Cameras', enabled: this.isAmenityEnabled('cameras') },
      { icon: 'park', label: 'Green Surrounding Neighborhood', enabled: this.isAmenityEnabled('green') },
      { icon: 'domain', label: 'Clubhouse', enabled: this.isAmenityEnabled('clubhouse') },
      { icon: 'celebration', label: 'Party Room', enabled: this.isAmenityEnabled('party') },
      { icon: 'roofing', label: 'Rooftop Garden', enabled: this.isAmenityEnabled('rooftop') }
    ];
}

// Helper method to check if amenity is enabled
private isAmenityEnabled(amenity: string): boolean {
    return this.amenity.includes(amenity); // Check if the amenity is in the array
}





  toggleShowMore() {
    this.showAll = !this.showAll;
  }

  get displayedAmenities() {
    return this.showAll ? this.amenities : this.amenities.slice(0, 7);
  }

  formatPrice(value: number | null): string {
    if (value === null || value === undefined) return '';
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + ' Crore';
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + ' Lakh';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + ' Thousand';
    }
    return value.toString();
  }

  stripHtmlTags(value: string | null): string {
    if (!value) return '';
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = value;

    return tempDiv.textContent || tempDiv.innerText || "";
  }
  
  back(){
    this.router.navigate(['/project-invetory'], { queryParams: { projectId: this.projectidinven } });
  }
}