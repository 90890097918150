<app-header-property></app-header-property>
<app-menu></app-menu>

<div class="row p-2">
  <div class="col-md-3">
      <app-property-slide  (payloadEvent)="onPayloadReceived($event)"></app-property-slide>
  </div>
  <div class="col-md-9">
    <ng-container *ngIf="listings.length > 0; else noListings">
      <ng-container *ngFor="let listing of listings;">
        <!-- <app-property-card [listing]="listing"></app-property-card> -->
        <app-property-card [listing]="listing" [editButton]="false"></app-property-card>
      </ng-container>
      <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
        ⬆
      </div>
    </ng-container>
    <ng-template #noListings>
      <app-not-found></app-not-found>
    </ng-template>
  
  </div>
</div>
<app-footer></app-footer>