<div class="wrapper">
    <app-header></app-header>
    <div class="content">
      <app-menu></app-menu><br>
      <div class="row d-flex card1">
        <div class="col-md-3">
            <img src="assets/images/Wild-Life.webp" class="img-fluid5 image5 ">
        </div>
        <div class="col-md-6">
            <img src="assets/images/house.jpg" class="img-fluid5 image5 ">
        </div>
        <div class="col-md-3">
            <img src="assets/images/Autumn.webp" class="img-fluid5 image5 ">
        </div>
    </div>
    <br><br>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 mb-3" *ngFor="let card of cards; let i = index">
                <div class="property-card1 d-flex">
                    <div class="flex-shrink-0">
                        <img [src]="card.imageUrl" alt="" class="img-fluid1" />
                    </div>
                    <div class="flex-grow-1 ms-3 clsspad" style="padding-left: 10px;">
                        <h5 class="card-text" style="font-size: 24px;"><b>{{ card.sourceName }} - {{ card.pubDate | date
                                }}</b></h5>
                        <p class="card-text truncated-text">
                            {{ (card.description)}}
                        </p>
    
                        <a class="card-text" style="color: blue; cursor: pointer;" [href]="card.link" target="_blank">
                            READ FULL ARTICLE HERE
                        </a>
    
                    </div>
                </div>
                <hr *ngIf="i < cards.length" style="width: 100%;" />
            </div>
        </div>
    </div>
    </div>
    <app-footer></app-footer>
   </div>
  
  
  