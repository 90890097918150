<div class="container">
  <button mat-icon-button class="filter-icon" (click)="toggleFilterSidebar()">
    <mat-icon style="color: white;">filter_list</mat-icon>
  </button>
  <div class="filter-sidebar" [class.open]="isFilterSidebarOpen">
    <!-- Your existing filter sidebar content -->
    <div class="row">
      <div class="col-7">
        <button mat-button style="color:#004aad; font-size: 16px; " class="font1" (click)="applyFilter()">Apply Filters</button>
      </div>
      <div class="col-5">
        <button mat-button style="color:#004aad; font-size: 16px;" class="font1" (click)="clearSelection()">Clear All</button>
      </div>
    </div>
    <div class="selected-filters-container font1">
      <div *ngFor="let filter of userfilter" class="selected-filter">
        {{ filter }}
        <!-- <mat-icon class="remove-icon" (click)="removeFilter(filter)">cancel</mat-icon> -->
      </div>
    </div>
    <!-- <div class="selected-filters-container1">
      <div *ngIf="selectedMinValue && selectedMaxValue" class="selected-filter">
        Budget: {{ selectedMinValue }} - {{ selectedMaxValue }}
        <mat-icon class="remove-icon" (click)="removeBudget()">cancel</mat-icon>
      </div>
      
    </div> -->
    <div>
      <mat-expansion-panel class="no-box-shadow" [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 16px;" class="font1">Budget</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-6">
            <div>
              <mat-form-field>
                <mat-label>Min Budget</mat-label>
                <mat-select [(value)]="selectedMinValue" (selectionChange)="onBudgetChange()">
                  <mat-option *ngFor="let option of budgetOptions" class="font1" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-6">
            <div>
              <mat-form-field>
                <mat-label>Max Budget</mat-label>
                <mat-select [(value)]="selectedMaxValue" (selectionChange)="onBudgetChange()">
                  <mat-option *ngFor="let option of budgetOptions" class="font1" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div>
      <mat-expansion-panel class="no-box-shadow" [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 16px;" class="font1"><span>Type of property</span></mat-panel-title>
        </mat-expansion-panel-header>
        <mat-chip-list>
          <mat-chip *ngFor="let propertyType of propertyTypes" [selected]="propertyType.selected" (click)="selectPropertyType(propertyType)">
            <mat-icon *ngIf="propertyType.selected">check_circle</mat-icon>
            <mat-icon  *ngIf="!propertyType.selected">add</mat-icon>
            <div class="font12">{{ propertyType.name }}</div>
          </mat-chip>
        </mat-chip-list>
      </mat-expansion-panel>
    </div>
    <div>
      <mat-expansion-panel class="no-box-shadow" [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 16px;" class="font1">No. of Bedrooms</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="bedroom-chips">
          <mat-chip-list>
            <mat-chip *ngFor="let bedroom of getVisibleBedrooms()" [selected]="bedroom.selected" (click)="selectBedroom(bedroom)">
              <mat-icon *ngIf="bedroom.selected">check_circle</mat-icon>
              <mat-icon *ngIf="!bedroom.selected">add</mat-icon>
              <div style="font-size: 14px;" class="font12">{{ bedroom.name }}</div>
            </mat-chip>
          </mat-chip-list>
        </div>
        <button mat-button *ngIf="bedroomOptions.length > 5" (click)="toggleShowAllBedrooms()" class="font1" style="color: #004aad; font-size: 16px; margin-top: 5px;">
          {{ showAllBedrooms ? 'Less' : '+ 5 More' }}
        </button>
      </mat-expansion-panel>
    </div>
  </div>
</div>
