import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../_services/home.service';

@Component({
  selector: 'app-myprojects',
  templateUrl: './myprojects.component.html',
  styleUrls: ['./myprojects.component.scss']
})
export class MyprojectsComponent implements OnInit {
  IsLoading: boolean = false;
  topList: any[] = [];
  searchValue: string = '';
  constructor(private homeService: HomeService, private router: Router) {}

  ngOnInit(): void {
    this.getProject();
  }

  navigateToAddProject() {
    this.router.navigate(['/add-project']);
  }

  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList(); 
  }

  getProjectList(): void {
    this.IsLoading = true;

    // Ensure that the searchValue is passed as an empty string if it's undefined
    this.homeService.getProjectList('top', this.searchValue || '').subscribe((response: any) => {
      this.IsLoading = false;
      if (response.status === 'SUCCESS') {
        this.topList = response.payload.items;
      } else {
        console.error('Failed to fetch projects');
      }
    }, error => {
      this.IsLoading = false;
      console.error('Error fetching projects:', error);
    });
  }
  getProject() {
    this.IsLoading = true;
    this.homeService.getMyProjects().subscribe(
      (res: any) => {
        this.IsLoading = false;
        if (res.status === 'SUCCESS' && res.payload) {
          this.topList = res.payload; // Populate the topList array
          console.log("this.topListthis.topListthis.topList",this.topList)
        } else {
          this.topList = []; // Handle the case when no data is available
        }
      },
      () => {
        this.IsLoading = false;
        this.topList = []; // Handle error case
      }
    );
  }
}
