import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-property-card-small',
  templateUrl: './top-property-card-small.component.html',
  styleUrls: ['./top-property-card-small.component.scss']
})
export class TopPropertyCardSmallComponent implements OnInit {
  @Input() cardData: any;
  @Input() showsizeonly: boolean=true

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log('Recentlt Added',this.cardData);
    
  }
  formatPrice(value: number | null | undefined): string {
    if (value == null) {
      return ''; 
    }
  
    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(2)} Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(2)} L`;
    } else {
      return value.toLocaleString('en-IN');
    }
  }
  navigateTOListing(){
    this.router.navigate(['/recently-added-Project-list']);
  }
  viewDetails(event: Event) {
    event.stopPropagation();
    this.router.navigate(['/topProject'],{ queryParams: { id: this.cardData.id } });
  }
}
