<app-header></app-header>
<app-menu></app-menu><br>
<div class="p-3" style="margin-top: 3pc;" class="borderaroud">
  <mat-stepper [linear]="isLinear" (selectionChange)="onStepChange($event)" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()">
        <ng-template matStepLabel>Basic Details</ng-template>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
          <mat-form-field >
            <mat-label>Title</mat-label>
            <input matInput placeholder="" formControlName="title" required>
            <mat-error *ngIf="firstFormGroup.controls['title'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
            </div></div><br>
            <div class="row col-md-12">
              
              <div class="custom-form-field4">
                <label for="middleName" class="post-headings" style="color: rgb(136, 110, 110);">Description *</label>
                <textarea formControlName="description" class="description-textarea"></textarea>
                <div *ngIf="description.invalid && (description.dirty || description.touched)" class="text-danger">
                  This field is required.
                </div>
              </div>
            </div>
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Title</mat-label>
                <input matInput placeholder="" formControlName="title" required>
                <mat-error *ngIf="firstFormGroup.controls['title'].hasError('required')">This field is required</mat-error>
              </mat-form-field> -->
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>City</mat-label>
                <mat-select formControlName="cityId" (selectionChange)="onCityChange($event)">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let city of cityDataList" [value]="city.cityId">
                    {{ city.cityName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['cityId'].hasError('required')">This field is required</mat-error>
    
              </mat-form-field>
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>State</mat-label>
                <mat-select formControlName="stateId">
                  <mat-option *ngIf="stateId" [value]="stateId">
                    {{ stateName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['stateId'].hasError('required')">This field is required</mat-error>
    
              </mat-form-field>
              
            <mat-form-field class="custom-form-field3">
              <mat-label>Loan Available</mat-label>
              <mat-select disableRipple formControlName="loanAvailable">
                <mat-option>None</mat-option>
                <mat-option value="Bank">Bank</mat-option>
                <mat-option value="Private">Private</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Project Name</mat-label>
                <input matInput placeholder="" formControlName="projectName" required>
                <mat-error *ngIf="firstFormGroup.controls['projectName'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
        
              
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Rera Approved</mat-label>
                <mat-select disableRipple formControlName="reraApproved">
                  <mat-option>None</mat-option>
                  <mat-option [value]="'approved'">Approved</mat-option>
                  <mat-option [value]="'pending'">Pending</mat-option>
                  <mat-option [value]="'rejected'">Rejected</mat-option>
                  <mat-option [value]="'not-applicable'">Not Applicable</mat-option>
                  
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['reraApproved'].hasError('required')">This field is required</mat-error>
              </mat-form-field> 


              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Inventory Type</mat-label>
                <mat-select disableRipple formControlName="inventoryType">
                  <mat-option>None</mat-option>
                  <mat-option value="plots">Plots</mat-option>
                  <mat-option value="flats">Flats</mat-option>
                  <mat-option value="villas">Villas</mat-option>
                  <mat-option value="commercials">Commercials</mat-option>
                  <mat-option value="offices">Offices</mat-option>
                  <mat-option value="mansions">Mansions</mat-option>
                  <mat-option value="farmHouse">Farmhouse</mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['inventoryType'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div class="row col-md-12">
          <div class="custom-form-field41">
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile">
                  <mat-form-field>
                    <mat-label>From Price</mat-label>
                    <input matInput placeholder="" formControlName="fromPrice"  (input)="onInput($event)"
                     type="text">
                    <mat-error *ngIf="firstFormGroup.controls['fromPrice'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="fromPriceUnit" >
                      <mat-option value="Lac">Lac</mat-option>
                      <mat-option value="Cr">Crore</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['fromPriceUnit'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                
              </div>
            </div>

            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile">
                  <mat-form-field>
                    <mat-label>To Price</mat-label>
                    <input matInput placeholder="" formControlName="toPrice"  (input)="onInput($event)"
                     type="text">
                    <mat-error *ngIf="firstFormGroup.controls['toPrice'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="toPriceUnit" >
                      <mat-option value="Lac">Lac</mat-option>
                      <mat-option value="Cr">Crore</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['toPriceUnit'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile" >
                  <mat-form-field>
                    <mat-label>Size</mat-label>
                    <input matInput placeholder="" formControlName="size"  (input)="onInput($event)"
                    type="text"  >
                    <mat-error *ngIf="firstFormGroup.controls['size'].hasError('required')">This field is required</mat-error>
                  </mat-form-field> 
                </div>
                <div class="col-sm-4 mobile">
  
                  
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="sizeUnit">
                      <mat-option value="Sqm">Square Meter</mat-option>
                      <mat-option value="Sqft">Square Foot</mat-option>
                      <mat-option value="Ac">Acre</mat-option>
                      <mat-option value="Sqyd">Square Yard</mat-option>
                      <mat-option value="Killa">Killa</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['sizeUnit'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Status</mat-label>
              <mat-select disableRipple formControlName="status">
                <mat-option>None</mat-option>
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>
                
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['status'].hasError('required')">This field is required</mat-error>
            </mat-form-field>  -->


          </div>
        </div>
        <br>
        <div class="row col-md-12">
          <div class="custom-form-field4">
            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Location</mat-label>
              <input matInput placeholder="" formControlName="location" required>
              <mat-error *ngIf="firstFormGroup.controls['location'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Status</mat-label>
              <mat-select disableRipple formControlName="status">
                <mat-option>None</mat-option>
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>
                
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['status'].hasError('required')">This field is required</mat-error>
            </mat-form-field> 


            <!-- File upload fields -->
            <mat-form-field class="custom-form-field1">
              <div>
                <mat-toolbar>
                  <input matInput [value]="selectedFileName.length ? selectedFileName.join(', ') : 'Upload Banner Image'" readonly />
                  <button mat-flat-button color="primary" type="button" [disabled]="!selectedFile.length" (click)="uploadFiles()">Upload</button>
                </mat-toolbar>
                <input type="file" id="fileInput1" formControlName="uploadbanner" (change)="handleChanges($event)"
                  name="fileInput" accept="image/*" multiple hidden />
                <button mat-stroked-button type="button" class="font1" color="primary" (click)="triggerFileInput1()">Browse</button>
              </div>
              <div class="uploaded-images">
                <div *ngFor="let image of uploadedbanner; let i = index" class="uploaded-image-container">
                  <img [src]="image" alt="Uploaded Image" class="uploaded-image" />
                  <button mat-icon-button type="button" style="color:#004aad;" (click)="removeBanner(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-form-field>
         
          
            <mat-form-field class="custom-form-field1">
              <div>
                <mat-toolbar>
                  <input matInput [value]="selectedFileNames.length ? selectedFileNames.join(', ') : 'Upload Image'" readonly />
                  <button mat-flat-button color="primary" type="button" [disabled]="!selectedFiles.length" (click)="uploadFile()">Upload</button>
                </mat-toolbar>
                <input type="file" id="fileInput" formControlName="uploadPdf" (change)="handleChange($event)"
                  name="fileInput" accept="image/*" multiple hidden />
                <button mat-stroked-button type="button" color="primary" (click)="triggerFileInput()">Browse</button>
              </div>
              <div class="uploaded-images">
                <div *ngFor="let image of uploadedImages; let i = index" class="uploaded-image-container">
                  <img [src]="image" alt="Uploaded Image" class="uploaded-image" />
                  <button mat-icon-button style="color:#004aad;" (click)="removeImage(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-form-field>
          
           
          </div>
          
        </div>
        <br>
  
        <br>
        <div class="button-container">
          <button mat-button class="custom-button font1 onlyhover" type="button" (click)="handleCancel()">Cancel</button>
          <button mat-button class="custom-button font1 onlyhover" type="submit" style="color: whitesmoke">Next</button>
        </div>
      </form>
      
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="Features">
      <form [formGroup]="secondFormGroup">
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Builder</mat-label>
                <input matInput placeholder="" formControlName="builder" required>
                <mat-error *ngIf="secondFormGroup.controls['builder'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Connectivity</mat-label>
                <mat-select formControlName="connectivity" required>
                  <mat-option value="wifi">Wi-Fi</mat-option>
                  <mat-option value="ethernet">Ethernet</mat-option>
                  <mat-option value="mobile-data">Mobile Data</mat-option>
                  <mat-option value="bluetooth">Bluetooth</mat-option>
                  <mat-option value="vpn">VPN</mat-option>
                  <mat-option value="satellite">Satellite</mat-option>
                  <mat-option value="other">Other</mat-option>
                  <mat-option value="none">None</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['connectivity'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
              
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Construction Approvals</mat-label>
                <mat-select formControlName="constructionApprovals" required>
                  <mat-option value="yes">Yes</mat-option>
                  <mat-option value="no">No</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['constructionApprovals'].hasError('required')">
                  This field is required
                </mat-error>
              </mat-form-field>
              
            </div>
          </div>
        </div>
        <br>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Flat Purchase Checklist</mat-label>
                <mat-select formControlName="flatPurchaseChecklist" required>
                  <mat-option value="property-inspection">Property Inspection</mat-option>
                  <mat-option value="loan-approval">Loan Approval</mat-option>
                  <mat-option value="legal-documentation">Legal Documentation</mat-option>
                  <mat-option value="down-payment">Down Payment</mat-option>
                  <mat-option value="home-insurance">Home Insurance</mat-option>
                  <mat-option value="final-walkthrough">Final Walkthrough</mat-option>
                  <mat-option value="closing-costs">Closing Costs</mat-option>
                  <mat-option value="title-search">Title Search</mat-option>
                  <mat-option value="utility-setup">Utility Setup</mat-option>
                  <mat-option value="move-in-date">Move-In Date</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['flatPurchaseChecklist'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Investment Perspective</mat-label>
                <mat-select formControlName="investmentPerspective" required>
                  <mat-option value="risk-assessment">Risk Assessment</mat-option>
                  <mat-option value="roi">Return on Investment (ROI)</mat-option>
                  <mat-option value="market-trends">Market Trends</mat-option>
                  <mat-option value="investment-horizon">Investment Horizon</mat-option>
                  <mat-option value="diversification-strategy">Diversification Strategy</mat-option>
                  <mat-option value="asset-allocation">Asset Allocation</mat-option>
                  <mat-option value="liquidity-needs">Liquidity Needs</mat-option>
                  <mat-option value="tax-implications">Tax Implications</mat-option>
                  <mat-option value="economic-indicators">Economic Indicators</mat-option>
                  <mat-option value="investment-goals">Investment Goals</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['investmentPerspective'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
              
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Security</mat-label>
                <mat-select formControlName="security" required>
                  <mat-option value="yes">Yes</mat-option>
                  <mat-option value="no">No</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['security'].hasError('required')">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Access To</mat-label>
                <mat-select formControlName="accessTo" required>
                  <mat-option value="public-access">Public Access</mat-option>
                  <mat-option value="restricted-access">Restricted Access</mat-option>
                  <mat-option value="admin-access">Admin Access</mat-option>
                  <mat-option value="user-access">User Access</mat-option>
                  <mat-option value="guest-access">Guest Access</mat-option>
                  <mat-option value="read-only-access">Read-Only Access</mat-option>
                  <mat-option value="full-access">Full Access</mat-option>
                  <mat-option value="limited-access">Limited Access</mat-option>
                  <mat-option value="temporary-access">Temporary Access</mat-option>
                  <mat-option value="no-access">No Access</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['accessTo'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
              
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Zoning Risks</mat-label>
                <mat-select formControlName="zoningRisks" required>
                  <mat-option value="regulatory-changes">Regulatory Changes</mat-option>
                  <mat-option value="environmental-restrictions">Environmental Restrictions</mat-option>
                  <mat-option value="land-use-conflicts">Land Use Conflicts</mat-option>
                  <mat-option value="zoning-violations">Zoning Violations</mat-option>
                  <mat-option value="market-demand-fluctuations">Market Demand Fluctuations</mat-option>
                  <mat-option value="infrastructure-limitations">Infrastructure Limitations</mat-option>
                  <mat-option value="community-opposition">Community Opposition</mat-option>
                  <mat-option value="property-value-impact">Property Value Impact</mat-option>
                  <mat-option value="development-delays">Development Delays</mat-option>
                  <mat-option value="compliance-costs">Compliance Costs</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['zoningRisks'].hasError('required')">This field is required</mat-error>
              </mat-form-field>
              
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Verification</mat-label>
                <mat-select formControlName="verification" required>
                  <mat-option value="yes">Yes</mat-option>
                  <mat-option value="no">No</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['verification'].hasError('required')">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div class="row col-md-12">
          <div class="custom-form-field4">
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Modern Features</mat-label>
              <mat-select formControlName="modernFeatures" required>
                <mat-option value="smart-home-technology">Smart Home Technology</mat-option>
                <mat-option value="energy-efficient-appliances">Energy-Efficient Appliances</mat-option>
                <mat-option value="open-floor-plan">Open Floor Plan</mat-option>
                <mat-option value="sustainable-materials">Sustainable Materials</mat-option>
                <mat-option value="home-automation-systems">Home Automation Systems</mat-option>
                <mat-option value="high-speed-internet">High-Speed Internet Connectivity</mat-option>
                <mat-option value="smart-security-systems">Smart Security Systems</mat-option>
                <mat-option value="green-roofs">Green Roofs</mat-option>
                <mat-option value="solar-panels">Solar Panels</mat-option>
                <mat-option value="integrated-smart-lighting">Integrated Smart Lighting</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['modernFeatures'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
            
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>No Objection Certificates</mat-label>
              <mat-select formControlName="noObjectionCertificates" required>
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['noObjectionCertificates'].hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
            
            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Market Research</mat-label>
              <mat-select formControlName="marketResearch" required>
                <mat-option value="surveys">Surveys</mat-option>
                <mat-option value="focus-groups">Focus Groups</mat-option>
                <mat-option value="observational-research">Observational Research</mat-option>
                <mat-option value="market-analysis">Market Analysis</mat-option>
                <mat-option value="competitor-analysis">Competitor Analysis</mat-option>
                <mat-option value="consumer-behavior-studies">Consumer Behavior Studies</mat-option>
                <mat-option value="product-testing">Product Testing</mat-option>
                <mat-option value="trend-analysis">Trend Analysis</mat-option>
                <mat-option value="data-analytics">Data Analytics</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['marketResearch'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
            
           

          </div>
        </div>
        <br>
        <div class="button-container">
          <button mat-button class="custom-button font1  onlyhover" type="button" (click)="stepper.previous()">Back</button>
          <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
        </div>
      </form>
    </mat-step>
  
  </mat-stepper>
</div>
<app-footer></app-footer>

<div class="page-container">

  
</div>