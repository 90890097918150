import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { ArgmapComponent } from './argmap/argmap.component';


import { ContractDetailComponent } from './contract-detail/contract-detail.component';

import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';



import { ProfileUpdateComponent } from './profileupdate/profileupdate.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SearchArtistComponent } from './search-artist/search-artist.component';
import { SearchAssignmentComponent } from './search-assignments/search-assignments.component';

import { UpdateAssignmentComponent } from './update-assignments/update-assignments.component';

import { AuthGuard } from './_helpers/auth.guard';
import { AddPropertyComponent } from './add-property/add-property.component';
import { PropertyDetailsComponent } from './property-detail/property-details.component';
import { PropertyOwnerComponent } from './property-owner/property-owner.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ImageSlideComponent } from './image-slider/image-slide.component';
import { UserPropertyComponent } from './user-property/user-property.component';
import { HomePropertyComponent } from './home-property/home-property.component';
import { TopProjectComponent } from './top-project/top-project.component';

import { MyprojectsComponent } from './myprojects/myprojects.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { NewsComponent } from './news/news.component';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import { ProjectInventoryComponent } from './project-inventory/project-inventory.component';
import { TopProjectListingComponent } from './Projects-listing/Top-project/top-project-listing.component';
import { RecentlyAddedProjectComponent } from './Projects-listing/Recently-added-project/recently-added-project.component';
import { TrendingProjectListingComponent } from './Projects-listing/Trending-project/trending-project-listing.component';
import { PrivacyPolityComponent } from './footer-content/privacy-polity.component';
import { TermConditionComponent } from './footer-content/term-condition.component';
import { AboutUsComponent } from './footer-content/about-us.component';
import { ContactUsComponent } from './footer-content/contact-us/contact-us.component';
import { ViewInventoryComponent } from './view-inventory/view-inventory.component';
import { FaqComponent } from './footer-content/FAQ/faq.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home-property', component: HomePropertyComponent },
  { path: 'image-slider', component: ImageSlideComponent },
  { path: 'home/:s', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'update-assignment/:assignmentId', component: UpdateAssignmentComponent, canActivate: [AuthGuard] },
  { path: 'search-assignments/:se', component: SearchAssignmentComponent },
  { path: 'search-artist/:se', component: SearchArtistComponent },
  { path: 'contract-detail/:contractId', component: ContractDetailComponent, canActivate: [AuthGuard] },
  { path: 'resetpassword', component: ResetpasswordComponent},
  { path: 'UserProperty', component: UserPropertyComponent },
  { path: 'topProject', component:TopProjectComponent },
  { path: 'inventory-details', component:ViewInventoryComponent },
  { path: 'update/profile/username/userid/activetab', component: ProfileUpdateComponent },
  { path: 'setnewpassword/:email', component: ForgotpasswordComponent },
  { path: 'map', component: MapComponent},
  { path: 'artistmap', component: ArgmapComponent},
  { path: 'add-property', component: AddPropertyComponent },
  { path: 'myprojects', component: MyprojectsComponent },
  { path: 'property-details', component: PropertyDetailsComponent },
  { path: 'owner-details', component:PropertyOwnerComponent },
  { path: 'add-project', component:AddProjectComponent },
  { path: 'create/id', component: AddPropertyComponent },
  { path: 'news', component: NewsComponent },
  { path: 'add-invetory', component: AddInventoryComponent },
  { path: 'project-invetory', component: ProjectInventoryComponent },
  { path: 'top-project-list', component: TopProjectListingComponent },
  { path: 'recently-added-Project-list', component: RecentlyAddedProjectComponent },
  { path: 'Trending-project-list', component: TrendingProjectListingComponent },
  { path: 'privacyPolicy', component: PrivacyPolityComponent },
  { path: 'termsandcondition', component: TermConditionComponent },
  { path: 'aboutUs', component: AboutUsComponent },
  { path: 'contactUs', component: ContactUsComponent },
  { path: 'FAQ', component: FaqComponent },
  { path: 'notfound', component: NotFoundComponent },
  {path: '**', redirectTo: '/404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],

  exports: [RouterModule]
})


export class AppRoutingModule { }
