import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../_services/home.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  
  ContacUsContent: string | null = null;
	projectName: any
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private homeService: HomeService,
		private cd: ChangeDetectorRef) { }


	ngOnInit(): void {
		this.projectName = "Property"
		this.getprofession_details(this.projectName)
	}

  getprofession_details(projectName: string): void {
    this.homeService.getprofession_details(projectName).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          // Find the item with type 'ContacUs'
          // const ContacUs = response.payload.find((item: any) => item.type === 'ContacUs');
          const ContacUs = response.payload.filter((item: any) => item.type === 'ContacUs');
          const letestContacUs = ContacUs[ContacUs.length - 1];
          if (letestContacUs) {
            this.ContacUsContent = letestContacUs.content;
          } else {
            console.error('No ContacUs found in the response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }


}

