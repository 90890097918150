import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service'
@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.scss']
})
export class TermConditionComponent implements OnInit {

  termAndConditionContent: string | null = null;
	projectName: any
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private homeService: HomeService,
		private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
  ) { }


	ngOnInit(): void {
		this.projectName = "Property"
		this.getprofession_details(this.projectName)
	}

  getprofession_details(projectName: string): void {
    this.loadingService.show()
    this.homeService.getprofession_details(projectName).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          // Find the item with type 'TermandCondtion'
          // const termAndCondition = response.payload.find((item: any) => item.type === 'TermandCondtion');
          const termAndCondition = response.payload.filter((item: any) => item.type === 'TermandCondtion');
          const letesttermAndCondition = termAndCondition[termAndCondition.length - 1];
          if (letesttermAndCondition) {
            this.termAndConditionContent = letesttermAndCondition.content;
            this.loadingService.hide()
          } else {
            console.error('No TermandCondtion found in the response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }


}
