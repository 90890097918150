<div class="containerss" [class.sign-in]="isSignIn" [class.sign-up]="!isSignIn">
  <!-- FORM SECTION -->
  <div class="row">
    
    <!-- SIGN UP -->
    <div class="col align-items-center flex-col sign-up">
      
      <div class="form-wrapper align-items-center">
        <div class="form sign-up">
          <form [formGroup]="regform" (ngSubmit)="onSubmit()" class="account account1 reg{{registrationactive}}">
            <a class="navbar-brand mr-0 mb-3" routerLink="/">
              <img src="../../assets/images/logo45.png" alt="" class="onlylogo" >
            </a>
            <h2 class="welcome-back">Create Account</h2>
            <input type="text" placeholder="Enter Full Name" formControlName="fullName" />
            <div class="errorClass" *ngIf="submitted && f.fullName.errors">
              <div *ngIf="f.fullName.errors.required">Full Name is required</div>
            </div>
            <div class="position-relative">
              <input
                type="email"
                placeholder="Email"
                formControlName="email"
                #emailInput
              />
              <button
                type="button"
                class="btn  input-btn"
                (click)="onVerifyEmail(emailInput.value)"
              >
                Verify
              </button>
            
              <div class="errorClass" *ngIf="submitted && f.email.errors">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            
            
            
            
            <input type="text" placeholder="Enter Mobile Number" formControlName="mobileNumber" (input)="onInput($event)"/>
            <div class="errorClass" *ngIf="submitted && f.mobileNumber.errors">
              <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
            </div>
            <input type="password" placeholder="Password" formControlName="password" />
            <div class="errorClass" *ngIf="submitted && f.password.errors">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
            <div class="col-md-12 col-12 user-type-container">
              <label class="main-label mt-2">Are You</label>
              <div class="radio-group">
                <label>
                  <input type="radio" formControlName="userType" [value]="{ individual: true, builder: false, agent: false }" /> Individual
                </label>
                <label>
                  <input type="radio" formControlName="userType" [value]="{ individual: false, builder: true, agent: false }" /> Builder
                </label>
                <label>
                  <input type="radio" formControlName="userType" [value]="{ individual: false, builder: false, agent: true }" /> Dealer
                </label>
              </div>
              <div class="errorClass" *ngIf="submitted && regform.errors?.userTypeRequired">
                <div>User Type is required</div>
              </div>
            </div>
            <button type="submit" class="btn signinn custom-bg text-white px-2 py-1 mt-1 onlyhover">Sign Up</button>
          </form><br>
          <p class="forgot-password-links mt-2 mb-2">
            <span style="color: black;">Already have an account?</span>&nbsp;
            <span (click)="toggle()">Sign in here</span>
          </p>
        </div>
      </div>
    </div>

    <!-- SIGN IN -->
    <div class="col align-items-center flex-col sign-in">
      <div class="form-wrapper align-items-center">
        <div class="form sign-in">
          <form [formGroup]="loginform" (ngSubmit)="onLoginSubmit()" class="account login{{aciveforgot}}">
       
        <a class="navbar-brand mr-0 mb-3 " routerLink="/">
          <img src="../../assets/images/logo45.png" alt="" class="onlylogo" >
        </a>

        <h2 class="welcome-back">Welcome Back</h2>

        <input type="text" placeholder="Enter Email" formControlName="emailusernam" />
        <div class="errorClass" *ngIf="submitted && h.emailusernam.errors">
          <div *ngIf="h.emailusernam.errors.required">Email is required</div>
        </div>
        <input type="password" placeholder="Password" formControlName="loginpassword" />
        <div class="errorClass" *ngIf="submitted && h.loginpassword.errors">
          <div *ngIf="h.loginpassword.errors.required">Password is required</div>
          <div *ngIf="h.loginpassword.errors.minlength">Password must be at least 6 characters</div>
        </div>
        <!-- <button type="submit" class="signinn  mb-2 mt-2 bsk " style="color: #004aad;">Sign In</button> -->
        <button type="submit" class="btn signinn custom-bg text-white px-2 py-1 mt-2">Sign In</button>
        <br>
        <p (click)="forgotSwitch()" class="forgot-password-link mt-2 mb-2">Forgot your password?</p><br>
        <!-- <div class="mobile-only" id="mobileSignUp" (click)="signUpSwap()">Sign Up</div> -->
            <p class="forgot-password-link " >
              <span style="color: black;">Don't have an account?</span>&nbsp;
              <span (click)="toggle()" class="pointer">Sign up here</span>
            </p>
         
      </form>
      <form [formGroup]="forgotform" (ngSubmit)="onForgotSubmit()" class="account fotgot{{aciveforgot}}">
        <h3 class="welcome-back">Forgot Password</h3>
        <input type="email" placeholder="Email" formControlName="forgotemail" />
        <div class="errorClass" *ngIf="submitted && k.forgotemail.errors">
          <div *ngIf="k.forgotemail.errors.required">Email is required</div>
          <div *ngIf="k.forgotemail.errors.email">Email must be a valid email address</div>
        </div>
        <button type="submit" class="signinn mb-2"  style="margin-top: 10px;">Confirm Email</button>
        <a href="javascript:;" (click)="loginSwitch()" class="forgot-password-link1 onlyhover" style="margin-top: 20px;">Sign In</a>
      </form>
    
        </div>
      </div>
    </div>

  </div>
  
  <div class="row content-row">
    <!-- SIGN IN CONTENT -->
    <div class="col align-items-center flex-col">
      <div class="text sign-in">
        <h2>
          Welcome
        </h2>

      </div>
      <div class="img sign-in">

      </div>
    </div>
    <!-- END SIGN IN CONTENT -->
    <!-- SIGN UP CONTENT -->
    <div class="col align-items-center flex-col">
      <div class="img sign-up">

      </div>
      <div class="text sign-up">
        <h2>
          Join with us
        </h2>

      </div>
    </div>
    <!-- END SIGN UP CONTENT -->
  </div>
</div>
