<div class="page-container">
  <app-header></app-header>
  <app-menu></app-menu>
  <div class="col-md-12 content">
    <div class="row align-items-center mt-4">
      <!-- <div class="col-12 col-md-2 mb-3 mb-md-0">
        <div class="titles">My Property</div>
      </div> -->

      <div class="col-12 col-md-12 mb-2 mb-md-0">
        <div class="search-container">
          <mat-icon class="search-icon" style="color: gray;">search</mat-icon>
          <input
            type="text"
            class="search-input"
            placeholder="Search your Property"
            [(ngModel)]="searchValue"
            (keyup)="searchListings()"
          />
          <button class="search-button" (click)="searchListings()">
            Search 
          </button>
        </div>
      </div>
      
    </div>

    <ng-container *ngIf="filteredListings?.length > 0; else noListings">
      <div class="listings-container">
        <ng-container *ngFor="let listing of filteredListings">
          <app-property-card
            [listing]="listing"
            [editButton]="true"
          ></app-property-card>
        </ng-container>
      </div>
      <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
        ⬆
      </div>
    </ng-container>
    <ng-template #noListings>
      <app-not-found></app-not-found>
    </ng-template>
  </div>
  <br />

  <app-footer></app-footer>
</div>
