
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss'],
})
export class AddPropertyComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;
  selectedFiles: File[] = [];
  selectedUnit: number = 1;
  selectedWidth: string = '';
  selectedSize: string = '';
  isFocused: boolean = false;
  cityId:any;
  calculatedWidth: string = '';
  calculatedSize: string = '';
  inputAsking: number = 0;
  inputWidth: string ='';
  inputSize: string = '';
  selectedFileNames: string[] = [];
  isLinear = false;
  countryId = 259;
  stateList: any[] = [];
  cityDataList: any[] = [];
  cityDataListfirst: any[] = [];
  id: any;
  stateId: number;
  stateName: string;
  data: any;
  cityid: number;
  payload: any;
  paramsData: any;
  Propertyid: string;
  pdfurl: any;
  media: any = [];
  length: number = 1;
  image: any = [];
  Urls: string[] = [];
  imageItems: any[] = [
    {
      constrolName: 'imageUrls',
      purpose: 'propertiesImage',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },
  ];

  feature:any;
  uploadedImages: any[] = [];
  featureResponsePayload: any;
  featureResponse:any;
  // unitMultiplier: { [key: string]: number } = {
  //   'Lac': 1e5,
  //   'Cr': 1e7
  // };
  constructor(
    private _formBuilder: FormBuilder,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private toastr:ToastrService,
    private errorMessageService:ErrorMessageService,


  ) {}

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
    if (input.value.length > 5) {
      input.value = input.value.slice(0, 5); // Limit to 10 digits
    }
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.Propertyid = params['id'];
      if (this.Propertyid) {
        this.propertById();
      } else {
        console.error('No Propertyid found in query params');
      }
    });
    const emptysearch =''
    this.getCitybyCountryId(emptysearch);

    // this.secondFormGroup.controls['asking'].valueChanges.subscribe(() => {
    //   // this.updateAskingValue();
    // });
  }

  firstFormGroup = this._formBuilder.group({
    description: ['', Validators.required],
    type: ['', Validators.required],
    address: ['', Validators.required],
    area: ['', Validators.required],
    stateId: ['', Validators.required],
    cityName: ['', Validators.required],
    pinCode: [''],
    status: [''],
    title: ['', Validators.required],
  });

  secondFormGroup = this._formBuilder.group({
    noOfBadroom: ['', Validators.required],
    noOfBathroom: ['', Validators.required],
    noOfBalconies: ['', Validators.required],
    totalFloors: ['', Validators.required],
    overLooking: ['', Validators.required],
    propertyAge: ['', Validators.required],
    placesNearby: ['', Validators.required],
    // transactionType: ['', Validators.required],
    propertyOwnership: ['', Validators.required],
    furnishing: ['', Validators.required],
    // widthOfFacingRoad: ['', Validators.required],
    // waterSource: ['', Validators.required],
    powerBackup: [''],
    direction: [''],
    loanAvailable:[''],
    buildupArea: ['', Validators.required],
    size: ['', Validators.required],
    sizeUnit: ['',Validators.required] ,
    construction: ['', Validators.required],
    askingUnit:['',Validators.required],
    asking: ['', Validators.required],
    // negotiable: ['', Validators.required],

    // widthOfFacingRoadUnit:['',Validators.required],

    // meetingPoint: ['',Validators.required],


  });

  thirdFormGroup = this._formBuilder.group({
    uploadPdf: [''],
  });
  // updateAskingValue() {
  //   const asking = this.secondFormGroup.controls['asking'].value;
  //   const askingUnit = this.secondFormGroup.controls['askingUnit'].value;
  //   let totalAskingValue = asking;

  //   if (askingUnit === 'Lac') {
  //     totalAskingValue = asking * 1e5; // 1 Lac = 100,000
  //   } else if (askingUnit === 'Cr') {
  //     totalAskingValue = asking * 1e7; // 1 Crore = 10,000,000
  //   }

  //   this.secondFormGroup.controls['asking'].setValue(totalAskingValue, { emitEvent: false });
  // }
  // onwidthInput(event: Event): void {
  //   this.inputWidth = (event.target as HTMLInputElement).value;
  //   this.updatewidth();
  // }
  // onUnitWidth(event: any): void {
  //   this.selectedWidth = event.value;
  // }
  // updatewidth(): void {
  //   this.secondFormGroup.get('widthOfFacingRoad')?.setValue(this.inputWidth, { emitEvent: false });
  // }
  // onsizeInput(event: Event): void {
  //   this.inputSize = (event.target as HTMLInputElement).value;
  // }
  // onUnitSize(event: any): void {
  //   this.selectedSize = event.value;
  // }
  // updatesize(): void {
  //   this.secondFormGroup.get('size')?.setValue(this.inputSize, { emitEvent: false });
  // }



  onFilesSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.selectedFiles = Array.from(input.files);
      this.selectedFileNames = this.selectedFiles.map((file) => file.name);
    }
  }







  citySelected = false;

  onCityBlur() {
    if (!this.citySelected) {
      this.selectedCity = null;
      this.firstFormGroup.controls['cityName'].setValue('');
      const searchtext1 =''
      this.getCitybyCountryId(searchtext1)
    }
    this.citySelected = false;
  }
onCitySearch(event: any) {
  const searchValue = event.target.value;
  if (searchValue.length > -1) {
    this.getCitybyCountryId(searchValue);
  } else {
    this.getCitybyCountryId(null);
  }
}

  getCitybyCountryId(searchText: string) {
    this.homeService.searchCity(searchText).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload;

          console.log(this.cityDataList);
        } else {
          console.error('Failed to fetch city data:', response);
        }
      },
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }


  displayCityName(city: any): string {
    console.log('gcd>>>>>>>>>>>>>>>>>>>>>>>>>>',city)
    if (!city) return '';
    return typeof city === 'object' && 'name' in city ? city.name : String(city);
  }


  selectedCity: any = null;
  selectedCityId1: number;
  selectedCityId:any
  onCityChange(event: any) {
    console.log('onCityChange triggered');
    this.selectedCity = event.option.value;
    console.log("this.selectedCity",this.selectedCity);
    this.selectedCityId1 = this.selectedCity.id;
    this.selectedCityId= this.selectedCity.name;
    console.log('selectedCityId:', this.selectedCityId1);
    this.citySelected = true;
    this.getStateByCityId(this.selectedCityId1);
  }

  getStateByCityId(cityId: number) {
    this.homeService.searchstate(cityId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.stateId = response.payload.stateId;
          this.stateName = response.payload.stateName;
          console.log(this.stateId, this.stateName);
          this.cd.detectChanges();

          this.firstFormGroup.patchValue({
            stateId: this.stateId,

          });
          this.cd.detectChanges();

          console.log('stId', this.stateId);
        } else {
          console.error('Failed to fetch state data:', response);
          this.firstFormGroup.patchValue({
            stateId: null,
          });
        }
      },
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }

  onSubmit() {
      const controls = this.firstFormGroup.controls;
      for (const i in controls) {
        controls[i].markAsDirty();
        controls[i].updateValueAndValidity();
      }
    if (this.firstFormGroup.valid) {

      let payload = { ...this.firstFormGroup.value,  cityName: this.selectedCityId  };
      if (!this.Propertyid) {
          delete payload.status;
      }
      console.log('payload', payload);
      console.log(this.Propertyid);
      if (this.Propertyid) {
        this.UpdateBasicDetail(payload, this.Propertyid);
      } else {
        this.create(payload);
      }
      this.stepper.next();
    } else {
      console.log('error');
    }
    (error) => {
      const message = error?.error?.message || 'You are not authorized to access this page';
      if (message) {
        let errorMessage = this.errorMessageService.getErrorMessage(message);
        this.toastr.error(errorMessage, 'Error');
      }


    }
  }

  UpdateBasicDetail(payload: any, id: any) {
    this.homeService.Updateroperty(payload, id).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);
          this.id = response.payload.id;
          this.paramsData = response.payload;
          if (this.id) {
            this.router.navigate(['/add-property'], {
              queryParams: { id: this.id },
            });
          } else {
            console.error('No id returned in response');
          }
        }   else if (response && response.status === 'EXCEPTION') {
          if (response['message']) {
            let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
            this.toastr.error(errorMessage, 'Error');
          }
        }
      },
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }

  create(payload: any) {
    this.homeService.CreateProperty(payload).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);
          this.id = response.payload.id;
          this.paramsData = response.payload;
          if (this.id) {
            this.router.navigate(['/add-property'], {
              queryParams: { id: this.id },
            });
          } else {
            console.error('No id returned in response');
          }
        }  else if (response && response.status === 'EXCEPTION') {
          if (response['message']) {
            let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
            this.toastr.error(errorMessage, 'Error');
          }
        }
      },
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }
  cityName:any
  propertById() {
    this.homeService.getPropertyById(this.Propertyid).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);
          this.data = response?.payload;
          console.log('data',this.data);
          let selectedCityId=response?.payload?.cityId;
          this.getStateByCityId(selectedCityId);
          this.stateId = response?.payload?.stateId;
          this.cityName = response?.payload?.cityName;

          // if (featuresData) {
          //   if (featuresData.askingUnit === 'Lac') {
          //     asking *= 100000;
          //   } else if (featuresData.askingUnit === 'Cr') {
          //     asking *= 10000000;
          //   }
          // }





          this.feature=response?.payload?.feature
          let askingValue = this.data.asking;
          if (this.feature) {
            if (this.feature.askingUnit=== 'Lac') {
              askingValue = this.data.asking ;
              askingValue /= 100000;
            } else if (this.feature.askingUnit=== 'Cr' ) {
              askingValue = this.data.asking ;
              askingValue /= 10000000;
            }
          } else {
            console.warn('Feature data is not available');
          }
          console.log('feature', this.feature);
          this.uploadedImages  = response?.payload?.mediaGroup.map(media => media.url);
          this.firstFormGroup.patchValue({

            title: this.data.title,
            description: this.data.description,
            status: this.data.status,
            address: this.data.address,
            pinCode: this.data.pinCode,
            area: this.data.area,
            cityName: this.cityName,
            stateId: this.stateId,
            type:this.data.type,
          });
          this.cd.detectChanges();
          console.log(this.firstFormGroup);

          if (this.feature) {

            this.secondFormGroup.patchValue({
              noOfBadroom: this.data.noOfBadroom,
              noOfBathroom: this.feature.noOfBathroom || 0,
              noOfBalconies: this.feature.noOfBalconies || 0,
              totalFloors: this.feature.totalFloors || 0,
              overLooking: this.feature.overLooking,
              propertyAge: this.feature.propertyAge,
              placesNearby: this.feature.placesNearby,
              // transactionType: this.feature.transactionType,
              propertyOwnership: this.feature.propertyOwnership,
              furnishing: this.feature.furnishing,
              // widthOfFacingRoad:this.feature.widthOfFacingRoad,
              waterSource: this.feature.waterSource,
              powerBackup: this.feature.powerBackup,
              // propertyCode: this.feature.propertyCode,
              direction: this.feature.direction,
              buildupArea: this.feature.buildupArea,
              size: this.feature.size,
              construction: this.feature.construction,
              asking: askingValue,
              askingUnit: this.feature.askingUnit,
              sizeUnit: this.feature. sizeUnit,
              // negotiable: this.feature.negotiable,
              loanAvailable: this.feature.loanAvailable,
              // meetingPoint: this.feature.meetingPoint,
              // widthOfFacingRoadUnit:this.feature. widthOfFacingRoadUnit,
            });
          } else {
            console.warn('Feature data is not available');
          }

          this.thirdFormGroup.patchValue({
            uploadPdf: this.uploadedImages

          });

          console.log('feature', this.feature);
        }   else if (response && response.status === 'EXCEPTION') {
          if (response['message']) {
            let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
            this.toastr.error(errorMessage, 'Error');
          }
        }
      },
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }

  UpdateFeature() {
    if (!this.secondFormGroup.valid) {
      console.error('Form is invalid');
      return;
    }

    let { noOfBadroom, asking, ...featuresData } = this.secondFormGroup.value;

    if (featuresData) {
      if (featuresData.askingUnit === 'Lac') {
        asking *= 100000;
      } else if (featuresData.askingUnit === 'Cr') {
        asking *= 10000000;
      }
    } else {
      console.warn('Feature data is not available, using normal asking value');
    }

    this.payload = {
      ...this.feature,
      ...this.firstFormGroup.value,
      noOfBadroom,
      feature: featuresData,
      asking,
      cityName: this.selectedCityId
    };

    this.homeService.Updateroperty(this.payload, this.Propertyid).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);
          this.featureResponsePayload = response.payload;
          this.featureResponse = response.payload.feature;
          console.log('featureResponsePayload', this.featureResponsePayload);

          this.id = response.payload.id;

          if (this.id) {
            this.router.navigate(['/add-property'], {
              queryParams: { id: this.id },
            });
          } else {
            console.error('No id returned in response');
          }
          this.stepper.next();
        }    else if (response && response.status === 'EXCEPTION') {
          if (response['message']) {
            let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
            this.toastr.error(errorMessage, 'Error');
          }
        }
      },
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }



  onStepChange(event: any) {
    // Check which step is being selected
    const stepIndex = event.selectedIndex;
    // Validation for the first form group
    if (stepIndex === 0 && this.firstFormGroup.invalid) {
      event.preventDefault();
      this.firstFormGroup.markAllAsTouched();
      return;
    }
    // Validation for the second form group
    if (stepIndex === 1 && this.secondFormGroup.invalid) {
      event.preventDefault();
      this.secondFormGroup.markAllAsTouched();
      return;
    }
    // Validation for the third form group
    if (stepIndex === 2 && this.thirdFormGroup.invalid) {
      event.preventDefault();
      this.thirdFormGroup.markAllAsTouched();
      return;
    }
  }

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
  handleCancel(){
    const id = this.route.snapshot.queryParamMap.get('id')
    if(id){
      this.router.navigate(['/UserProperty'])
    }
    else{
      this.router.navigate(['/home-property'])
    }

  }
  handleChange(event: any): void {
    const files = event.target.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
        this.selectedFileNames.push(files[i].name);
        this.imageItems.push({ loading: true, purpose: 'propertiesImage' });
      }
      this.cd.detectChanges();
    }
  }

  uploadFiles(): void {
    if (this.selectedFiles.length) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const selectedFile = this.selectedFiles[i];
        const type = this.imageItems[i];
        const mimeType = selectedFile.type;
        const purpose = type.purpose;

        this.homeService.getMediaData(purpose, mimeType, selectedFile)
          .pipe(finalize(() => { this.imageItems[i].loading = false; }))
          .subscribe((res: any) => {
            const media_type = (purpose === 'propertiesImage') ? 'image' : ((purpose === 'shopVideo') ? 'video' : 'logo');
              this.uploadedImages.push(res.url);
            this.cd.detectChanges();
          });
      }

      // Clear the file input and reset selected files and names
      this.clearFileInput();
    }
  }

  clearFileInput(): void {
    this.selectedFiles = [];
    this.selectedFileNames = [];
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = ''; // Clear the file input value
    this.cd.detectChanges();
  }
  removeImage(index: number): void {
    this.uploadedImages.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }
  updateImages() {
    const modifiedPayload = { ...this.featureResponsePayload };

    const fieldsToRemove = ['mediaGroup', 'user', 'updatedAt'];
    fieldsToRemove.forEach(field => {
      delete modifiedPayload[field];
    });

    const payloadWithFeature = {
      ...modifiedPayload,
      imageUrls: this.uploadedImages
    };

    this.homeService.Updateroperty(payloadWithFeature, this.Propertyid).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.toastr.success('Property Added Successfully', 'Success');
          this.router.navigate(['/UserProperty']),

          console.log('response', response);
        }
        else if (response && response.status === 'EXCEPTION') {
          if (response['message']) {
            let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
            this.toastr.error(errorMessage, 'Error');
          }
        }
      },
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }

  clearSelect(controlName: string) {
    this.thirdFormGroup.controls[controlName].setValue(null);
  }

  validateNumberInput(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
  get description() {
    return this.firstFormGroup.get('description');
  }

}

