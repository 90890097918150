
import { Component, HostListener, OnInit,ViewChild,ElementRef} from '@angular/core';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../_services/loading.service';
import { HeaderSearchService } from '../_services/header-search.service';
@Component({
  selector: 'app-user-property',
  templateUrl: './user-property.component.html',
  styleUrls: ['./user-property.component.scss']
})
export class UserPropertyComponent implements OnInit {
  @ViewChild('searchkey') searchInput: ElementRef;
  listings:any;
  Images: string[] = [];
  showTopIcon = false; 
  searchValue: string = '';
  IsLoading:boolean=false;
  topList:any[]=[];
  constructor(
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private searchService:HeaderSearchService
  ) { }
  ngOnInit(): void {
    this.getProprtybyUser()
    
  }
  @HostListener('window:scroll', [])
    onWindowScroll() {
      this.showTopIcon = window.scrollY > 100; // Show icon if scrolled down more than 200px
    }
  
    scrollToTop(): void {
        
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  showMore: boolean = false;
  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }
  searchArt() {
		const searchData = this.searchInput.nativeElement.value;
		console.log(searchData);
		this.searchService.setSearchData(searchData);
	  }
  getProprtybyUser() {
    // this.homeService.getProprtybyUser().subscribe(
    //   (response: any) => {
    //     if (response.status === 'SUCCESS') {
    //       this.listings=response?.payload;
    //       if (this.listing && this.listing.mediaGroup) {
    //         this.Images = this.listing.mediaGroup.map(media => media.url);
    //         console.log('images',this.Images);
    //       } else {
    //         this.Images = [];
    //       }
          
          
    //       console.log('listing',this.listing);
    //     } else {
    //       console.error('Failed to fetch city data:', response);
    //     }
    //   },
    //   (error) => {
    //     console.error('Error fetching city data:', error);
    //   }
    // );
    this.homeService.getProprtybyUser().subscribe(
			(response: any) => {
				if (response && response.payload) {
                    this.loadingService.hide();
				           	this.listings = response.payload;
					console.log('Listings:', this.listings,length);
				} else {
					console.error('Unexpected data format:', response);
					this.listings = [];
                  
				}
			},
			error => {
                this.loadingService.hide();
				console.error('Error loading listings:', error);
				this.listings = [];
			}
		);
  }

  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList(); 
  }

  getProjectList(): void {

    const searchData = this.searchInput.nativeElement.value;
		console.log(searchData);
		this.searchService.setSearchData(searchData);








    // this.IsLoading = true;

    // Ensure that the searchValue is passed as an empty string if it's undefined
    // this.homeService.getProjectList('top', this.searchValue || '').subscribe((response: any) => {
    //   this.IsLoading = false;
    //   if (response.status === 'SUCCESS') {
    //     this.topList = response.payload.items;
    //   } else {
    //     console.error('Failed to fetch projects');
    //   }
    // }, error => {
    //   this.IsLoading = false;
    //   console.error('Error fetching projects:', error);
    // });
  }

}