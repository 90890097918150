<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>

<div class="p-3" style="margin-top: 3pc;" class="borderaroud">
  <mat-stepper [linear]="isLinear" (selectionChange)="onStepChange($event)" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()">
        <ng-template matStepLabel>Basic Details</ng-template>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field>
                <mat-label>Title</mat-label>
                <input matInput placeholder="" formControlName="title" required>
                <mat-error *ngIf="firstFormGroup.controls['title'].hasError('required')">This field is
                  required</mat-error>
              </mat-form-field>
            </div>
          </div><br>
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <label for="description" class="post-headings" style="color: rgb(136, 110, 110); font-family: 'Roboto', sans-serif;">
                Description
              </label>
              <textarea formControlName="description" class="description-textarea"></textarea>
              <span *ngIf="firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched"
                    class="error-message1">
                Description is required
              </span>
            </div>
          </div>

        </div>

        <div class="row col-md-12">
          <div class="custom-form-field4">
            <!-- <mat-form-field class="custom-form-field">
                                <mat-label>Loan Available</mat-label>
                                <mat-select disableRipple formControlName="loanAvailable">
                                    <mat-option>None</mat-option>
                                    <mat-option value="Bank">Bank</mat-option>
                                    <mat-option value="Private">Private</mat-option>
                                </mat-select>
                                <mat-error *ngIf="firstFormGroup.controls['loanAvailable'].hasError('required')">This
                                    field is required</mat-error>
                            </mat-form-field> -->
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Inventory Type</mat-label>
              <mat-select disableRipple formControlName="inventoryType"
                (selectionChange)="onInventoryTypeChange($event)">
                <mat-option>None</mat-option>
                <mat-option value="plots">Plots</mat-option>
                <mat-option value="flats">Flats</mat-option>
                <mat-option value="villas">Villas</mat-option>
                <mat-option value="commercials">Commercials</mat-option>
                <mat-option value="offices">Offices</mat-option>
                <mat-option value="mansions">Mansions</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['inventoryType'].hasError('required')">This
                field is required</mat-error>
            </mat-form-field>

            <mat-form-field class="custom-form-field">
              <mat-label>Negotiable</mat-label>
              <mat-select disableRipple formControlName="isNegotiable">
                <mat-option>None</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">NO</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['isNegotiable'].hasError('required')">This
                field is required</mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Status</mat-label>
              <mat-select disableRipple formControlName="status">
                <mat-option>None</mat-option>
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>
                
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['status'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
       <div class="d-flex">
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile" >
                  <mat-form-field>
                    <mat-label>Total Size</mat-label>
                    <input matInput placeholder="" formControlName="totalSize"  (input)="onInput($event)"
                    type="text"  >
                    <mat-error *ngIf="firstFormGroup.controls['totalSize'].hasError('required')">This field is required</mat-error>
                  </mat-form-field> 
                </div>
                <div class="col-sm-4 mobile">
  
                  
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="sizeUnit">
                      <mat-option value="Sqm">Square Meter</mat-option>
                      <mat-option value="Sqft">Square Foot</mat-option>
                      <mat-option value="Ac">Acre</mat-option>
                      <mat-option value="Sqyd">Square Yard</mat-option>
                      <mat-option value="Killa">Killa</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['sizeUnit'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
              
            </div>
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile">
                  <mat-form-field>
                    <mat-label>Price</mat-label>
                    <input matInput placeholder="" formControlName="aaskingPrice"  (input)="onInput($event)"
                     type="text">
                    <mat-error *ngIf="firstFormGroup.controls['aaskingPrice'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="aaskingPriceUnit" >
                      <mat-option value="Lac">Lac</mat-option>
                      <mat-option value="Cr">Crore</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['aaskingPriceUnit'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                
              </div>
            </div>
          </div>

            <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Total Size</mat-label>
              <input matInput placeholder="" formControlName="totalSize" type="text" (input)="onInput($event)">
              <mat-error *ngIf="firstFormGroup.controls['totalSize'].hasError('required')">This field
                is required</mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Unit</mat-label>
              <mat-select formControlName="sizeUnit">
                <mat-option value="Sqm">Square Meter</mat-option>
                <mat-option value="Sqft">Square Foot</mat-option>
                <mat-option value="Ac">Acre</mat-option>
                <mat-option value="Sqyd">Square Yard</mat-option>
                <mat-option value="Killa">Killa</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['sizeUnit'].hasError('required')">This field
                is required</mat-error>
            </mat-form-field> -->
          </div>
        </div>

        <br>

        <br>

        <br>
        <div class="row col-md-12">
          <div class="custom-form-field4">
            <mat-form-field class="custom-form-field1">
              <div>
                <mat-toolbar>
                  <input matInput
                    [value]="selectedFileNames.length ? selectedFileNames.join(', ') : 'Upload Plan Image'" readonly />
                  <button mat-flat-button color="primary" type="button" [disabled]="!selectedFiles.length"
                    (click)="uploadFile()">Upload</button>
                </mat-toolbar>
                <input type="file" id="fileInput" formControlName="UploadPlaneImages" (change)="handleChange($event)"
                  name="fileInput" accept="image/*" multiple hidden />
                <button mat-stroked-button type="button" color="primary" (click)="triggerFileInput()">Browse</button>
              </div>
              <div class="uploaded-images">
                <div *ngFor="let image of uploadedImages; let i = index" class="uploaded-image-container">
                  <img [src]="image" alt="Uploaded Image" class="uploaded-image" />
                  <button mat-icon-button style="color:#004aad;" (click)="removeImage(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-form-field>

          </div>
        </div>
        <div class="button-container">
          <button mat-button class="custom-button font1 onlyhover" type="button" (click)="handleCancel()">Cancel</button>
          <button mat-button class="custom-button font1 onlyhover" type="submit" style="color: whitesmoke">Next</button>
        </div>
      </form>

    </mat-step>

    <!-- <mat-step   [stepControl]="selectedValue === 'Plot' ? PlotFormGroup : selectedValue === 'Flat' ? FlatFormGroup : null" label="Features"> -->
    <mat-step [stepControl]="showPlotForm ? PlotFormGroup : 
                            showFlatForm ? FlatFormGroup : 
                            showVillasForm ? villasFormGroup :
                            showCommercialsForm ? commercialsFormGroup :
                            showMansionsForm ? mansionsFormGroup :
                            showOfficesForm ? officesFormGroup :
                            null" [label]="getStepLabel()">


      <div *ngIf="showPlotForm">
        <form [formGroup]="PlotFormGroup">
          <div>Plot Feature</div>
          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title Deed</mat-label>
            <mat-select placeholder="Title Deed" formControlName="titleDeed">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['titleDeed'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Encumbrance Certificate</mat-label>
            <mat-select placeholder="Encumbrance Certificate" formControlName="encumbranceCertificate">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['encumbranceCertificate'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Taxes</mat-label>
            <mat-select placeholder="Property Taxes" formControlName="propertyTaxes">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['propertyTaxes'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location">
            <mat-error *ngIf="PlotFormGroup.controls['location'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Uniformity of the Plot</mat-label>
            <mat-select formControlName="uniformityOfThePlot">
              <mat-option value="high">High Uniformity</mat-option>
              <mat-option value="medium">Medium Uniformity</mat-option>
              <mat-option value="low">Low Uniformity</mat-option>
              <mat-option value="varied">Varied Uniformity</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['uniformityOfThePlot'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenities</mat-label>
            <mat-select formControlName="amenity" multiple>
              <mat-option value="pool">Swimming Pool</mat-option>
              <mat-option value="gym">Gym</mat-option>
              <mat-option value="garden">Garden</mat-option>
              <mat-option value="parking">Parking</mat-option>
              <mat-option value="security">Security</mat-option>
              <mat-option value="wifi">Wi-Fi</mat-option>
              <mat-option value="fitnesscentre">Fitness centre</mat-option>
              <mat-option value="table">Billiards Table</mat-option>
              <mat-option value="lockers">Package lockers</mat-option>
              <mat-option value="playgrounds">Playgrounds</mat-option>
              <mat-option value="walkingpaths">Walking paths</mat-option>
              <mat-option value="valet">Valet trash</mat-option>
              <mat-option value="cameras">Surveillance cameras</mat-option>
              <mat-option value="green">Green surrounding neighborhood</mat-option>
              <mat-option value="clubhouse">Clubhouse</mat-option>
              <mat-option value="party">Party room</mat-option>
              <mat-option value="rooftop">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['amenity'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Completion Certificate</mat-label>
            <mat-select formControlName="completionCertificate">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['completionCertificate'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Soil Quality</mat-label>
            <mat-select formControlName="soilQuality">
              <mat-option value="excellent">Excellent</mat-option>
              <mat-option value="good">Good</mat-option>
              <mat-option value="average">Average</mat-option>
              <mat-option value="poor">Poor</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['soilQuality'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Access to the Plot</mat-label>
            <mat-select formControlName="accessToThePlot">
              <mat-option value="easy">Easy Access</mat-option>
              <mat-option value="moderate">Moderate Access</mat-option>
              <mat-option value="difficult">Difficult Access</mat-option>
              <mat-option value="no-access">No Access</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['accessToThePlot'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Avoid Verbal Agreement</mat-label>
            <mat-select formControlName="avoidVerbalAgreement">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['avoidVerbalAgreement'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Builder</mat-label>
            <input matInput placeholder="Builder" formControlName="builder">
            <mat-error *ngIf="PlotFormGroup.controls['builder'].hasError('required')">This field is required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Connectivity</mat-label>
            <mat-select formControlName="connectivity">
              <mat-option value="excellent">Excellent</mat-option>
              <mat-option value="good">Good</mat-option>
              <mat-option value="average">Average</mat-option>
              <mat-option value="poor">Poor</mat-option>
              <mat-option value="none">None</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['connectivity'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select formControlName="constructionApprovals">
              <mat-option value="approved">Approved</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="not-applicable">Not Applicable</mat-option>
              <mat-option value="denied">Denied</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['constructionApprovals'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Flat Purchase Checklist</mat-label>
            <mat-select formControlName="flatPurchaseChecklist">
              <mat-option value="documents">Documents</mat-option>
              <mat-option value="inspection">Inspection</mat-option>
              <mat-option value="payment">Payment</mat-option>
              <mat-option value="agreement">Agreement</mat-option>
              <mat-option value="registration">Registration</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['flatPurchaseChecklist'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Perspective</mat-label>
            <mat-select formControlName="investmentPerspective">
              <mat-option value="high-return">High Return</mat-option>
              <mat-option value="moderate-return">Moderate Return</mat-option>
              <mat-option value="low-return">Low Return</mat-option>
              <mat-option value="risk">Risk</mat-option>
              <mat-option value="safe">Safe</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['investmentPerspective'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>NA Order</mat-label>
            <mat-select formControlName="naOrder">
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="completed">Completed</mat-option>
              <mat-option value="canceled">Canceled</mat-option>
              <mat-option value="in-progress">In Progress</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['naOrder'].hasError('required')">This field is required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Power of Attorney</mat-label>
            <mat-select formControlName="powerOfAttorney">
              <mat-option value="full">Full</mat-option>
              <mat-option value="limited">Limited</mat-option>
              <mat-option value="special">Special</mat-option>
              <mat-option value="none">None</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['powerOfAttorney'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select formControlName="security">
              <mat-option value="high">High</mat-option>
              <mat-option value="medium">Medium</mat-option>
              <mat-option value="low">Low</mat-option>
              <mat-option value="none">None</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['security'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Water & Electricity Supply</mat-label>
            <mat-select formControlName="waterElectricitySupply">
              <mat-option value="available">Available</mat-option>
              <mat-option value="not-available">Not Available</mat-option>
              <mat-option value="partially-available">Partially Available</mat-option>
              <mat-option value="pending">Pending</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['waterElectricitySupply'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Zoning Risks</mat-label>
            <mat-select formControlName="zoningRisks">
              <mat-option value="high">High</mat-option>
              <mat-option value="moderate">Moderate</mat-option>
              <mat-option value="low">Low</mat-option>
              <mat-option value="none">None</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['zoningRisks'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Approvals</mat-label>
            <mat-select formControlName="approvals">
              <mat-option value="approved">Approved</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="rejected">Rejected</mat-option>
              <mat-option value="in-process">In Process</mat-option>
              <mat-option value="not-required">Not Required</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['approvals'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>DueDiligence</mat-label>
            <mat-select formControlName="dueDiligence">
              <mat-option value="completed">Completed</mat-option>
              <mat-option value="in-progress">In Progress</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="not-required">Not Required</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['dueDiligence'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Encumbrance</mat-label>
            <mat-select formControlName="encumbrance">
              <mat-option value="clear">Clear</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="encumbered">Encumbered</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['encumbrance'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>HistoryOfTheProperty</mat-label>
            <mat-select formControlName="historyOfTheProperty">
              <mat-option value="recent">Recent</mat-option>
              <mat-option value="old">Old</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
              <mat-option value="inherited">Inherited</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['historyOfTheProperty'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>



          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>
      <div *ngIf="showFlatForm">
        <form [formGroup]="FlatFormGroup">
          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenity</mat-label>
            <mat-select placeholder="Select Amenities" formControlName="amenity" multiple>
              <mat-option>None</mat-option>
            <mat-option value="pool">Swimming Pool</mat-option>
              <mat-option value="gym">Gym</mat-option>
              <mat-option value="garden">Garden</mat-option>
              <mat-option value="parking">Parking</mat-option>
              <mat-option value="security">Security</mat-option>
              <mat-option value="wifi">Wi-Fi</mat-option>
              <mat-option value="fitnesscentre">Fitness centre</mat-option>
              <mat-option value="table">Billiards Table</mat-option>
              <mat-option value="lockers">Package lockers</mat-option>
              <mat-option value="playgrounds">Playgrounds</mat-option>
              <mat-option value="walkingpaths">Walking paths</mat-option>
              <mat-option value="valet">Valet trash</mat-option>
              <mat-option value="cameras">Surveillance cameras</mat-option>
              <mat-option value="green">Green surrounding neighborhood</mat-option>
              <mat-option value="clubhouse">Clubhouse</mat-option>
              <mat-option value="party">Party room</mat-option>
              <mat-option value="rooftop">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['amenity'].hasError('required')">This field is required</mat-error>
          </mat-form-field>



          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>RERA Compliance</mat-label>
            <mat-select placeholder="Select RERA Compliance" formControlName="reraCompliance">
              <mat-option>None</mat-option>
              <mat-option value="compliant">Compliant</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="exempt">Exempt</mat-option>
              <mat-option value="under-review">Under Review</mat-option>
              <mat-option value="not-compliant">Not Compliant</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['reraCompliance'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Budget</mat-label>
            <input matInput placeholder="Select Budget" formControlName="budget">
            <mat-error *ngIf="FlatFormGroup.controls['budget'].hasError('required')">This field is required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location">
            <mat-error *ngIf="FlatFormGroup.controls['location'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Occupancy Certificate</mat-label>
            <mat-select placeholder="Select Occupancy Certificate" formControlName="occupancyCertificate">
              <mat-option>None</mat-option>
              <mat-option value="issued">Issued</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="not-applied">Not Applied</mat-option>
              <mat-option value="exempt">Exempt</mat-option>
              <mat-option value="under-review">Under Review</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['occupancyCertificate'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Area of Flat</mat-label>
            <input matInput placeholder="Area of Flat" formControlName="areaOfFlat">
            <mat-error *ngIf="FlatFormGroup.controls['areaOfFlat'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Bank NOC</mat-label>
            <mat-select placeholder="Select Bank NOC" formControlName="bankNOC" >
              <mat-option>None</mat-option>
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['bankNOC'].hasError('required')">This field is required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Quality</mat-label>
            <mat-select placeholder="Select Construction Quality" formControlName="constructionQuality" >
                <mat-option>None</mat-option>
              <mat-option value="excellent">Excellent</mat-option>
              <mat-option value="good">Good</mat-option>
              <mat-option value="average">Average</mat-option>
              <mat-option value="poor">Poor</mat-option>
              <mat-option value="needs-improvement">Needs Improvement</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['constructionQuality'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Choose Reputed Builders</mat-label>
            <input matInput placeholder="Choose Reputed Builders" formControlName="reputedBuilders">
            <mat-error *ngIf="FlatFormGroup.controls['reputedBuilders'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select placeholder="Select Construction Approvals" formControlName="constructionApprovals">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['constructionApprovals'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Legal Check of Property</mat-label>
            <mat-select placeholder="Select Legal Check of Property" formControlName="legalCheckOfProperty">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['legalCheckOfProperty'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title Verification</mat-label>
            <mat-select placeholder="Select Title Verification" formControlName="titleVerification">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['titleVerification'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Apartment Possession</mat-label>
            <input matInput placeholder="Apartment Possession" formControlName="apartmentPossession">
            <mat-error *ngIf="FlatFormGroup.controls['apartmentPossession'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Avoid Verbal Agreement</mat-label>
            <mat-select placeholder="Select Avoid Verbal Agreement" formControlName="verbalAgreement">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['verbalAgreement'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Builder-Buyer Agreement</mat-label>
            <mat-select placeholder="Select Agreement" formControlName="builderBuyerAgreement">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['builderBuyerAgreement'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Encumbrance</mat-label>
            <input matInput placeholder="Encumbrance" formControlName="encumbrance">
            <mat-error *ngIf="FlatFormGroup.controls['encumbrance'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Land Use Permissions</mat-label>
            <mat-select placeholder="Select Permission" formControlName="landUsePermissions">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['landUsePermissions'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Physical visit of the location</mat-label>
            <mat-select placeholder="Select options" formControlName="physicalVisit" >
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['physicalVisit'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>



          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Location</mat-label>
            <input matInput placeholder="Property Location" formControlName="propertyLocation">
            <mat-error *ngIf="FlatFormGroup.controls['propertyLocation'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>
         
          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Taxes</mat-label>
            <mat-select placeholder="Select options" formControlName="propertyTaxes" >
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['propertyTaxes'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Resale Value</mat-label>
            <mat-select placeholder="Select Resale Values" formControlName="resaleValue" >
              <mat-option>None</mat-option>
              <mat-option value="low">Low</mat-option>
              <mat-option value="medium">Medium</mat-option>
              <mat-option value="high">High</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['resaleValue'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Sale Agreement</mat-label>
            <mat-select placeholder="Select Sale Agreements" formControlName="saleAgreement" >
              <mat-option>None</mat-option>
              <mat-option value="signed">Signed</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="notApplicable">Not Applicable</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['saleAgreement'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select placeholder="Select Security" formControlName="security">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['security'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Service Tax</mat-label>
            <mat-select placeholder="Select Service Tax" formControlName="serviceTax">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['serviceTax'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>

        </form>
      </div>
      
      <div *ngIf="showVillasForm">
        <form [formGroup]="villasFormGroup">
          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenities</mat-label>
            <mat-select placeholder="Select Amenities" formControlName="amenity" multiple>
              <mat-option>None</mat-option>
              <mat-option value="pool">Swimming Pool</mat-option>
                <mat-option value="gym">Gym</mat-option>
                <mat-option value="garden">Garden</mat-option>
                <mat-option value="parking">Parking</mat-option>
                <mat-option value="security">Security</mat-option>
                <mat-option value="wifi">Wi-Fi</mat-option>
                <mat-option value="fitnesscentre">Fitness centre</mat-option>
                <mat-option value="table">Billiards Table</mat-option>
                <mat-option value="lockers">Package lockers</mat-option>
                <mat-option value="playgrounds">Playgrounds</mat-option>
                <mat-option value="walkingpaths">Walking paths</mat-option>
                <mat-option value="valet">Valet trash</mat-option>
                <mat-option value="cameras">Surveillance cameras</mat-option>
                <mat-option value="green">Green surrounding neighborhood</mat-option>
                <mat-option value="clubhouse">Clubhouse</mat-option>
                <mat-option value="party">Party room</mat-option>
                <mat-option value="rooftop">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['amenity'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Taxes</mat-label>
            <mat-select placeholder="Select options" formControlName="propertyTaxes">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['propertyTaxes'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Quality</mat-label>
            <mat-select placeholder="Select Construction Quality" formControlName="constructionQuality" >
              <mat-option>None</mat-option>
              <mat-option value="excellent">Excellent</mat-option>
              <mat-option value="good">Good</mat-option>
              <mat-option value="average">Average</mat-option>
              <mat-option value="poor">Poor</mat-option>
              <mat-option value="needs-improvement">Needs Improvement</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['constructionQuality'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Occupancy Certificate</mat-label>
            <mat-select placeholder="Select Occupancy Certificate" formControlName="occupancyCertificate" >
              <mat-option value="issued">Issued</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="not-applied">Not Applied</mat-option>
              <mat-option value="exempt">Exempt</mat-option>
              <mat-option value="under-review">Under Review</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['occupancyCertificate'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select placeholder="Select Security" formControlName="security">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['security'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Legal Compliance</mat-label>
            <mat-select placeholder="Select Legal Compliance" formControlName="legalCompliance">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['legalCompliance'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location">
            <mat-error *ngIf="villasFormGroup.controls['location'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location Considerations</mat-label>
            <input matInput placeholder="Location Considerations" formControlName="locationConsiderations">
            <mat-error *ngIf="villasFormGroup.controls['locationConsiderations'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title Deed</mat-label>
            <mat-select placeholder="Title Deed" formControlName="titleDeed">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['titleDeed'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Accessibility</mat-label>
            <mat-select placeholder="Select Accessibility" formControlName="accessibility" >
              <mat-option>None</mat-option>
              <mat-option value="Wheelchair Accessible">Wheelchair Accessible</mat-option>
              <mat-option value="Elevator">Elevator</mat-option>
              <mat-option value="Ramps">Ramps</mat-option>
              <mat-option value="Braille Signage">Braille Signage</mat-option>
              <mat-option value="Hearing Assistance">Hearing Assistance</mat-option>
              <mat-option value="Accessible Parking">Accessible Parking</mat-option>
              <mat-option value="Sign Language Interpreters">Sign Language Interpreters</mat-option>
              <mat-option value="Accessible Restrooms">Accessible Restrooms</mat-option>
              <mat-option value="Guide Dogs Allowed">Guide Dogs Allowed</mat-option>
              <mat-option value="Accessible Entrances">Accessible Entrances</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['accessibility'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Budget</mat-label>
            <input matInput placeholder="Budget" formControlName="budget">
            <mat-error *ngIf="villasFormGroup.controls['budget'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Builder Reputation</mat-label>
            <!-- <input matInput placeholder="Builder Reputation" formControlName="builderReputation"> -->
            <mat-select placeholder="Builder Reputation" formControlName="builderReputation">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['builderReputation'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select placeholder="Select Construction Approvals" formControlName="constructionApprovals" >
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['constructionApprovals'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Documents for Property Use</mat-label>
            <mat-select placeholder="Select Option" formControlName="documentsForPropertyUse">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['documentsForPropertyUse'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Home Loan</mat-label>
            <mat-select placeholder="Select Option" formControlName="homeLoan">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['homeLoan'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Perspective</mat-label>
            <mat-select placeholder="Select Option" formControlName="investmentPerspective">
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Not Sure">Not Sure</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['investmentPerspective'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Land Documents</mat-label>
            <mat-select placeholder="Select Land Document" formControlName="landDocuments">
              <mat-option>None</mat-option>
              <mat-option value="Title Deed">Title Deed</mat-option>
              <mat-option value="Sale Deed">Sale Deed</mat-option>
              <mat-option value="Land Use Certificate">Land Use Certificate</mat-option>
              <mat-option value="Encumbrance Certificate">Encumbrance Certificate</mat-option>
              <!-- Add other options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['landDocuments'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Modern Features</mat-label>
            <mat-select placeholder="Select Modern Feature" formControlName="modernFeatures" >
              <mat-option>None</mat-option>
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Home Theater">Home Theater</mat-option>
              <mat-option value="Smart Home System">Smart Home System</mat-option>
              <mat-option value="Jacuzzi">Jacuzzi</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['modernFeatures'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>No Objection Certificates</mat-label>
            <mat-select placeholder="Select No Objection Certificate" formControlName="noObjectionCertificates">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <!-- Add other options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['noObjectionCertificates'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Outdoor Amenities</mat-label>
            <mat-select placeholder="Select Outdoor Amenities" formControlName="outdoorAmenities" >
              <mat-option>None</mat-option>
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Garden">Garden</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Playground">Playground</mat-option>
              <mat-option value="BBQ Area">BBQ Area</mat-option>
              <!-- Add other options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['outdoorAmenities'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Pricing</mat-label>
            <input matInput placeholder="Enter Pricing" formControlName="pricing">
            <!-- <mat-select placeholder="Select Pricing" formControlName="pricing">
              <mat-option value="Below 1000k">Below 1000k</mat-option>
              <mat-option value="1000k- 3000k">1000k - 3000k</mat-option>
              <mat-option value="3000k - 5000k">3000k - 5000k</mat-option>
              <mat-option value="Above 5000k">Above 5000k</mat-option>
              <!-- Add other pricing ranges as needed -->
            <!-- </mat-select>  -->
            <mat-error *ngIf="villasFormGroup.controls['pricing'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Privacy and Space</mat-label>
            <mat-select placeholder="Select Privacy and Space" formControlName="privacyAndSpace">
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['privacyAndSpace'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Real Estate Developer</mat-label>
            <mat-select placeholder="Select Real Estate Developer" formControlName="realEstateDeveloper">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['realEstateDeveloper'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Size of Property</mat-label>
            <input matInput placeholder="Size of Property" formControlName="sizeOfProperty">
            <mat-error *ngIf="villasFormGroup.controls['sizeOfProperty'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field>

          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>
      <div *ngIf="showCommercialsForm">
        <form [formGroup]="commercialsFormGroup">

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Market Research</mat-label>
            <mat-select placeholder="Select Market Research" formControlName="marketResearch">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['marketResearch'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location">
            <mat-error *ngIf="commercialsFormGroup.controls['location'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Commercial Property Types</mat-label>
            <mat-select formControlName="commercialPropertyTypes">
              <mat-option *ngFor="let type of propertyTypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['commercialPropertyTypes'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Floor Plan</mat-label>
            <mat-select placeholder="Select Floor Plans" formControlName="floorPlan">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['commercialPropertyTypes'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Lease Structure</mat-label>
            <mat-select placeholder="Select Lease Structure" formControlName="leaseStructure">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['leaseStructure'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Choose the Right Builder</mat-label>
            <input matInput placeholder="Choose the Right Builder" formControlName="rightBuilder">
            <mat-error *ngIf="commercialsFormGroup.controls['rightBuilder'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Risk and Return</mat-label>
            <mat-select placeholder="Select Risk and Return" formControlName="riskAndReturn">
              <mat-option>None</mat-option>
              <mat-option value="low">Low</mat-option>
              <mat-option value="medium">Medium</mat-option>
              <mat-option value="high">High</mat-option>
              <mat-option value="very-high">Very High</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['riskAndReturn'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Value Assessment</mat-label>
            <input matInput placeholder="Value Assessment" formControlName="valueAssessment">
            <mat-error *ngIf="commercialsFormGroup.controls['valueAssessment'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Evaluate Rental Income</mat-label>
            <mat-select placeholder="Select Rental Income Evaluation" formControlName="rentalIncome">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['rentalIncome'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Tenant Profiles</mat-label>
            <input matInput placeholder="Tenant Profiles" formControlName="tenantProfiles">
            <mat-error *ngIf="commercialsFormGroup.controls['tenantProfiles'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>

      <div *ngIf="showOfficesForm">
        <form [formGroup]="officesFormGroup">

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location">
            <mat-error *ngIf="officesFormGroup.controls['location'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenity</mat-label>
            <mat-select placeholder="Select Amenities" formControlName="amenity" multiple>
              <mat-option value="pool">Swimming Pool</mat-option>
              <mat-option value="gym">Gym</mat-option>
              <mat-option value="garden">Garden</mat-option>
              <mat-option value="parking">Parking</mat-option>
              <mat-option value="security">Security</mat-option>
              <mat-option value="wifi">Wi-Fi</mat-option>
              <mat-option value="fitnesscentre">Fitness centre</mat-option>
              <mat-option value="table">Billiards Table</mat-option>
              <mat-option value="lockers">Package lockers</mat-option>
              <mat-option value="playgrounds">Playgrounds</mat-option>
              <mat-option value="walkingpaths">Walking paths</mat-option>
              <mat-option value="valet">Valet trash</mat-option>
              <mat-option value="cameras">Surveillance cameras</mat-option>
              <mat-option value="green">Green surrounding neighborhood</mat-option>
              <mat-option value="clubhouse">Clubhouse</mat-option>
              <mat-option value="party">Party room</mat-option>
              <mat-option value="rooftop">rooftop garden</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['amenity'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Goals</mat-label>
            <mat-select placeholder="Select Investment Goals" formControlName="investmentGoals" multiple>
              <mat-option value="growth">Growth</mat-option>
              <mat-option value="income">Income</mat-option>
              <mat-option value="preservation">Preservation</mat-option>
              <mat-option value="speculation">Speculation</mat-option>
              <mat-option value="diversification">Diversification</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['investmentGoals'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title</mat-label>
            <input matInput placeholder="Title" formControlName="title">
            <mat-error *ngIf="officesFormGroup.controls['title'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Cash Flow</mat-label>
            <mat-select placeholder="Select Cash Flow Options" formControlName="cashFlow" multiple>
     
              <mat-option value="positive">Positive</mat-option>
              <mat-option value="negative">Negative</mat-option>
              <mat-option value="neutral">Neutral</mat-option>
              <mat-option value="variable">Variable</mat-option>
              <mat-option value="stable">Stable</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['cashFlow'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Check Legal Documents</mat-label>
            <mat-select placeholder="Select an option" formControlName="legalDocuments">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['legalDocuments'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Financial Considerations</mat-label>
            <mat-select placeholder="Select Financial Considerations" formControlName="financialConsiderations">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['financialConsiderations'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Infrastructure</mat-label>
            <input matInput placeholder="Infrastructure" formControlName="infrastructure">
            <mat-error *ngIf="officesFormGroup.controls['infrastructure'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Lease Structure</mat-label>
            <input matInput placeholder="Lease Structure" formControlName="leaseStructure">
            <mat-error *ngIf="officesFormGroup.controls['leaseStructure'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Market Research</mat-label>
            <mat-select placeholder="Market Research" formControlName="marketResearch">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['marketResearch'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Tax Payment Status</mat-label>
            <mat-select placeholder="Select a status" formControlName="taxPaymentStatus">
              <mat-option value="paid">Paid</mat-option>
              <mat-option value="pending">Pending</mat-option>
              <mat-option value="overdue">Overdue</mat-option>
              <mat-option value="exempt">Exempt</mat-option>
              <mat-option value="notApplicable">Not Applicable</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['taxPaymentStatus'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Value Assessment</mat-label>
            <input matInput placeholder="Value Assessment" formControlName="valueAssessment">
            <mat-error *ngIf="officesFormGroup.controls['valueAssessment'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Documentation</mat-label>
            <mat-select placeholder="Property Documentation" formControlName="propertyDocumentation">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['propertyDocumentation'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Choose the Right Builder</mat-label>
            <input matInput placeholder="Choose the Right Builder" formControlName="rightBuilder">
            <mat-error *ngIf="officesFormGroup.controls['rightBuilder'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Location</mat-label>
            <input matInput placeholder="Property Location" formControlName="propertyLocation">
            <mat-error *ngIf="officesFormGroup.controls['propertyLocation'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>


      <div *ngIf="showMansionsForm">
        <form [formGroup]="mansionsFormGroup">

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location">
            <mat-error *ngIf="mansionsFormGroup.controls['location'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select placeholder="Select an option" formControlName="security">
              <mat-option value="high">High</mat-option>
              <mat-option value="medium">Medium</mat-option>
              <mat-option value="low">Low</mat-option>
              <mat-option value="none">None</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['security'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenity</mat-label>
            <mat-select placeholder="Select an amenity" formControlName="amenity" multiple>
              <mat-option value="pool">Swimming Pool</mat-option>
              <mat-option value="gym">Gym</mat-option>
              <mat-option value="garden">Garden</mat-option>
              <mat-option value="parking">Parking</mat-option>
              <mat-option value="security">Security</mat-option>
              <mat-option value="wifi">Wi-Fi</mat-option>
              <mat-option value="fitnesscentre">Fitness centre</mat-option>
              <mat-option value="table">Billiards Table</mat-option>
              <mat-option value="lockers">Package lockers</mat-option>
              <mat-option value="playgrounds">Playgrounds</mat-option>
              <mat-option value="walkingpaths">Walking paths</mat-option>
              <mat-option value="valet">Valet trash</mat-option>
              <mat-option value="cameras">Surveillance cameras</mat-option>
              <mat-option value="green">Green surrounding neighborhood</mat-option>
              <mat-option value="clubhouse">Clubhouse</mat-option>
              <mat-option value="party">Party room</mat-option>
              <mat-option value="rooftop">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['amenity'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Trusted Developer</mat-label>
            <mat-select placeholder="Trusted Developer" formControlName="trustedDeveloper">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['trustedDeveloper'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select placeholder="Construction Approvals" formControlName="constructionApprovals">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['constructionApprovals'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Documents for Property Use</mat-label>
            <mat-select placeholder="Select an option" formControlName="documentsForPropertyUse">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['documentsForPropertyUse'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>High-End Kitchens</mat-label>
            <mat-select placeholder="Select an option" formControlName="highEndKitchens">
              <mat-option value="luxury">Luxury</mat-option>
              <mat-option value="deluxe">Deluxe</mat-option>
              <mat-option value="elite">Elite</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['highEndKitchens'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Perspective</mat-label>
            <mat-select placeholder="Select Option" formControlName="investmentPerspective">
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Not Sure">Not Sure</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['investmentPerspective'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Smart Homes</mat-label>
            <mat-select placeholder="Select an option" formControlName="smartHomes">
              <mat-option value="basic">Basic</mat-option>
              <mat-option value="advanced">Advanced</mat-option>
              <mat-option value="premium">Premium</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['smartHomes'].hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>


          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>

    </mat-step>
  </mat-stepper>
</div>

</div>
<app-footer></app-footer>
</div>