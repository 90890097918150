import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridOptions } from 'ag-grid-community';
import { HomeService } from '../_services/home.service';
import { ActionComponent } from '../Action/action.component';

@Component({
  selector: 'app-project-inventory',
  templateUrl: './project-inventory.component.html',
  styleUrls: ['./project-inventory.component.scss']
})
export class ProjectInventoryComponent implements OnInit {
  projectId: string | null = null;
  searchValue: string = '';
  IsLoading: boolean = false;
  topList: any[] = [];
  showButton: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.projectId = params.get('projectId');
      console.log('Received ProjectId:', this.projectId);
      if (this.projectId) {
        localStorage.setItem('projectId', this.projectId);
      }
      this.projectId= localStorage.getItem("projectId")
      console.log('localprojectId',this.projectId);
      this.route.queryParams.subscribe(params => {
        this.showButton = params['showButton'] === 'false' ? false : true;
      });
      this.apiService();
    });
  }

  // navigateToAddInventory() {
  //  let userinfo =localStorage.getItem("auth-token")
  //   if(userinfo){
  //     this.router.navigate(['/add-invetory'], {
  //       queryParams: { projectId: this.projectId }
  //     });
  //   }else{
  //     this.router.navigate(['/login']),
  //   }
 
  // }
  
  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList(); 
  }

  getProjectList(): void {
    this.IsLoading = true;

    // Ensure that the searchValue is passed as an empty string if it's undefined
    this.homeService.getProjectList('top', this.searchValue || '').subscribe((response: any) => {
      this.IsLoading = false;
      if (response.status === 'SUCCESS') {
        this.topList = response.payload.items;
      } else {
        console.error('Failed to fetch projects');
      }
    }, error => {
      this.IsLoading = false;
      console.error('Error fetching projects:', error);
    });
  }


  navigateToAddInventory() {
    let userinfo =localStorage.getItem("auth-token")
   if(userinfo){
    this.router.navigate(['/add-invetory'], {
            queryParams: { projectId: this.projectId }
          });
   }else{
    this.router.navigate(['/login'])
   }
  }

  stripHtmlTags(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.body.textContent || '';
  }

  
  apiService() {
    this.IsLoading = true;
    this.homeService.getInventoryByProjectId(this.projectId).subscribe((res: any) => {
      this.IsLoading = false;
        if (res.status === 'SUCCESS' && res.payload) {
          this.topList=res.payload
          // this.topList = res.payload.map((item: any) => ({
          //   ...item,
          //  ...item.project,
           
          // }));
          console.log("toplistaaaaa",this.topList)
        } else {
          this.topList = []; 
        }
        
    },
    () => {
      this.IsLoading = false;
      this.topList = []; // Handle error case
    });
  }

  
  ngOnDestroy() {
  
  }

  
}
