import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ActivatedRoute } from '@angular/router';
;
@Component({
  selector: 'app-property-owner',
  templateUrl: './property-owner.component.html',
  styleUrls: ['./property-owner.component.scss']
})
export class PropertyOwnerComponent implements OnInit {
  listingData: any;
  formattedDate: string;
  contactForm: FormGroup;
  userProfileData: any = {};
  name: string = '';
  email: string = '';
  mobile: string = '';
  owner:any
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private homeservice:HomeService,
    private route:ActivatedRoute,
    public dialogRef: MatDialogRef<PropertyOwnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const dateString = "2023-07-23T10:20:30Z";
    this.formattedDate = this.formatDate(dateString);

    this.contactForm = this.fb.group({
      reasonToBuy: ['', Validators.required],
      propertyDealer: ['', Validators.required],
      name: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      mobile: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      planningToBuy: [''],
      homeLoan: [false],
      siteVisits: [false],
      terms: [false ,Validators.requiredTrue]
    });
  }

  loginUser() {
    const profileData = localStorage.getItem('auth-user');
    if (profileData) {
      try {
        let userProfileData1 = JSON.parse(profileData); 
        this.userProfileData = JSON.parse(userProfileData1);
        this.contactForm.patchValue({
          name: this.userProfileData.fullName || 'N/A',
          email: this.userProfileData.email || 'N/A',
          mobile: this.userProfileData.mobileNumber || 'N/A'
        });
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    } else {
      console.log("No profile data found in localStorage");
    }
  }

  ngOnInit(): void {
    this.loginUser();
    this.listingData = this.data;
    // let propertyid =this.listingData.id;
    // console.log("propertyid",propertyid)
    console.log("this.listingData", this.listingData);
  }

  // formatDate(dateString: string): string {
  //   const date = new Date(dateString);
  //   if (isNaN(date.getTime())) {
  //     return "Invalid Date";
  //   }

  //   const day = date.getDate().toString().padStart(2, '0');
  //   const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  //   const month = monthNames[date.getMonth()];
  //   const year = date.getFullYear();

  //   return `${day}TH ${month}, ${year}`;
  // }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
  
    // Function to get the appropriate suffix for the day
    function getDaySuffix(day: number): string {
      if (day > 3 && day < 21) return 'th'; // Special case for days 11–13
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }
  
    const daySuffix = getDaySuffix(day);
  
    return `${day}${daySuffix} ${month}, ${year}`;
  }
  

  closeDialog() {
    this.dialogRef.close();
  }

  
 

  
  onSubmit() {
    if (this.contactForm.valid) {
      // const inventoryId = 0;
      const id = this.route.snapshot.queryParamMap.get('id'); // Get the id parameter
      console.log("Extracted ID from query parameters:", id);
      const payload = {
        ...this.contactForm.value,
        name: this.contactForm.get('name').value,
        email: this.contactForm.get('email').value,
        mobile: this.contactForm.get('mobile').value,
        inventoryId:'', 
        propertyId:'' ,
        projectId:'',  
      };
      const currentPath = this.route.snapshot.firstChild?.routeConfig?.path;
      console.log("Current path:", currentPath);
      if (currentPath === 'property-details') {
        payload.propertyId = id; 
        console.log("Property ID assigned:", payload.propertyId);
      } else if (currentPath === 'topProject') {
        payload.projectId = id; 
        console.log("Project ID assigned:", payload.projectId);
      } else {
        console.log("No valid route found for ID assignment");
      }
  
      console.log("Final payload before submission:", payload);
      this.homeservice.ownwerdetail(payload).subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            Swal.fire({
              icon: 'success',
              title: 'Thanks For Submitting',
              showConfirmButton: false,
              timer: environmentbasic.timer
            });
            this.dialogRef.close();
          } else {
            Swal.fire({
              icon: 'error',
              title: response['message'],
              showConfirmButton: false,
              timer: environmentbasic.timer
            });
          }
        },
        (error) => {
          console.error("Error occurred while submitting data:", error); // Log any error
          Swal.fire({
            icon: 'error',
            title: 'Submission Failed',
            text: error.message || 'An error occurred while submitting your data.',
            showConfirmButton: true
          });
        }
      );
  
    } 
    else {
      console.log("Form is not valid");
      Swal.fire({
        icon: 'warning',
        title: 'Form Validation Failed',
        text: 'Please fill out all required fields.',
        showConfirmButton: true
      });
    }
  }
  
  
  
  
  
  
}
