
<div class="page-container">
    <app-header></app-header>
    <app-menu></app-menu><br>
<div style="margin-top: 10px;">
    <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
  </div>
  
    <div *ngIf="!IsLoading" class="card-container">
      <ng-container *ngFor="let card of recentList">
        <app-top-property-card-small [cardData]="card" class="card-item"></app-top-property-card-small>
      </ng-container>
    </div>
    
    <div *ngIf="IsLoading">Loading...</div>
    <div class="pagination-container">
      <span class="page-info">Page {{ currentPage + 1 }} of {{ totalPages }}</span>
    
      <div class="pagination">
        <button
          class="page-link"
          (click)="changePage(currentPage - 1)"
          [disabled]="currentPage === 0"
        >
          &lt; Previous Page
        </button>
    
        <ng-container *ngFor="let page of getPages()">
          <button
            class="page-number"
            [class.active]="currentPage === page"
            (click)="changePage(page)"
          >
            {{ page + 1 }}
          </button>
        </ng-container>
    
        <button
          class="page-link"
          (click)="changePage(currentPage + 1)"
          [disabled]="currentPage + 1 >= totalPages"
        >
          Next Page &gt;
        </button>
      </div>
    </div>
    
    <app-footer></app-footer>
</div>