import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { ErrorMessageService } from '../services/error-message.service';
import { AuthService } from '../_services/auth.service';
@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.scss']
})
export class ProfileUpdateComponent implements OnInit {
  profilePic: string = '../assets/images/profile.png';
  updateform: FormGroup;
  submitted = false;
  isreadonly: boolean = false;
  aciveforgot = false;
  profileid: any
  constructor(private formBuilder: FormBuilder, private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private homeService: HomeService,
    private loadingService: LoadingService,
    private errorMessageService:ErrorMessageService,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.getPhoneCode()
    const profileid2 = localStorage.getItem('auth-user')
    const profileid1 = JSON.parse(profileid2)
    const profileids = JSON.parse(profileid1)
    this.profileid = profileids.id;
    this.getProfileById(this.profileid)
    this.initializeForm();
  }

  initializeForm() {
    this.updateform = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required]],
      mobileCode: [''],
      password: [''],
      aboutMe: [''],
      userType: [null],
      deleteAccount: [0],
      accountEnabled: [1]
    });
  }

  forgotSwitch() {
    this.aciveforgot = true;
  }
  navigateToReset() {
    const email = this.updateform.get('email')?.value;
    this.router.navigate(['/resetpassword'], { queryParams: { email } });
  }


  get f() { return this.updateform.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.updateform.invalid) {
      return;
    }

    const payload = this.updateform.value;
    const userType = payload.userType;
    const userTypePayload = userType ? { [userType]: true } : {};
    console.log('userTypePayloaduserTypePayload', userTypePayload)
    delete payload.userType;
    delete payload.password;
    const finalPayload = {
      ...payload,
      ...userTypePayload
    };
    console.log("payload after cleaning", finalPayload);
    this.updateprofile(finalPayload);
  }
  getProfileById(profileid) {
    this.profileService.getProfileById(profileid).subscribe(
      response => {
        if (response['status'] === 'SUCCESS') {
          const parsedProfileData = response['payload'];
          let selectedUserType: string | null = null;
          if (parsedProfileData.builder) {
            selectedUserType = 'builder';
          } else if (parsedProfileData.agent) {
            selectedUserType = 'agent';
          } else if (parsedProfileData.individual) {
            selectedUserType = 'individual';
          }
          const mobileCodeValue = parsedProfileData.mobileCode ? parsedProfileData.mobileCode : "91";
          const aboutMeValue = parsedProfileData?.aboutMe ?? '';
          console.log("aboutMeValue", aboutMeValue);
          this.updateform.patchValue({
            fullName: parsedProfileData.fullName ?? '',
            email: parsedProfileData.email ?? '',
            mobileNumber: parsedProfileData.mobileNumber ?? '',
            aboutMe: aboutMeValue,
            mobileCode: mobileCodeValue,
            userType: selectedUserType,
            deleteAccount: parsedProfileData.deleteAccount ?? 0,
            accountEnabled: parsedProfileData.accountEnabled ?? 1
          });
        }
      },
      error => {
        console.error('Error updating profile:', error);
      }
    );
  }

  updateprofile(payload: any) {
    this.profileService.updateprofile(payload).subscribe(
      response => {
        if (response['status'] === 'SUCCESS') {
          console.log('Profile updated successfully!');
          const updatedProfileData = {
            ...JSON.parse(localStorage.getItem('auth-user')),
            ...payload
          };
          localStorage.setItem('auth-user', JSON.stringify(updatedProfileData));

          Swal.fire({
            icon: 'success',
            title: 'Profile updated successfully!',
            showConfirmButton: false,
            timer: environmentbasic.timer
          })

          window.location.href = '/';
          // window.history.back();


        }  else if (response['message']) {
                  let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
                  Swal.fire({
                    icon: 'error',
                    title: errorMessage,
                    showConfirmButton: false,
                    timer: environmentbasic.timer
                  });
                  }
      },
      error => {
        console.error('Error updating profile:', error);
      }
    );
  }
	onInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
		if (input.value.length > 10) {
			input.value = input.value.slice(0, 10); // Limit to 10 digits
		}
	}

  mobileCode: string[] = [];
  selectedPhoneCode: string = "+91"; // Always default to +91
  
  getPhoneCode() {
    this.authService.getphonecode().subscribe(
      (response: any) => {
        if (response && response.errorCode === 0 && response.payload) {
          this.mobileCode = response.payload
            .filter((item: any) => item.phoneCode)
            .map((item: any) => `+${item.phoneCode}`);
  
          console.log("Filtered Mobile Codes:", this.mobileCode);
  
          // Ensure +91 is always included and selected
          if (!this.mobileCode.includes("+91")) {
            this.mobileCode.unshift("+91"); // Add +91 if not in the list
          }
  
          // this.selectedPhoneCode = "+91"; // Always select +91
        }
      },
      (error) => {
        console.error("Error fetching phone codes:", error);
        // this.selectedPhoneCode = "+91"; // Fallback in case of error
      }
    );
  }

  userLogout() {
    this.tokenStorage.signOut();
    this.router.navigate(['/login']).then(() => {
      // window.location.reload();

      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    });
  }
  back() {
    window.history.back();
  }
}
