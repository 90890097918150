<header class="header_p" style="background-color: #004aad;">
	<div class="navigation-wrap bg-light stlatest-header stlatest-style">
		<div class="container-fluid mob-menu-width">
			<div class="row" style="box-shadow: 0 1px 6px rgba(57, 63, 72, 0.3);">
				<div class="col-12 col-md-12 col-sm-12" style="background-color: #004aad;">
					<nav class="navbar navbar-expand-xl navbar-light">
						<button class="navbar-toggler" type="button" #navbarToggler1 data-toggle="collapse"
							data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1"
							aria-expanded="false" aria-label="Toggle navigation">
						</button>
						<a class="navbar-brand mr-0" routerLink="/">
							<img src="../../assets/images/logo45.png" alt="" style="width: 100px; height: 50px;">
						</a>
						<button class="navbar-toggler" type="button" #navbarToggler data-toggle="collapse"
							data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<div>
								<i class="fa fa-filter" aria-hidden="true" style="color: #fff;"></i>
							</div>
						</button>
						<section class="navup" data-aos-delay="500">
							<div class="collapse navbar-collapse" id="navbarSupportedContent1" style="width: 100%;"
								[ngClass]="{'collapse': collapsed, 'navbar-collapse': true}">
							</div>
						</section>
						<div class="collapse navbar-collapse" id="navbarSupportedContent" style="width:100%"
							[ngClass]="{'collapse': collapsed1, 'navbar-collapse': true}">
							<ul
								class="navbar-nav right-header-content w-100 d-flex justify-content-between align-items-center">
								<!-- <li class="nav-item d-flex justify-content-center flex-grow-1">
									<div class="searchbar search11 d-flex align-items-center">
									  <div class="dropdown">
										<button
										  class="btn btn-light dropdown-toggle d-flex justify-content-between align-items-center"
										  (click)="toggleDropdown()">
										  Buy
										  <i class="fa fa-caret-down ml-2"></i>
										</button>
										<ul class="dropdown-menu" [class.show]="dropdownOpen">
										  <li (click)="selectOption('buy')">
											<a class="dropdown-item">Buy</a>
										  </li>
										</ul>
									  </div>
									  <input
										class="search_input form-control"
										type="text"
										#searchkey
										[placeholder]="searchPlaceholder" 
										(keyup.enter)="searchArt()"
									  />
									  <a href="javascript:;" (click)="searchArt()" class="search_icon">
										<i class="fa fa-search" aria-hidden="true" style="color: #fff;"></i>
									  </a>
									</div>
								  </li> -->
								  


								  <li class="nav-item d-flex justify-content-center flex-grow-1">
									<div class="searchbar search11 d-flex align-items-center">
									  <div class="dropdown">
										<button
										  class="btn btn-light dropdown-toggle d-flex justify-content-between align-items-center"
										  (click)="toggleDropdown()">
										  Buy
										  <i class="fa fa-caret-down ml-2"></i>
										</button>
										<ul class="dropdown-menu" [class.show]="dropdownOpen">
										  <li (click)="selectOption('buy')">
											<a class="dropdown-item">Buy</a>
										  </li>
										</ul>
									  </div>
									  <div class="search-wrapper d-flex">
										<input class="search_input form-control" type="text" #searchkey [(ngModel)]="searchQuery"
											   [placeholder]="searchPlaceholder" (keyup.enter)="searchArt()" />
										<a href="javascript:;" (click)="searchArt()" class="search_icon">
										  <i class="fa fa-search" aria-hidden="true" style="color: #fff;"></i>
										</a>
										<a href="javascript:;" (click)="clearSearch()" *ngIf="searchQuery" class="clear_search_icon">
										  <i class="fa fa-times" aria-hidden="true"></i>
										</a>
									  </div>
									</div>
								  </li>
								  








								<li class="nav-item" *ngIf="userinfo && userinfo.email; else notlogin">
									<div class="navbar">
										<span class="nav-container">
											<a routerLink="/add-project" *ngIf="builder" class="nav-link">New Project
												<span style="color: white;">|</span></a>
											<a routerLink="/add-property" class="nav-link">New Property </a><span
												style="color: white;">|</span>
											<a routerLink="/contactUs" class="nav-link">Any Query? </a><span
												style="color: white;">|</span>
											<span class="username">{{userfullname}}&nbsp;</span>
											<button mat-icon-button [matMenuTriggerFor]="menu" class="user-icon">
												<i class="fa fa-user-circle"></i>
											</button>
											<!-- <button mat-icon-button click="Refresh()" class="user-icon">
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</button> -->
										</span>
									</div>
									<mat-menu #menu="matMenu">
										<button mat-menu-item routerLink="/update/profile/username/userid/activetab">
											<i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>
											<span>&nbsp; My Profile</span>
										</button>
										<button mat-menu-item (click)="navigateToUserProprty()">
											<i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>
											&nbsp; My Property
										</button>
										<button mat-menu-item (click)="navigateToUserProjects()" *ngIf="builder">
											<i class="fa fa-home" style="color: #004aad; font-size: 16px;"></i>
											&nbsp; My Projects
										</button>
										<button mat-menu-item (click)="userLogout()">
											<i class="fas fa-sign-out-alt" style="color: #004aad; font-size: 16px;"></i>
											&nbsp; Logout
										</button>
									</mat-menu>
								</li>
								<ng-template #notlogin>
									<li class="nav-item">
										<!-- <span class="p-3 pl-3 pr-3">
										<a  routerLink="/News" class="post-property-link mx-2.4">News</a>
										<a routerLink="/login" class="post-property-link mx-2.4">Add Property</a>
										<a routerLink="/contactUs" class="post-property-link mx-2.4">Any Query?</a>
										<a routerLink="/login" class="post-property-link mx-2.4">Login|Signup</a>
									</span> -->
										<div class="navbar">
											<span class="nav-container">
												<!-- <a routerLink="/news" class="nav-link">News</a> -->
												<a routerLink="/login" class="nav-link">New Property</a><span
													style="color: white;">|</span>
												<a routerLink="/contactUs" class="nav-link">Any Query?</a><span
													style="color: white;">|</span>
												<a routerLink="/login" class="nav-link lastlogin">Login|Signup</a>
											</span>
										</div>
									</li>
								</ng-template>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</div>
	</div>
</header>
