import { Component, HostListener, OnInit, ViewEncapsulation,ElementRef, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { HomeService } from '../_services/home.service';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { EmbedVideoService } from 'ngx-embed-video';
import { filter } from 'rxjs/operators';
import { ProfileService } from '../_services/profile.service';
import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environments/environment';
import { HeaderSearchService } from '../_services/header-search.service';
import { LoadingService } from '../_services/loading.service';
import { AddPropertyComponent } from '../add-property/add-property.component';
import Swiper, { Navigation } from 'swiper';
import { MatTabChangeEvent } from '@angular/material/tabs';
Swiper.use([Navigation]);
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  isRequiredMessageVisible:boolean=false
  @ViewChild('searchkey') searchInput: ElementRef;
  @ViewChild('swiperRef', { static: false }) swiper?: Swiper;
  @ViewChild('swiperRef2', { static: false }) swiper2?: Swiper;
  @ViewChild('swiperRef3', { static: false }) swiper3?: Swiper;
  collapsed1:boolean=false
	userfullname: string = '';
  cards = [
    {
      image:
        'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
      title: 'Anantam',
      subtitle: 'By Ganga Realty',
      details: '3,4 BHK Apartments',
      location: 'Sector 85, New Gurgaon, Gurgaon',
      price: '₹ 4.03 Cr - 5.95 Cr',
    },
    {
      image:
        'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
      title: 'Anantam',
      subtitle: 'By Ganga Realty',
      details: '3,4 BHK Apartments',
      location: 'Sector 85, New Gurgaon, Gurgaon',
      price: '₹ 4.03 Cr - 5.95 Cr',
    },
    {
      image:
        'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
      title: 'Anantam',
      subtitle: 'By Ganga Realty',
      details: '3,4 BHK Apartments',
      location: 'Sector 85, New Gurgaon, Gurgaon',
      price: '₹ 4.03 Cr - 5.95 Cr',
    },
    {
      image:
        'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
      title: 'Anantam',
      subtitle: 'By Ganga Realty',
      details: '3,4 BHK Apartments',
      location: 'Sector 85, New Gurgaon, Gurgaon',
      price: '₹ 4.03 Cr - 5.95 Cr',
    },
    {
      image:
        'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
      title: 'Anantam',
      subtitle: 'By Ganga Realty',
      details: '3,4 BHK Apartments',
      location: 'Sector 85, New Gurgaon, Gurgaon',
      price: '₹ 4.03 Cr - 5.95 Cr',
    },
    {
      image:
        'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
      title: 'Anantam',
      subtitle: 'By Ganga Realty',
      details: '3,4 BHK Apartments',
      location: 'Sector 85, New Gurgaon, Gurgaon',
      price: '₹ 4.03 Cr - 5.95 Cr',
    },
  ];
  populateForm() {
		const profileData = localStorage.getItem('auth-user');
		  const parsedProfileData1 = JSON.parse(profileData);
		  const parsedProfileData = JSON.parse(parsedProfileData1);
		  this.userfullname = parsedProfileData.fullName;
		  this.useremail = parsedProfileData.email;
		  let useremail = this.useremail
		  console.log("useremail",useremail);
	      console.log("userfullname",this.userfullname);
		  let userfullname = this.userfullname
		  }
  public loading = true;
  artslist: any = [];
  searchQuery: string = '';
  filteredAreas: any[] = [];
  showSuggestions: boolean = false;

  listings: any[] = [];
  recentList: any[] = [

  ];
  trendingList: any[] = [];
  topList: any[] = [];
  tophome:any[]=[];
  selectTab:any;
  page: number = 1;
  limit: number = 10;
  sortactivetab = 'trending';
  builder: boolean | string = '';
  cityDataList: any[] = [];
  areaList: any[] = [];

  selectedArea: any;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsmedium: IDropdownSettings = {};
  selectedCategories = [] as any;
  selectedCategoryIds = [] as any;
  selectedMediums = [] as any;
  selectedMediumIds = [] as any;
  activeIndex: number = -1; 
  artParama: any = {
    artWorkCategories: [],
    limit: 28,
    mediumIds: [],
    offset: 0,
    searchString: '',
    sortCriteria: 'trending',
  };
  btnStyle = 'tablinks ';
  isLoggedIn = false;
  defaultThumbnail = '../assets/images/video.png';
  defaultThumbnailAudio = '../assets/images/audio.jpeg';
  maxCategoryMessage: boolean = false;
  searchResultMessage: boolean = false;
  maxMediumMessage: boolean = false;
  usertoken: any = [];
  searchValue: any = '';
  socialOrigin: any;
  useremail: any;
  payload: any;
  searchData: string = '';
  dropdownOpen = false;
  currentIndex: number = 0;
  showPrevButton = false;
  selectedCityId: string | null = null;
  isPrevActive = false;
  isNextActive = false;
  showNextButton: boolean = true;
  showPrevButton2: boolean = false;
  showNextButton2: boolean = true;
  showPrevButton3: boolean = false;
  showNextButton3: boolean = true;
  url = 'https://www.eduforbetterment.com/generate-qr-code-in-angular/';
  image = 'https://www.eduforbetterment.com/wp-content/uploads/2020/08/qrcode.jpg';
  title = 'Generate QR Code in Angular';
  description = 'Learn how to generate QR codes in Angular with this comprehensive guide!';
  price = '$10.00';

  get fullDescription(): string {
    return `${this.description} - Price: ${this.price}`;
  }
  tabs = [
    { name: 'Residential', selected: false },
    { name: 'Commercial', selected: false },
    { name: 'Flat/Apartment', selected: false },
    { name: 'Independent House/Villa', selected: false },
    { name: 'Service Apartment', selected: false },
    { name: 'Farm House', selected: false },
    { name: 'Agricultural Land', selected: false },
  ];
  
    slideConfig = {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "nextArrow": "",
        "prevArrow": "",
        "dots": false,
        "infinite": true
    };
    userinfo =localStorage.getItem("auth-token")
    toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	  }
      selectOption(option: string) {
		console.log(`Selected option: ${option}`);
		this.dropdownOpen = false;
	  }
  //     searchArt() {
	// 	const searchData = this.searchInput.nativeElement.value;
  //   const areaOnly = searchData.split(',')[0].trim();

  //   console.log(areaOnly,searchData); // This will log "Madanpuri"

  //   this.searchService.setSearchData(areaOnly); //
  //   localStorage.setItem('search_query', areaOnly);
    
  //   if (this.router.url !== '/home-property') {
  //     this.router.navigate(['/home-property']);
  //   }
  // }
  searchArt() {
    const searchData = this.searchInput.nativeElement.value;
    const areaOnly = searchData.split(',')[0].trim();
    console.log(areaOnly);
    this.searchService.setSearchData(areaOnly);
    localStorage.setItem('search_query', areaOnly);
    if (searchData && areaOnly) {
      if (this.router.url !== '/home-property') {
        this.router.navigate(['/home-property']);
      }
    }else{
      this.isRequiredMessageVisible = true;
      setTimeout(() => {
        this.isRequiredMessageVisible = false;
      }, 1000);
    }
  }
  
  navigateTOListing() {
    this.router.navigate(['/top-project-list']);
  } 
  navigateTOhome() {
    localStorage.removeItem("search_query");
		localStorage.removeItem("search_type");
    this.router.navigate(['/home-property']);
  } 
  navigateTOListing1() {
    this.router.navigate(['/recently-added-Project-list']);
  }  
  navigateTOListing2() {
    this.router.navigate(['/Trending-project-list']);
  } 
  navigateTOListing11() {
    this.router.navigate(['/home-property']);
  } 
  placeholderText: string = '';
  fullPlaceholder: string = 'Search for locality, landmark, project, or builder';
  typingSpeed: number = 150; // Typing speed in ms
  deletingSpeed: number = 75; // Deleting speed in ms
  isDeleting: boolean = false;
  typingInterval: any;
  constructor(
    private homeService: HomeService,
    private tokenStorage: TokenStorageService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private embedService: EmbedVideoService,
    private router: Router,
    private searchService: HeaderSearchService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {}
  postproperty(){
    this.router.navigate(['add-property'])
  }
  postpropertynotlogin(){
    this.router.navigate(['login'])
  }
  
  ngOnInit(): void {
    this.startTypingEffect();
    localStorage.setItem('search_type',"Residential");
    this.getCitybyCountryId();
    let Userdata = localStorage.getItem('UserProfile');
    if (Userdata) {
      let parsedUserdata = JSON.parse(Userdata);
      this.builder = parsedUserdata.payload.builder;
      this.userfullname=parsedUserdata.payload.fullName;
    }
    this.getProjectList();
  }
getProjectList() {
  this.homeService
    .getProjectTop10List('recent')
    .subscribe((response: any) => {
      this.recentList = response.payload;
      this.recentList.push({ type: 'viewAll', name: 'View All' });
    });

 
  this.homeService
    .getProjectTop10List('trending')
    .subscribe((response: any) => {
      this.trendingList = response.payload;
      this.trendingList.push({ type: 'viewAll', name: 'View All' });

    });

  this.homeService
    .getProjectTop10List('top')
    .subscribe((response: any) => {
      this.topList = response.payload;
      this.topList.push({ type: 'viewAll', name: 'View All' });
    });

    this.homeService
    .getrecentlyadd()
    .subscribe((response: any) => {
      this.tophome = response.payload;
      this.tophome.push({ type: 'viewAll', name: 'View All' });
    });
}

 activeButtons: boolean[] = [false, false, false];

onPrevClick(index: number) {
  this.resetActiveButtons();
  this.activeButtons[index] = true;
}

onNextClick(index: number) {
  this.resetActiveButtons();
  this.activeButtons[index] = true;
}

navigateTofullDetail(id: number): void {
  this.router.navigate(['/topProject'], { queryParams: { id: id } });

}
newsnavigate(){
  this.router.navigate(['/news']).then(() => {
    window.location.reload();
  });
}
resetActiveButtons() {
  this.activeButtons = [false, false, false];
}
  userLogout() {
    this.tokenStorage.signOut();
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }
  navigateToUserProprty() {
    this.router.navigate(['/UserProperty']);
  }

  // In your component
  onSwiper(swiperInstance: Swiper) {
    this.showPrevButton = swiperInstance.activeIndex > 0;
  }

  onPropertyTypeChange(selectedType: any) {
    selectedType.selected = !selectedType.selected;
    // You can handle additional logic here, like updating filters or payload
    console.log('Selected property types:', this.tabs.filter(type => type.selected));
  }
  onTabChange(event: MatTabChangeEvent) {
    const selectedIndex = event.index;
    const selectedTabName = this.tabs[selectedIndex].name;
    console.log('Selected Tab:', selectedTabName);
    this.selectTab=selectedTabName;
    localStorage.setItem('search_type', selectedTabName);

  }
  
  getCitybyCountryId() {
    this.homeService.getCitybyCountryId().subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload.cityDataList;
          if (this.cityDataList.length > 0) {
            const gurgaonCity = this.cityDataList.find(
              (city) => city.cityId === 153475
            );

            if (gurgaonCity) {
              this.selectedCityId = gurgaonCity.cityId;
            } else {
              this.selectedCityId = this.cityDataList[0].cityId;
            }
            this.onCityChange();
          }
        } else {
          console.error('Failed to fetch city data:', response);
        }
      },
      (error) => {
        console.error('Error fetching city data:', error);
      }
    );
  }
  onCityChange() {
    if (this.selectedCityId) {
      this.AreayByCityId(this.selectedCityId);
    }
  }
  onSearchKeyChange() {
    // if (this.searchQuery.trim()) {
    //   this.filteredAreas = this.areaList.filter(area =>
    //     area.area.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
    //     area.cityName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
    //     area.stateName.toLowerCase().includes(this.searchQuery.toLowerCase())
    //   );
    // } else {
    //   this.filteredAreas = [];
    // }
    this.homeService
      .getPropertyByCityIdAndArea(this.selectedCityId, this.searchQuery)
      .subscribe((response: any) => {
        if (response.status === 'SUCCESS') {
          console.log(response);
          this.areaList = response.payload;

          this.cdr.detectChanges();
        }
      });
  }

  // Function to select an area and fill the input
  selectArea(area: any) {
    console.log(area.area);

    this.searchQuery = `${area.area}, ${area.cityName}, ${area.stateName}`;
    // this.searchService.setSearchData(area.area);
    localStorage.setItem('search_query', area.area);
    // if (this.router.url !== '/home-property') {
    //     this.router.navigate(['/home-property']);
    //   }
    this.showSuggestions = false;
  }

  // Function to hide suggestions after a delay (to allow click)
  hideSuggestions() {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200);
  }
  AreayByCityId(cityId: string) {
    this.homeService.getPropertyByCityId(cityId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.areaList = response.payload;
          this.loadingService.hide();
          console.log('response', response);
        } else {
          console.error('Response status is not SUCCESS');
          this.areaList = [];
        }
      },
      (error) => {
        this.loadingService.hide();
        this.areaList = [];
        console.error('API call error', error);
      }
    );
  }

  ngAfterViewInit() {
    this.cdr.detectChanges(); // Trigger change detection manually if needed
  }
  onSlideChange(event: any) {
    console.log(event);
    console.log(event[0].activeIndex);
    
    
    const swiperInstance = event.swiper; // Accessing the Swiper instance from the event
    this.showPrevButton = event[0].activeIndex > 0;
    this.showNextButton = event[0].activeIndex < (event[0].slides.length - event[0].params.slidesPerView)-1;
    console.log(this.showNextButton);
    
    
  }
  onSlideChange2(event: any) {
    // const swiperInstance2 = event.swiper;

    // this.showPrevButton2 = swiperInstance2.activeIndex > 0;
    // this.showNextButton2 = swiperInstance2.activeIndex < swiperInstance2.slides.length - swiperInstance2.params.slidesPerView;
    console.log(event[0].activeIndex);
    
    
    const swiperInstance = event.swiper; // Accessing the Swiper instance from the event
    this.showPrevButton2 = event[0].activeIndex > 0;
    this.showNextButton2 = event[0].activeIndex < (event[0].slides.length - event[0].params.slidesPerView)-1;
    console.log(this.showNextButton);
  }
  onSlideChange3(event: any) {
    // const swiperInstance2 = event.swiper;

    // this.showPrevButton2 = swiperInstance2.activeIndex > 0;
    // this.showNextButton2 = swiperInstance2.activeIndex < swiperInstance2.slides.length - swiperInstance2.params.slidesPerView;
    console.log(event[0].activeIndex);
    
    
    const swiperInstance = event.swiper; // Accessing the Swiper instance from the event
    this.showPrevButton3 = event[0].activeIndex > 0;
    this.showNextButton3 = event[0].activeIndex < (event[0].slides.length - event[0].params.slidesPerView)-1;
    console.log(this.showNextButton);
  }

  // navigateToProjectDeatils(){
  //   this.router.navigate(['/topProject']);
  // }
  navigateToProjectDetails(card: any) {
    // Navigate and pass the card data as state
    this.router.navigate(['/topProject'], { state: { cardData: card } });
  }

  navigateToUserProjects() {
    this.router.navigate(['/myprojects']);
  }
  // ngOnInit(): void {
  //   this.startTypingEffect();
  // }

  ngOnDestroy(): void {
    if (this.typingInterval) {
      clearInterval(this.typingInterval); // Clear interval when component is destroyed
    }
  }
  startTypingEffect(): void {
    this.typingInterval = setInterval(() => {
      if (this.isDeleting) {
        this.placeholderText = this.placeholderText.slice(0, -1);
        if (this.placeholderText.length === 0) {
          this.isDeleting = false;
        }
      } else {
        this.placeholderText = this.fullPlaceholder.slice(0, this.currentIndex);
        if (this.currentIndex === this.fullPlaceholder.length) {
          this.isDeleting = true;
        } else {
          this.currentIndex++;
        }
      }
    }, this.isDeleting ? this.deletingSpeed : this.typingSpeed);
  }
}
