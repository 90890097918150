<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>

    <div class="content contents">
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="custom-form">
        <h2 class="form-title">Contact Us</h2>

        <div class="form-group">
          <label for="email" class="form-label">Email <span>*</span></label>
          <input 
            type="email" 
            class="form-control" 
            placeholder="Email" 
            formControlName="email" 
            [readonly]="isProfileEmail" 
            (blur)="!isProfileEmail && onEmailInputBlur($event)" 
          />
          <div *ngIf="f.email?.touched && f.email?.errors" class="error-messagess">
            <small *ngIf="f.email.errors.required">Email is required.</small>
            <small *ngIf="f.email.errors.email">Enter a valid email address.</small>
          </div>
        </div>

        <!-- <div class="form-group">
          <label for="phone" class="form-label">Phone</label>
          <input
            type="text"
            class="form-control no-spinner"
            placeholder="Mobile Number"
            formControlName="mobileNumber"
            (input)="onInput($event)"
          />
        </div> -->
        <label for="phone" class="form-label">Phone</label>
        <div class="mobile-input-container">
          
          <!-- Country Code Dropdown -->
          <select formControlName="mobileCode" class="country-code" [(ngModel)]="selectedPhoneCode">
            <option *ngFor="let code of mobileCode" [value]="code">
              {{ code }}
            </option>
          </select>
        
          <!-- Mobile Number Input -->
          <input 
            type="text" 
            placeholder="Enter Mobile Number" 
            formControlName="mobileNumber" 
            (input)="onInput($event)" 
            class="mobile-input"
            maxlength="10"
          />
        </div>

        <div class="form-group">
          <label for="description" class="form-label">Query <span>*</span></label>
          <textarea 
            class="form-control size" 
            placeholder="Enter your query" 
            formControlName="description" 
            rows="3"
          ></textarea>
          <div *ngIf="f.description?.touched && f.description?.errors" class="error-messagess">
            <small *ngIf="f.description.errors.required">Query  is required.</small>
          </div>
        </div>

        <button 
          type="submit" 
          class="submit-btn" 
          [disabled]="!isEmailVerified"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
  <app-footer></app-footer>
</div>
