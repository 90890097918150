

<div class="page-container">
  <app-header></app-header>
  <app-menu></app-menu>
  <!-- <div style="margin-top: 30px;">
    <app-project-common-filter (keyup.enter)="searchArt()"></app-project-common-filter>
  </div> -->

    <div class="col-md-10  content">
      <div class="page-heading d-flex justify-content-between align-items-center mt-2">
        <h1 class="titles">My Property</h1>
    </div>
      <ng-container *ngIf="listings?.length > 0; else noListings">
        <ng-container *ngFor="let listing of listings;">
          <app-property-card [listing]="listing" [editButton]="true" ></app-property-card>
        </ng-container>
        <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
          ⬆
        </div>
      </ng-container>
      <ng-template #noListings>
        <app-not-found></app-not-found>
      </ng-template>
    
    </div><br>

    <app-footer></app-footer>
</div>