<div class="container-full d-flex justify-content-center align-items-center h-100">
    <div class="form-container">
        <div class="form-section sign-in-section">
            <form [formGroup]="resetform" (ngSubmit)="onResetSubmit()" class="account-form">
                <span class="form-header">Enter Otp</span><br>
                <div id="otp" class="form-group text-center">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
                </div>
                <div class="position-relative">
                    <hr class="bg-200 mt-4 mb-4" />
                    <div class="divider-text-center">Set your password</div>
                </div>
                <div class="form-group">
                    <input type="password" placeholder="Enter Password" formControlName="newPassword" class="form-control" />
                    <div class="error-messages" *ngIf="submitted && l.newPassword.errors">
                        <div *ngIf="l.newPassword.errors.required">Password is required</div>
                        <div *ngIf="l.newPassword.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" placeholder="Enter Confirm Password" formControlName="confirmPassword" class="form-control" />
                    <div class="error-messages" *ngIf="submitted && l.confirmPassword.errors">
                        <div *ngIf="l.confirmPassword.errors.required">Password is required</div>
                        <div *ngIf="l.confirmPassword.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <button class="update-password mb-2 btn btn-primary w-50 mx-auto">Update Password</button>
                <a routerLink="/login" class="login-link float-right text-center mt-3 d-block" style="color: #004aad;">Sign In</a>
            </form>
        </div>
    </div>
</div>
