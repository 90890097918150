<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>
    <!-- <div style="margin-top: 30px;">
      <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
    </div> -->
    <div class="page-heading d-flex justify-content-between align-items-center mt-2">
      <h1 class="titles">My Projects</h1>
  </div>
    <div style="cursor: pointer;" class="card-container master12 col-md-12"  *ngIf="topList.length > 0" >
      <ng-container *ngFor="let card of topList">
        <app-top-property-card
        [cardData]="card"
        [showEditDetailsButton]="true"
        [showViewDetailsButton]="true"
        [showInventoryButton]="true"
        [showdetails]="false"
        [showdetailsbutton]="false"
        [showdsize]="false"

        [showEditInventoryButton]="false">
      </app-top-property-card>
      </ng-container>
    </div>
    <div *ngIf="topList.length === 0">
      <!-- <p>No projects available.</p> -->
       <app-not-found></app-not-found>
    </div><br>
  </div>
  <app-footer></app-footer>
 </div>


