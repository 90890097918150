<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>

    <div class="content content12 p-3" style="padding: 30px;">
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="custom-form">
        <h2 class="form-title">Contact Us</h2>

        <div class="form-group">
          <label for="email" class="form-label">Email</label>
          <!-- Conditionally set the 'readonly' attribute if the email is from profileData -->
          <input type="email" class="form-control" placeholder="Email" formControlName="email" 
                 [readonly]="isProfileEmail" (blur)="onEmailInputBlur($event)" />
        </div>

        <div class="form-group">
          <label for="phone" class="form-label">Phone</label>
          <input
            type="text"
            class="form-control no-spinner"
            placeholder="Mobile Number"
            formControlName="mobileNumber"
            (input)="onInput($event)"
          />
        </div>

        <div class="form-group">
          <label for="description" class="form-label">Query</label>
          <textarea class="form-control" placeholder="Enter your query" formControlName="description" rows="3"></textarea>
        </div>

        <!-- Disable the submit button until the email is verified or profile data exists -->
        <button type="submit" class="submit-btn" [disabled]="!isEmailVerified || myForm.invalid">Submit</button>
      </form>
    </div>
  </div>
  <app-footer></app-footer>
</div>
