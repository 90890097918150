import { Component, OnInit } from '@angular/core';
import { ContactSellerComponent } from '../contact-seller/contact-seller.component';
import { MatDialog } from '@angular/material/dialog';
import { TopProjectService } from '../_services/top-project.service';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../_services/home.service'; 
import { PropertyOwnerComponent } from '../property-owner/property-owner.component';
import { Router } from '@angular/router';
import { auto } from '@popperjs/core';
import { LoginPopupComponent } from '../login-popup/login-popup.component';
import { LoadingService } from '../_services/loading.service';
@Component({
  selector: 'app-top-project',
  templateUrl: './top-project.component.html',
  styleUrls: ['./top-project.component.scss']
})
export class TopProjectComponent implements OnInit {
  listingData: any;
  projectId: string | null = null;
  listing: any;
  cardData: any;
  toplist: any;
  title: string = '';
  showAll = false; 
  fromPrice: number | null = null;
  fromPriceUnit: any;
  toPriceUnit: any;
  toPrice: number | null = null;
  location: any = '';
  cityName: any = '';
  stateName: any = '';
  isExpanded: boolean = true;
  Images: string[] = []; 
  inventoryType: any = '';
  status:any='';
  loanAvailable:any='';
  totalInventory:any = '';
  sizeOfProperty: any = '';
  titleDeed: any = '';
  area: any = '';
  soilQuality: any = '';
  physicalVisit: any = '';
  propertyLocation: any = '';
  size: any = '';
  sizeUnit:any='';
  pricing: any = '';
  projectName: any = '';
  reraApproved: any = '';
  reraCompliance: any = '';
  builder: any = '';
  connectivity:any='';
  constructionApprovals:any='';
  flatPurchaseChecklist:any='';
  investmentPerspective:any='';
  security:any='';
  accessTo:any='';
  zoningRisks:any='';
  verification:any='';
  modernFeatures:any='';
  noObjectionCertificates:any=''
  marketResearch:any='';
  outdoor: any = '';
  smartHomes: any = '';
  infrastructure: any = '';
  highEndKitchens: any = '';
  floorPlan: any = '';
  description: string = "<p>This is a <strong>sample</strong> description.</p>";
  apartmentPossession: any = '';
  postdate:any
  constructor(
    private router:Router,
    private dialog: MatDialog,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private topProjectService: TopProjectService,
    private loadingService:LoadingService
  ) { }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.queryParamMap.get('id');
    if (this.projectId) {
      this.loadingService.show()
      this.homeService.getProjectById(this.projectId).subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS' && response.payload) {
            this.loadingService.hide()
            this.title = response.payload.title || '';
            this.postdate = response.payload.createdAt || '';
            this.fromPrice = response.payload.fromPrice || null;
            this.fromPriceUnit = response.payload.fromPriceUnit ;
            this.toPriceUnit = response.payload.toPriceUnit ;
            this.toPrice = response.payload.toPrice || null;
            this.location = response.payload.location || '';
            this.cityName = response.payload.cityName || '';
            this.stateName = response.payload.stateName || '';
            this.titleDeed = response.payload?.projectfeatures?.titleDeed || '';
            this.inventoryType = response.payload?.inventoryType || '';
            this.status = response.payload?.status || '';
            this.loanAvailable = response.payload?.loanAvailable || '';

            this.totalInventory = response.payload?.totalInventory || '';
            this.sizeOfProperty = response.payload?.projectBuilder?.sizeOfProperty || '';
            this.area = response.payload?.projectBuilder?.area || '';
            this.soilQuality = response.payload?.projectfeatures?.soilQuality || '';
            this.physicalVisit = response.payload?.projectfeatures?.physicalVisit || '';
            this.propertyLocation = response.payload?.projectfeatures?.propertyLocation || '';
            this.pricing = response.payload?.projectfeatures?.pricing || '';
            this.size = response.payload?.projectfeatures?.size || '';
            this.size = response.payload?.size || '';
            this.sizeUnit=response.payload?.sizeUnit
            this.projectName = response.payload?.projectName || '';
            this.reraCompliance = response.payload?.projectfeatures?.reraCompliance || '';
            this.reraApproved = response.payload?.reraApproved || '';
            this.outdoor = response.payload?.projectfeatures?.outdoor || '';
            this.smartHomes = response.payload?.projectfeatures?.smartHomes || '';
            this.infrastructure = response.payload?.projectfeatures?.infrastructure || '';
            this.highEndKitchens = response.payload?.projectfeatures?.highEndKitchens || '';
            this.floorPlan = response.payload?.projectfeatures?.floorPlan || '';
            this.builder = response.payload?.projectfeatures?.builder || '';
            this.connectivity = response.payload?.projectfeatures?.connectivity || '';
            this.flatPurchaseChecklist = response.payload?.projectfeatures?.flatPurchaseChecklist || '';
            this.constructionApprovals = response.payload?.projectfeatures?.constructionApprovals || '';
            this.investmentPerspective = response.payload?.projectfeatures?.investmentPerspective || '';
            this.security = response.payload?.projectfeatures?.security || '';
            this.accessTo = response.payload?.projectfeatures?.accessTo || '';
            this.zoningRisks = response.payload?.projectfeatures?.zoningRisks || '';
            this.verification = response.payload?.projectfeatures?.verification || '';
            this.modernFeatures = response.payload?.projectfeatures?.modernFeatures || '';
            this.noObjectionCertificates = response.payload?.projectfeatures?.noObjectionCertificates || '';
            this.marketResearch = response.payload?.projectfeatures?.marketResearch || '';
            this.description = response.payload?.description || '';
            this.listingData =response.payload?.projectBuilder || '';
            this.apartmentPossession = response.payload?.projectfeatures?.apartmentPossession || '';
            if (response.payload.projectPlanImage && response.payload.projectPlanImage.length > 0) {
              this.Images = response.payload.projectPlanImage.map((image: any) => image.url || '');
            }

            console.log('Title:', this.titleDeed);
          }
        }
      );
    }

    console.log("Project ID:", this.projectId);
  }
  
  // inventory(event: Event) {
  //   const propertyId = this.route.snapshot.queryParamMap.get('id');
  //     event.stopPropagation();
  //     this.router.navigate(['/project-invetory'], { queryParams: { projectId: propertyId } });
  // }
  inventory(event: Event) {
    const propertyId = this.route.snapshot.queryParamMap.get('id');
    
    event.stopPropagation();

    this.router.navigate(['/project-invetory'], { 
      queryParams: { 
        projectId: propertyId, 
        showButton: false 
      } 
    });
  }
  parseDate(dateString: string): Date {
    const parts = dateString.split(' ');
    const dateParts = parts[0].split('-');
    const timeParts = parts[1].split(':');
    return new Date(
      parseInt(dateParts[2], 10), 
      parseInt(dateParts[1], 10) - 1,  
      parseInt(dateParts[0], 10),  
      parseInt(timeParts[0], 10),  
      parseInt(timeParts[1], 10),  
      parseInt(timeParts[2], 10)  
    );

  }
  openContactDetailsDialog(listingData): void {
    const lastdate = localStorage.getItem('auth-user');
    if (lastdate) {
      try {
        const lastdatedata = JSON.parse(lastdate);
        const lastdatedata1 = JSON.parse(lastdatedata);
        const lastLoginString = lastdatedata1.lastLogin;
        console.log("lastLogin", lastLoginString);
        const lastLogin = this.parseDate(lastLoginString);
        const currentTime = new Date();
        if (lastLogin) {
          const adjustedTime = new Date(currentTime.getTime() - (5 * 60 * 60 * 1000) - (30 * 60 * 1000));
          const timeDifferenceInSeconds = Math.floor((adjustedTime.getTime() - lastLogin.getTime()) / 1000);
          console.log("count 0 to 1800", timeDifferenceInSeconds);
          if (timeDifferenceInSeconds <= 1800) {
            this.dialog.open(PropertyOwnerComponent, {

              data: listingData,
              width: '830px',
              height:'500px'
            });
          }
         else {
          console.error('Invalid date parsed:', lastLogin);
          // this.router.navigate(['/login']);
          this.loginPopup()
        }
      }
      } catch (error) {
        console.error('Error parsing lastdate:', error);
        // this.router.navigate(['/login']);
        this.loginPopup()

      }
    } else {
      console.log('User is not logged in.');
      // this.router.navigate(['/login']);
      this.loginPopup()

    }
  }
  loginPopup(){
    this.dialog.open(LoginPopupComponent, {
      width: '380px',
      height:auto
    });
  }
  opencontactceller(): void {   
    this.dialog.open(ContactSellerComponent, {
      width: '540px',
      height: '670px',
    });
  }

  amenities = [
    { icon: 'weekend', label: 'Waiting Lounge' },
    { icon: 'spa', label: 'Steam Room' },
    { icon: 'nature', label: 'Reflexology Park' },
    { icon: 'outdoor_grill', label: 'Barbecue Area' },
    { icon: 'hot_tub', label: 'Spa' },
    { icon: 'local_bar', label: 'Bar/Chill-out Lounge' },
    { icon: 'celebration', label: 'Party Hall' },
    { icon: 'sports_tennis', label: 'Table Tennis' },
    { icon: 'sports_handball', label: 'Badminton Court' },
    { icon: 'fitness_center', label: 'Gymnasium' },
    { icon: 'pool', label: 'Kid\'s Pool' },
  ];

  toggleShowMore() {
    this.showAll = !this.showAll;
  }

  get displayedAmenities() {
    return this.showAll ? this.amenities : this.amenities.slice(0, 7);
  }

  // formatPrice(value: number | null): string {
  //   if (value === null || value === undefined) return ''; 
  //   if (value >= 10000000) {
  //     return (value / 10000000).toFixed(2) + ' Crore';
  //   } else if (value >= 100000) {
  //     return (value / 100000).toFixed(2) + ' Lakh';
  //   } else if (value >= 1000) {
  //     return (value / 1000).toFixed(2) + ' Thousand';
  //   }
  //   return value.toString();
  // }

  stripHtmlTags(value: string | null): string {
    if (!value) return ''; 
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = value;

    return tempDiv.textContent || tempDiv.innerText || "";
  }
  back(){
    window.history.back();
  }
}
