import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CellClickedEvent, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/_services/home.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';


@Component({
  selector: 'app-recently-added-project',
  templateUrl: './recently-added-project.component.html',
  styleUrls: ['./recently-added-project.component.scss']
})
export class RecentlyAddedProjectComponent implements OnInit {
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any = {};
  rowData: any = [];
  IsLoading: boolean = false;
  paginationPageSize!: number;
  subs$!: Subscription;
  gridOptions: GridOptions = {
    pagination: true,
    rowHeight: 40,
  };
  userId:any;
  module: any;
  subModule: any;
  accessRight: any;
  createRight: boolean = false;
  deleteRight: boolean = false;
  updateRight: boolean = false;
  viewRight: boolean = false;
  userType: any;
  public frameworkComponents:any;
  user: any;
  selectedOption: any = null;
  userlist: any[] = [];
  recentList: any[] = [

  ];
  currentPage: number = 0; // Current page number (zero-based)
  pageSize: number = 12;   // Number of records per page
  totalPages: number = 0;  // Total number of pages
  totalElements: number = 0;
  searchValue: string = '';
  page=0;
  size=10;
  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getProjectList()
  }


  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList(); 
  }

  getProjectList() {
    this.homeService
      .ProjectList('recent',this.searchValue || '',this.currentPage,this.pageSize)
      .subscribe((response: any) => {
        this.IsLoading = false;
        if (response.status === "SUCCESS") {
          this.recentList = response.payload.items; 
          this.totalPages = response.payload.totalPages;  
          this.totalElements = response.payload.totalElements; 
          console.log(this.rowData);
        } else {
          console.error('Failed to fetch projects');
        }
      }, error => {
        this.IsLoading = false;
        console.error('Error fetching projects:', error);
      });

  }
  changePage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.getProjectList()
    }
  }
  getPages(): number[] {
    const pages: number[] = [];
    const start = Math.max(this.currentPage - 4, 0); 
    const end = Math.min(start + 9, this.totalPages - 1); 
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }
}
