import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-top-property-card',
  templateUrl: './top-property-card.component.html',
  styleUrls: ['./top-property-card.component.scss']
})
export class TopPropertyCardComponent implements OnInit {
  @Input() cardData: any;
  @Input() showEditDetailsButton: boolean = true;
  @Input() showViewDetailsButton: boolean = true;
  @Input() showInventoryButton: boolean = true;
  @Input() showEditInventoryButton: boolean = false;
  @Input() showbugget: boolean = true;
  @Input() showdetails: boolean = true;
  @Input() showdetailsbutton: boolean = true;
  @Input() showdsize: boolean = true;


  showButton: boolean = false;



  currentRoute: string = '';
  currentQueryParams: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    console.log('TopCard', this.cardData);

    // Initialize currentRoute with the current URL
    this.currentRoute = this.router.url;

    // Update currentRoute and query parameters on navigation events
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentRoute = this.router.url;
      this.route.queryParams.subscribe(params => {
        this.currentQueryParams = params;
      });
    });
  }

  navigateToProjectDetails() {
    this.router.navigate(['/topProject']);
  }

  formatPrice(value: number | null | undefined): string {
    if (value == null) {
      return ''; 
    }

    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(2)} Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(2)} L`;
    } else {
      return value.toLocaleString('en-IN');
    }
  }

  navigateTOListing() {
    this.router.navigate(['/top-project-list']);
  }

  editDetails(event: Event) {
    event.stopPropagation();
    if (this.cardData && this.cardData.id) {
      this.router.navigate(['/add-project'], { queryParams: { id: this.cardData.id } });
    }
  }

  // viewDetails(event: Event) {
  //   const id = this.route.snapshot.queryParamMap.get('id');
  //   event.stopPropagation();
  //   this.router.navigate(['/topProject'],{ queryParams: { id: this.cardData.id } });
  // }
  
  viewDetails(event: Event) {
    event.stopPropagation();
    this.route.queryParams.subscribe(params => {
      const projectId = params['projectId'];
      if (projectId) {
        this.router.navigate(['/inventory-details'], { queryParams: { id: this.cardData.id } });
      } else if (this.cardData && this.cardData.totalInventory) {
        this.router.navigate(['/topProject'], { queryParams: { id: this.cardData.id } });
      } else {
        this.router.navigate(['/topProject'], { queryParams: { id: this.cardData.id } });
      }
    });
  }
  


  inventory(event: Event) {
    event.stopPropagation();
    if (this.cardData && this.cardData.id) {
      this.router.navigate(['/project-invetory'], { queryParams: { projectId: this.cardData.id } });
    }
  }
  editInventory(event: Event) {
    event.stopPropagation();
    if (this.cardData && this.cardData.id) {
      this.router.navigate(['/add-invetory'], { queryParams: { inventoryId: this.cardData.id } });
    }
  }
}
