<app-header></app-header><br>
     <div class="col-12 first-container first-container12">
    <div class="listing-image">
      <ng-container *ngIf="Images.length > 0; else noImage">
        <app-image-slide [slides]="Images"></app-image-slide>
      </ng-container>
      <ng-template #noImage>
        <!-- <app-image-slide [slides]="['../../assets/images/defaultproperty.png']"></app-image-slide> -->
        <app-image-slide [slides]="['../../assets/images/Images_not_found (1).png']"></app-image-slide>
  
      </ng-template>
    </div>
    </div>

    <div class="col-12 first-container">
      <mat-card class="project-overview " style="text-align: center;">
        <mat-card-title class="project-title mb-3">
          {{ basicform.title | capitalizeFirstLetter}}<br>
        </mat-card-title><hr>

        <mat-card-content>
          <div class="project-details">
            <div class="detail">
              <mat-icon>work_outline</mat-icon>
              <span>Negotiable :</span>
              <strong>{{ basicform.isNegotiable ? 'Yes' : 'No' }}</strong>

            </div>
            <div class="detail">
              <mat-icon>straighten</mat-icon>
              <span>Inventory Type :</span>
              <strong>{{basicform.inventoryType | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>location_city</mat-icon>
              <span>Total Size:</span>
              <strong>     {{basicform.totalSize}}&nbsp;{{basicform.sizeUnit}}</strong>
            </div>
            <div class="detail">
              <mat-icon>
                {{ basicform?.status ? 'check_circle' : 'cancel'}}
              </mat-icon>
              <span>Status</span>
              <strong>{{ basicform?.status ? 'Active' : 'Inactive' }}</strong>
            </div>
            
            <div class="detail">
              <mat-icon>inventory_2</mat-icon>
              <span>Price</span>
              <strong>₹{{basicform.aaskingPrice | formatNumber :basicform.aaskingPriceUnit }}</strong>
            </div> 
        
          </div>
          <hr>
          <div class="detail1">
            <mat-card-title class="project-title">Description</mat-card-title>
            <div class="description">{{basicform.description | capitalizeFirstLetter}}</div>
          </div>
        </mat-card-content>

      </mat-card>
    </div>

    <div *ngIf="inventoryType === 'commercials' else plote">
      <div class="col-12 first-container">
        <mat-card class="project-overview " style="text-align: center;">
          <mat-card-title class="project-title mb-3">
            Project Specifications
            <br>
          </mat-card-title><hr>
          
  
          <mat-card-content>
            <div class="project-details">
              <div class="detail">
                <mat-icon>construction</mat-icon>
                <span>Market Research:</span>
                <strong>{{ projectfeaturelist.marketResearch | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon>signal_wifi_4_bar</mat-icon>
                <span>Location:</span>
                <strong>{{projectfeaturelist.location | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                <span>Commercial Property Types:</span>
                <strong>{{projectfeaturelist.commercialPropertyTypes | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon>list</mat-icon>
                <span>Floor Plan:</span>
                <strong>{{projectfeaturelist.floorPlan | capitalizeFirstLetter }}</strong>
              </div>
              <div class="detail">
                <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                <span>Lease Structure:</span>
                <strong>{{projectfeaturelist.leaseStructure | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon>security</mat-icon>
                <span>Right Builder:</span>
                <strong>{{projectfeaturelist.rightBuilder | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon>vpn_key</mat-icon>
                <span>Risk & Return:</span>
                <strong>{{projectfeaturelist.riskAndReturn | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon>warning</mat-icon>
                <span>Value Assessment:</span>
                <strong>{{projectfeaturelist.valueAssessment | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon>check_circle</mat-icon>
                <span>Rental Income:</span>
                <strong>{{projectfeaturelist.rentalIncome | capitalizeFirstLetter}}</strong>
              </div>
              <div class="detail">
                <mat-icon>star</mat-icon>
                <span>Tenant Profiles:</span>
                <strong>{{projectfeaturelist.tenantProfiles | capitalizeFirstLetter}}</strong>
              </div>
            </div>
          </mat-card-content>
          
        </mat-card>
      </div>
    </div>
    <ng-template #plote>
      <div *ngIf="inventoryType === 'plots' else plotaaa">
        <div class="col-12 first-container">
          <mat-card class="project-overview " style="text-align: center;">
            <mat-card-title class="project-title mb-3">
              Project Specifications
              <br>
            </mat-card-title><hr>
            
    
            <mat-card-content>
              <div class="project-details">
                <div class="detail">
                  <mat-icon>construction</mat-icon>
                  <span>Title Deed:</span>
                  <strong>{{projectfeaturelist.titleDeed | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>signal_wifi_4_bar</mat-icon>
                  <span>Encumbrance Certificate:</span>
                  <strong>{{projectfeaturelist.encumbranceCertificate | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                  <span>Property Taxes:</span>
                  <strong>{{projectfeaturelist.propertyTaxes | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>list</mat-icon>
                  <span>Location:</span>
                  <strong>{{projectfeaturelist.location | capitalizeFirstLetter }}</strong>
                </div>
                <div class="detail">
                  <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                  <span>Uniformity Of Plot:</span>
                  <strong>{{projectfeaturelist.uniformityOfThePlot | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>security</mat-icon>
                  <span>Completion Certificate:</span>
                  <strong>{{projectfeaturelist.completionCertificate | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>vpn_key</mat-icon>
                  <span>SoilQuality:</span>
                  <strong>{{projectfeaturelist.soilQuality | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>warning</mat-icon>
                  <span>Access To Plot:</span>
                  <strong>{{projectfeaturelist.accessToThePlot | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>check_circle</mat-icon>
                  <span>AvoidVerbal Agreement:</span>
                  <strong>{{projectfeaturelist.avoidVerbalAgreement | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>star</mat-icon>
                  <span>Builder:</span>
                  <strong>{{projectfeaturelist.builder | capitalizeFirstLetter}}</strong>
                </div>

                <div class="detail">
                  <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                  <span>Connectivity:</span>
                  <strong>{{projectfeaturelist.connectivity | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>security</mat-icon>
                  <span>Completion Certificate:</span>
                  <strong>{{projectfeaturelist.completionCertificate | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>vpn_key</mat-icon>
                  <span>Construction Approvals:</span>
                  <strong>{{projectfeaturelist.constructionApprovals | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>warning</mat-icon>
                  <span>Flat Purchase Checklist:</span>
                  <strong>{{projectfeaturelist.flatPurchaseChecklist | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>check_circle</mat-icon>
                  <span>Investment Perspective:</span>
                  <strong>{{projectfeaturelist.investmentPerspective | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>star</mat-icon>
                  <span>Order:</span>
                  <strong>{{projectfeaturelist.naOrder | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                  <span>Power Of Attorney:</span>
                  <strong>{{projectfeaturelist.powerOfAttorney | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>security</mat-icon>
                  <span>Security:</span>
                  <strong>{{projectfeaturelist.security | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>vpn_key</mat-icon>
                  <span>Water ElectricitySupply:</span>
                  <strong>{{projectfeaturelist.waterElectricitySupply | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>warning</mat-icon>
                  <span>Zoning Risks:</span>
                  <strong>{{projectfeaturelist.zoningRisks | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>check_circle</mat-icon>
                  <span>Approvals:</span>
                  <strong>{{projectfeaturelist.approvals | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>star</mat-icon>
                  <span>dueDiligence:</span>
                  <strong>{{projectfeaturelist.dueDiligence | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>check_circle</mat-icon>
                  <span>Due Diligence:</span>
                  <strong>{{projectfeaturelist.dueDiligence | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>star</mat-icon>
                  <span>dueDiligence:</span>
                  <strong>{{projectfeaturelist.dueDiligence | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>security</mat-icon>
                  <span>Encumbrance:</span>
                  <strong>{{projectfeaturelist.encumbrance | capitalizeFirstLetter}}</strong>
                </div>
                <div class="detail">
                  <mat-icon>vpn_key</mat-icon>
                  <span>History Of Property:</span>
                  <strong>{{projectfeaturelist.historyOfTheProperty | capitalizeFirstLetter}}</strong>
                </div>
              </div>
            </mat-card-content>
            
          </mat-card>
        </div>
        <div class="amenities-container">
          <div class="amenitiestrue">Project Amenities</div><hr>
          <!-- <mat-card-title class="project-title mb-3">Project Amenities<br></mat-card-title><hr> -->
          <mat-grid-list cols="4" rowHeight="100px" gutterSize="10px">
            <mat-grid-tile *ngFor="let amenity of displayedAmenities">
              <div class="amenity-item" [ngClass]="{'enabled': amenity.enabled, 'disabled': !amenity.enabled}">
                <mat-icon class="amenity-icon">{{ amenity.icon }}</mat-icon>
                <span class="amenity-label">{{ amenity.label }}</span>
              </div>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!showAll && amenities.length > 8" [colspan]="1" [rowspan]="1">
              <div class="show-more-container" (click)="toggleShowMore()">
                <span class="show-more-text">+{{ amenities.length - 7 }} more</span>
                <mat-icon>expand_more</mat-icon>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <button *ngIf="showAll" class="show-less-button" mat-button (click)="toggleShowMore()">Show Less</button>
        </div>
      </div>
      <ng-template #plotaaa>
        <div *ngIf="inventoryType === 'flats' else villas">
          <div class="col-12 first-container">
            <mat-card class="project-overview" style="text-align: center;">
              <mat-card-title class="project-title mb-3">
                Project Specifications
                <br>
              </mat-card-title>
              <hr>
        
              <mat-card-content>
                <div class="project-details">
                  <!-- <div class="detail">
                    <mat-icon>construction</mat-icon>
                    <span>Amenity:</span>
                    <strong>{{ amenity | capitalizeFirstLetter }}</strong>
                  </div> -->
                  <div class="detail">
                    <mat-icon>check_circle</mat-icon>
                    <span>RERA Compliance:</span>
                    <strong>{{projectfeaturelist.reraCompliance  | capitalizeFirstLetter}}</strong>
                  </div>
                  <!-- <div class="detail">
                    <mat-icon>attach_money</mat-icon>
                    <span>Budget:</span>

                    <strong>{{projectfeaturelist.budget  }}</strong>
                  </div>
                    <strong>{{projectfeaturelist.budget | formatNumber }}</strong>
                  </div> -->

                  <div class="detail">
                    <mat-icon>location_on</mat-icon>
                    <span>Location:</span>
                    <strong>{{ projectfeaturelist.location | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>assignment_turned_in</mat-icon>
                    <span>Occupancy Certificate:</span>
                    <strong>{{projectfeaturelist.occupancyCertificate | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>area_chart</mat-icon>
                    <span>Area of Flat:</span>
                    <strong>{{projectfeaturelist.areaOfFlat | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>security</mat-icon>
                    <span>Bank NOC:</span>
                    <strong>{{projectfeaturelist.bankNoc | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>attach_money</mat-icon>
                    <span>Construction Quality:</span>
                    <strong>{{ projectfeaturelist.constructionQuality | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>build</mat-icon>
                    <span>Reputed Builders:</span>
                    <strong>{{ projectfeaturelist.reputedBuilders | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Construction Approvals:</span>
                    <strong>{{ projectfeaturelist.constructionApprovals | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>check</mat-icon>
                    <span>Legal Check of Property:</span>
                    <strong>{{ projectfeaturelist.legalCheckOfProperty | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>verified_user</mat-icon>
                    <span>Title Verification:</span>
                    <strong>{{ projectfeaturelist.titleVerification | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>home</mat-icon>
                    <span>Apartment Possession:</span>
                    <strong>{{ projectfeaturelist.apartmentPossession | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>description</mat-icon>
                    <span>Verbal Agreement:</span>
                    <strong>{{ projectfeaturelist.verbalAgreement | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>assignment</mat-icon>
                    <span>Builder Buyer Agreement:</span>
                    <strong>{{ projectfeaturelist.builderBuyerAgreement | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>warning</mat-icon>
                    <span>Encumbrance:</span>
                    <strong>{{ projectfeaturelist.encumbrance | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>map</mat-icon>
                    <span>Land Use Permissions:</span>
                    <strong>{{ projectfeaturelist.landUsePermissions | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>check_circle</mat-icon>
                    <span>Physical Visit:</span>
                    <strong>{{ projectfeaturelist.physicalVisit | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>place</mat-icon>
                    <span>Property Location:</span>
                    <strong>{{ projectfeaturelist.propertyLocation | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>monetization_on</mat-icon>
                    <span>Property Taxes:</span>
                    <strong>{{ projectfeaturelist.propertyTaxes | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>attach_money</mat-icon>
                    <span>Resale Value:</span>
                    <strong>{{ projectfeaturelist.resaleValue | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>assignment</mat-icon>
                    <span>Sale Agreement:</span>
                    <strong>{{ projectfeaturelist.saleAgreement | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>security</mat-icon>
                    <span>Security:</span>
                    <strong>{{ projectfeaturelist.security | capitalizeFirstLetter }}</strong>
                  </div>
                  <div class="detail">
                    <mat-icon>money</mat-icon>
                    <span>Service Tax:</span>
                    <strong>{{ projectfeaturelist.serviceTax | capitalizeFirstLetter }}</strong>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="amenities-container">
            <div class="amenitiestrue">Project Amenities</div><hr>

            <mat-grid-list cols="4" rowHeight="100px" gutterSize="10px">
              <mat-grid-tile *ngFor="let amenity of displayedAmenities">
                <div class="amenity-item" [ngClass]="{'enabled': amenity.enabled, 'disabled': !amenity.enabled}">
                  <mat-icon class="amenity-icon">{{ amenity.icon }}</mat-icon>
                  <span class="amenity-label">{{ amenity.label }}</span>
                </div>
              </mat-grid-tile>
              <mat-grid-tile *ngIf="!showAll && amenities.length > 8" [colspan]="1" [rowspan]="1">
                <div class="show-more-container" (click)="toggleShowMore()">
                  <span class="show-more-text">+{{ amenities.length - 7 }} more</span>
                  <mat-icon>expand_more</mat-icon>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <button *ngIf="showAll" class="show-less-button" mat-button (click)="toggleShowMore()">Show Less</button>
          </div>
        </div>
        <ng-template #villas>
          <div *ngIf="inventoryType === 'villas' else commercialsaa">
            <div class="col-12 first-container">
              <mat-card class="project-overview">
                <mat-card-title class="project-title mb-3">
                  Property Specifications
                </mat-card-title>
                <hr>
                <mat-card-content>
                  <div class="project-details">
                    <!-- <div class="detail">
                      <mat-icon>star</mat-icon>
                      <span>Amenity:</span>
                      <strong>{{ amenity }}</strong>
                    </div> -->
                    <div class="detail">
                      <mat-icon>title</mat-icon>
                      <span>Property Taxes:</span>
                      <strong>{{ projectfeaturelist.propertyTaxes | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>location_on</mat-icon>
                      <span>Construction Quality:</span>
                      <strong>{{ projectfeaturelist.constructionQuality | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>info</mat-icon>
                      <span>Occupancy Certificate:</span>
                      <strong>{{ projectfeaturelist.occupancyCertificate | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>accessibility</mat-icon>
                      <span>Security:</span>
                      <strong>{{ projectfeaturelist.security | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>monetization_on</mat-icon>
                      <span>Legal Compliance:</span>
                      <strong>{{ projectfeaturelist.legalCompliance | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>money_off</mat-icon>
                      <span>Location:</span>
                      <strong>{{ projectfeaturelist.location | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>home</mat-icon>
                      <span>Location Considerations:</span>
                      <strong>{{ projectfeaturelist.locationConsiderations | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>trending_up</mat-icon>
                      <span>Title Deed:</span>
                      <strong>{{ projectfeaturelist.titleDeed | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>construction</mat-icon>
                      <span>Accessibility:</span>
                      <strong>{{ projectfeaturelist.accessibility | capitalizeFirstLetter}}</strong>
                    </div>
                    <!-- <div class="detail">
                      <mat-icon>build</mat-icon>
                      <span>Budget:</span>
                      <strong>{{ projectfeaturelist.budget | formatNumber}}</strong>
                    </div> -->
                    <div class="detail">
                      <mat-icon>spa</mat-icon>
                      <span>Builder Reputation:</span>
                      <strong>{{ projectfeaturelist.builderReputation | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>privacy_tip</mat-icon>
                      <span>Construction Approvals:</span>
                      <strong>{{ projectfeaturelist.constructionApprovals | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>security</mat-icon>
                      <span>Documents For PropertyUse:</span>
                      <strong>{{ projectfeaturelist.documentsForPropertyUse | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>gavel</mat-icon>
                      <span>Home Loan:</span>
                      <strong>{{ projectfeaturelist.homeLoan | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>home_work</mat-icon>
                      <span>Investment Perspective:</span>
                      <strong>{{ projectfeaturelist.investmentPerspective | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>check_circle</mat-icon>
                      <span>Land Documents:</span>
                      <strong>{{ projectfeaturelist.landDocuments | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>description</mat-icon>
                      <span>Modern Features:</span>
                      <strong>{{ projectfeaturelist.modernFeatures | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>document_scanner</mat-icon>
                      <span>No Objection Certificates:</span>
                      <strong>{{ projectfeaturelist.noObjectionCertificates | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>no_sim</mat-icon>
                      <span>Out door Amenities:</span>
                      <strong>{{ projectfeaturelist.outdoorAmenities | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>scale</mat-icon>
                      <span>Pricing:</span>
                      <strong>{{ projectfeaturelist.pricing  | formatNumber}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>description</mat-icon>
                      <span>Privacy & Space:</span>
                      <strong>{{ projectfeaturelist.privacyAndSpace | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>document_scanner</mat-icon>
                      <span>Real Estate Developer</span>
                      <strong>{{ projectfeaturelist.realEstateDeveloper | capitalizeFirstLetter}}</strong>
                    </div>
                    <div class="detail">
                      <mat-icon>crop_square</mat-icon>
                      <span>Size of Property:</span>
                      <strong>{{ projectfeaturelist.sizeOfProperty | capitalizeFirstLetter}}</strong>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="amenities-container">
              <div class="amenitiestrue">Project Amenities</div><hr>

              <mat-grid-list cols="4" rowHeight="100px" gutterSize="10px">
                <mat-grid-tile *ngFor="let amenity of displayedAmenities">
                  <div class="amenity-item" [ngClass]="{'enabled': amenity.enabled, 'disabled': !amenity.enabled}">
                    <mat-icon class="amenity-icon">{{ amenity.icon }}</mat-icon>
                    <span class="amenity-label">{{ amenity.label }}</span>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="!showAll && amenities.length > 8" [colspan]="1" [rowspan]="1">
                  <div class="show-more-container" (click)="toggleShowMore()">
                    <span class="show-more-text">+{{ amenities.length - 7 }} more</span>
                    <mat-icon>expand_more</mat-icon>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
              <button *ngIf="showAll" class="show-less-button" mat-button (click)="toggleShowMore()">Show Less</button>
            </div>
            
          </div>
          <ng-template #commercialsaa>
            <div *ngIf="inventoryType === 'offices' else mansionsss">
              <div class="col-12 first-container">
                <mat-card class="project-overview " style="text-align: center;">
                  <mat-card-title class="project-title mb-3">
                    Project Specifications
                    <br>
                  </mat-card-title><hr>
                  
          
                  <mat-card-content>
                    <div class="project-details">
                      <div class="detail">
                        <mat-icon>construction</mat-icon>
                        <span>Location</span>
                        <strong>{{projectfeaturelist.location | capitalizeFirstLetter }}</strong>
                      </div>
                      <!-- <div class="detail">
                        <mat-icon>signal_wifi_4_bar</mat-icon>
                        <span>Amenity:</span>
                        <strong>{{amenity | capitalizeFirstLetter}}</strong>
                      </div> -->
                      <div class="detail">
                        <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                        <span>InvestmentGoals</span>
                        <strong>{{projectfeaturelist.investmentGoals | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>list</mat-icon>
                        <span>Title:</span>
                        <strong>{{projectfeaturelist.title | capitalizeFirstLetter }}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>
                        <span>Cash Flow:</span>
                        <strong>{{projectfeaturelist.cashFlow | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>security</mat-icon>
                        <span>Legal Documents:</span>
                        <strong>{{projectfeaturelist.legalDocuments | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>vpn_key</mat-icon>
                        <span>Financial Considerations:</span>
                        <strong>{{projectfeaturelist.financialConsiderations | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>warning</mat-icon>
                        <span>Infrastructuret:</span>
                        <strong>{{projectfeaturelist.infrastructure | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>check_circle</mat-icon>
                        <span>LeaseStructure:</span>
                        <strong>{{projectfeaturelist.leaseStructure | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>star</mat-icon>
                        <span>Market Research:</span>
                        <strong>{{projectfeaturelist.marketResearch | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>security</mat-icon>
                        <span>Tax Payment Status:</span>
                        <strong>{{projectfeaturelist.taxPaymentStatus | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>vpn_key</mat-icon>
                        <span>Value Assessment:</span>
                        <strong>{{projectfeaturelist.valueAssessment | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>warning</mat-icon>
                        <span>Property Documentation:</span>
                        <strong>{{projectfeaturelist.propertyDocumentation | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>check_circle</mat-icon>
                        <span>Right Builder:</span>
                        <strong>{{projectfeaturelist.rightBuilder | capitalizeFirstLetter}}</strong>
                      </div>
                      <div class="detail">
                        <mat-icon>star</mat-icon>
                        <span>Property Location:</span>
                        <strong>{{projectfeaturelist.propertyLocation | capitalizeFirstLetter}}</strong>
                      </div>
                    </div>
                  </mat-card-content>
                  
                </mat-card>
              </div>
              <div class="amenities-container">
                <div class="amenitiestrue">Project Amenities</div><hr>

                <mat-grid-list cols="4" rowHeight="100px" gutterSize="10px">
                  <mat-grid-tile *ngFor="let amenity of displayedAmenities">
                    <div class="amenity-item" [ngClass]="{'enabled': amenity.enabled, 'disabled': !amenity.enabled}">
                      <mat-icon class="amenity-icon">{{ amenity.icon }}</mat-icon>
                      <span class="amenity-label">{{ amenity.label }}</span>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile *ngIf="!showAll && amenities.length > 8" [colspan]="1" [rowspan]="1">
                    <div class="show-more-container" (click)="toggleShowMore()">
                      <span class="show-more-text">+{{ amenities.length - 7 }} more</span>
                      <mat-icon>expand_more</mat-icon>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
                <button *ngIf="showAll" class="show-less-button" mat-button (click)="toggleShowMore()">Show Less</button>
              </div>
            </div>
            <ng-template #mansionsss>
              <div *ngIf="inventoryType === 'mansions'">
                <div class="col-12 first-container">
                  <mat-card class="project-overview" style="text-align: center;">
                    <mat-card-title class="project-title mb-3">
                      Project Specifications
                      <br>
                    </mat-card-title>
                    <hr>
                    <mat-card-content>
                      <div class="project-details">
                        <div class="detail">
                          <mat-icon>construction</mat-icon>
                          <span>Location:</span>
                          <strong>{{ projectfeaturelist.location | capitalizeFirstLetter }}</strong>
                        </div>
                        <div class="detail">
                          <mat-icon>signal_wifi_4_bar</mat-icon>
                          <span>Security:</span>
                          <strong>{{ projectfeaturelist.security | capitalizeFirstLetter }}</strong>
                        </div>
                        <!-- <div class="detail">
                          <mat-icon aria-label="Amenity Icon">info</mat-icon>
                          <span>Amenity:</span>
                          <strong>{{ amenity | capitalizeFirstLetter }}</strong>
                        </div> -->
                        <div class="detail">
                          <mat-icon>list</mat-icon>
                          <span>Trusted Developer:</span>
                          <strong>{{ projectfeaturelist.trustedDeveloper | capitalizeFirstLetter }}</strong>
                        </div>
                        <div class="detail">
                          <mat-icon>check_circle</mat-icon>
                          <span>Construction Approvals:</span>
                          <strong>{{ projectfeaturelist.constructionApprovals | capitalizeFirstLetter }}</strong>
                        </div>
                        <div class="detail">
                          <mat-icon>security</mat-icon>
                          <span>Documents for Property Use:</span>
                          <strong>{{ projectfeaturelist.documentsForPropertyUse | capitalizeFirstLetter }}</strong>
                        </div>
                        <div class="detail">
                          <mat-icon>vpn_key</mat-icon>
                          <span>High-End Kitchens:</span>
                          <strong>{{ projectfeaturelist.highEndKitchens | capitalizeFirstLetter }}</strong>
                        </div>
                        <div class="detail">
                          <mat-icon>warning</mat-icon>
                          <span>Investment Perspective:</span>
                          <strong>{{ projectfeaturelist.investmentPerspective | capitalizeFirstLetter }}</strong>
                        </div>
                        <div class="detail">
                          <mat-icon>check_circle</mat-icon>
                          <span>Smart Homes:</span>
                          <strong>{{ projectfeaturelist.smartHomes | capitalizeFirstLetter}}</strong>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="amenities-container">
                  <div class="amenitiestrue">Project Amenities</div><hr>

                  <mat-grid-list cols="4" rowHeight="100px" gutterSize="10px">
                    <mat-grid-tile *ngFor="let amenity of displayedAmenities">
                      <div class="amenity-item" [ngClass]="{'enabled': amenity.enabled, 'disabled': !amenity.enabled}">
                        <mat-icon class="amenity-icon">{{ amenity.icon }}</mat-icon>
                        <span class="amenity-label">{{ amenity.label }}</span>
                      </div>
                    </mat-grid-tile>
                    <mat-grid-tile *ngIf="!showAll && amenities.length > 8" [colspan]="1" [rowspan]="1">
                      <div class="show-more-container" (click)="toggleShowMore()">
                        <span class="show-more-text">+{{ amenities.length - 7 }} more</span>
                        <mat-icon>expand_more</mat-icon>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list>
                  <button *ngIf="showAll" class="show-less-button" mat-button (click)="toggleShowMore()">Show Less</button>
                </div>
              </div>
          </ng-template>
        </ng-template>
      </ng-template>

     </ng-template>

     <div class="col-12 first-container">
      <mat-card class="project-overview" >
        <mat-card-content>
          <div class="project-details">
            <div class="detail mt-2">
              <span class="posted" style="color: black;">Posted On Owner :</span>
              <strong style="color: gray;">{{basicform.createdAt | timeAgo }}</strong>
            </div>
            <div class="detail10 mt-2" >
              <button mat-button class="right-align-button" (click)="back()">Back</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    










   

<br><br>
  <app-footer></app-footer>