<!-- <mat-dialog-content> -->
  <div class="row first-row">
    <div class="col-10">
      <span class="title">Verify OTP</span>
    </div>
    <div class="col-2 close-container">
      <button class="close-button" (click)="closeDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="otp-card">
    <!-- <div class="spinner"></div> -->
    <div id="otp" class="form-group">
      <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
    </div>
    <div class="button-container">
      <button mat-raised-button color="primary" (click)="verifyOtp()">Verify OTP</button>
    </div>
  </div>
<!-- </mat-dialog-content> -->
