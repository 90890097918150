<div class="wrapper">
  <app-header></app-header>


  <div class="content">
    <app-menu></app-menu>
    <div class="row col-md-12 p-2 justify-content-center">
      <div class="col-md-7">
        <div class="mt-5">
          <div class="firstshift">Welcome Back! Let’s Continue Your Search</div>
        </div>
        <div class="mt-3">
          <mat-tab-group class="custom-tabs" (selectedTabChange)="onTabChange($event)">
            <mat-tab *ngFor="let tab of tabs" [label]="tab.name">
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="mt-3">
          <div class="container row col-12">
            <div class="custom-search">
              <div class="row align-items-center">
                <div class="col-md-2 d-flex justify-content-start">
                  <div class="styled-select-container">
                    <select class="styled-select" [(ngModel)]="selectedCityId" (change)="onCityChange()">
                      <option *ngFor="let city of cityDataList" [value]="city.cityId">
                        {{ city.cityName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-8 d-flex justify-content-start position-relative">
                  <!-- <input type="text" #searchkey class="form-control search-input"
                    placeholder="Search for locality, landmark, project, or builder" [(ngModel)]="searchQuery"
                    (keyup)="onSearchKeyChange()" (focus)="showSuggestions = true" (blur)="hideSuggestions()" /> -->


                  <!-- search.component.html -->
                  <input type="text" #searchkey class="form-control search-input" [placeholder]="placeholderText"
                    [(ngModel)]="searchQuery" (keyup)="onSearchKeyChange()" (focus)="showSuggestions = true"
                    (blur)="hideSuggestions()" />

                    

                  <div class="suggestion-container">
                    <ul *ngIf="showSuggestions && areaList.length > 0" class="suggestion-dropdown">
                      <li *ngFor="let area of areaList" (click)="selectArea(area)">
                        {{ area.area | titlecase }}, {{ area.cityName | titlecase }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-2 d-flex justify-content-end">
                  <button class="btn btn-primary11 onlyhover" #searchkey (click)="searchArt()">Search</button>
                </div>
                <div *ngIf="isRequiredMessageVisible" class="error-message">
                  Please Try Again With The Location Included!
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>




  </div>
  <div class="col-md-12 " style="margin-top: 6pc; z-index: 0;">
    <!-- <div class="col-md-11">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Top Property</span>
        <button (click)="navigateTOhome()" class="btn btn-primary viewall">View All</button>
      </div>
      
      <span class="toppro">Fresh Property Listings Available Here</span>
  </div> -->
    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Top Property</span>
        <button (click)="navigateTOhome()" class="btn btn-primary viewall">View All</button>
      </div>

      <span class="toppro">Fresh Property Listings Available Here</span>
    </div>

    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer;">
      <swiper #swiperRef (slideChange)="onSlideChange($event)" [slidesPerView]="1" [spaceBetween]="15" [navigation]="{ nextEl: '.custom-next-button1',
        prevEl: '.custom-prev-button1' }" [breakpoints]="{
            '768': { 'slidesPerView': 3 }
          }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of tophome">
          <app-home-top-property (click)="navigateTofullDetail(card.id)" [cardData]="card">
          </app-home-top-property>
        </ng-template>
      </swiper>

      <div class="custom-prev-button1" (click)="onPrevClick(0)" [ngClass]="{'hide': !showPrevButton}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button1" (click)="onNextClick(0)" [ngClass]="{'hide': !showNextButton}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_forward
        </mat-icon>
      </div>

    </div>

  </div>

  <div class="col-md-12 " style="margin-top: 3pc; z-index: 0;">
    <!-- <div class="col-md-11">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Top Projects</span>
        <button (click)="navigateTOListing()" class="btn btn-primary viewall">View All</button>
      </div>
      
      <span class="toppro">Best Developers In India To Explore</span>
  </div> -->


    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Top Projects</span>
        <button (click)="navigateTOListing()" class="btn btn-primary viewall">View All</button>
      </div>

      <span class="toppro">Best Developers In India To Explore</span>
    </div>
    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer;">
      <swiper #swiperRef (slideChange)="onSlideChange($event)" [slidesPerView]="1" [spaceBetween]="15" [navigation]="{ nextEl: '.custom-next-button1',
        prevEl: '.custom-prev-button1' }" [breakpoints]="{
            '768': { 'slidesPerView': 3 }
          }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of topList">
          <!-- <app-top-property-card (click)="navigateTofullDetail()"
      [cardData]="card"
      [showEditDetailsButton]="false"
      [showViewDetailsButton]="false"
      [showInventoryButton]="false"
      [showEditInventoryButton]="false">
    </app-top-property-card>  -->
          <app-top-property-card (click)="navigateTofullDetail(card.id)" [cardData]="card"
            [showEditDetailsButton]="false" [showViewDetailsButton]="false" [showInventoryButton]="false"
            [showEditInventoryButton]="false" [showdetails]="false" [showdsize]='false'>
          </app-top-property-card>

        </ng-template>
      </swiper>

      <div class="custom-prev-button1" (click)="onPrevClick(0)" [ngClass]="{'hide': !showPrevButton}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button1" (click)="onNextClick(0)" [ngClass]="{'hide': !showNextButton}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_forward
        </mat-icon>
      </div>

    </div>

  </div>

  <div class="col-md-12" style="margin-top: 3pc;">
    <!-- <div class="col-md-11">
      <div class="d-flex justify-content-between justify-content-center">
        <span class="topproject mb-2">Recently Added</span>
        <button (click)="navigateTOListing1()" class="btn btn-primary viewall" >View All</button>
      </div>
      <span class="toppro">Based On Preferences Of Users Like You</span>
    </div> -->


    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Recently Added</span>
        <button (click)="navigateTOListing1()" class="btn btn-primary viewall">View All</button>
      </div>

      <span class="toppro">Based On Preferences Of Users Like You</span>
    </div>

    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer; position: relative;">
      <swiper #swiperRef2 [slidesPerView]="1" [spaceBetween]="10"
        [navigation]="{ nextEl: '.custom-next-button2', prevEl: '.custom-prev-button2' }"
        (slideChange)="onSlideChange2($event)" [breakpoints]="{
        '768': { 'slidesPerView': 4 }
      }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of recentList.slice(0,4)">
          <app-top-property-card-small (click)="navigateTofullDetail(card.id)" [cardData]="card" [showsizeonly]="false">
          </app-top-property-card-small>

        </ng-template>
      </swiper>


    </div><br>
    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer; position: relative;">
      <swiper #swiperRef2 [slidesPerView]="1" [spaceBetween]="10"
        [navigation]="{ nextEl: '.custom-next-button2', prevEl: '.custom-prev-button2' }"
        (slideChange)="onSlideChange2($event)" [breakpoints]="{
        '768': { 'slidesPerView': 4 }
      }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of recentList.slice(4)">
          <app-top-property-card-small (click)="navigateTofullDetail(card.id)" [cardData]="card" [showsizeonly]="false">
          </app-top-property-card-small>

        </ng-template>
      </swiper>

      <div class="custom-prev-button2" (click)="onPrevClick(1)" [ngClass]="{'hide': !showPrevButton2}">
        <mat-icon class="nav-icon">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button2" (click)="onNextClick(1)" [ngClass]="{'hide': !showNextButton2}">
        <mat-icon class="nav-icon">
          arrow_forward
        </mat-icon>
      </div>
    </div>

  </div>

  <div class="col-md-12" style="margin-top: 3pc;">

    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Trending Projects</span>
        <button (click)="navigateTOListing2()" class="btn btn-primary viewall">View All</button>
      </div>

      <span class="toppro">Most Sought-after Projects In India</span>
    </div>

    <!-- <div class="col-md-11">
      <div class="d-flex justify-content-between justify-content-center">
        <span class="topproject mb-2">Trending Projects</span>
        <button (click)="navigateTOListing2()" class="btn btn-primary viewall" >View All</button>
      </div>
  
      <span class="toppro">Most Sought-after Projects In India</span>
    </div> -->

    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer;">
      <swiper #swiperRef3 [slidesPerView]="1" [spaceBetween]="10"
        [navigation]="{ nextEl: '.custom-next-button3', prevEl: '.custom-prev-button3' }"
        (slideChange)="onSlideChange3($event)" [breakpoints]="{
                '768': { 'slidesPerView': 3 }
              }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of trendingList">
          <app-top-property-card-semi-small (click)="navigateTofullDetail(card.id)"
            [cardData]="card"></app-top-property-card-semi-small>
        </ng-template>
      </swiper>

      <div class="custom-prev-button3" (click)="onPrevClick(2)" [ngClass]="{'hide': !showPrevButton3}">
        <mat-icon [ngClass]="{'active': activeButtons[2], 'inactive': !activeButtons[2]}">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button3" (click)="onNextClick(2)" [ngClass]="{'hide': !showNextButton3}">
        <mat-icon [ngClass]="{'active': activeButtons[2], 'inactive': !activeButtons[2]}">
          arrow_forward
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="col-md-12 mt-4">
    <!-- <button class="btn-info">
  </button> -->
  </div>
  <!-- <mat-card >
    <mat-card-content> 
    <button class="btn btn-primary">Post Your Property For Free</button></mat-card-content>
  </mat-card> -->

  <!-- <section class="container">
    <div class="row">
      <div class="col-12">

      </div>
    </div>
  </section> -->

  <!-- share-post.component.html -->
  <!-- <share-buttons
    [theme]="'circles-dark'"
    [include]="['facebook','twitter','linkedin','whatsapp','pinterest','reddit','mix','vk','telegram','messenger','xing','line','sms','email','copy']"
    [show]="9"
    [size]="1"
    [showText]="true"
    [url]="url"
    [title]="title"
    [description]="fullDescription"
    [image]="image"
    [autoSetMeta]="false">
  </share-buttons> -->
  <div *ngIf="userinfo else notlogin1" class="mt-3 mb-1 mr-2" style="cursor: pointer;">
    <div class="col-md-11 d-flex justify-content-end justify-content-center-mobile" (click)="postproperty()">
      <mat-card class="custom-card onlyhover" appearance="outlined">
        <mat-card-content>
          <div class="lastseen">
            <i class="fa fa-home" aria-hidden="true"></i>
            Post Your Property For Free
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-template #notlogin1>
    <div class="col-md-11 d-flex justify-content-end justify-content-center-mobile" (click)="postpropertynotlogin()"
      style="cursor: pointer;">
      <mat-card class="custom-card onlyhover" appearance="outlined">
        <mat-card-content>
          <div class="lastseen">
            <i class="fa fa-home" aria-hidden="true"></i>
            Post Your Property For Free
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-template>






  <br>
  <app-footer></app-footer>
</div>