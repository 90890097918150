import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-property-slide',
  templateUrl: './property-slide.component.html',
  styleUrls: ['./property-slide.component.scss'],
  providers: [CurrencyPipe]  // Provide CurrencyPipe
})
export class PropertySlideComponent implements OnInit {
  @Output() payloadEvent = new EventEmitter<any>();
  hasMore: boolean = false;
  isExpanded: boolean = true;
  selectedMinValue: number | null = null;
  selectedMaxValue: number | null = null;
  selectedBudget: string = '';
  panelOpenState = false;
  userfilter: string[] = [];
  showAllBedrooms: boolean = false;
  selectedType: string[] = [];
  isFilterSidebarOpen = false;

  constructor(private cdr: ChangeDetectorRef, private currencyPipe: CurrencyPipe ,private router:Router)  {}

  propertyTypes = [
    { name: 'Residential', selected: false },
    { name: 'Commercial', selected: false },
    { name: 'Flat/Apartment', selected: false },
    { name: 'Independent House/Villa', selected: false },
    { name: 'Service Apartment', selected: false },
    { name: 'Farm House', selected: false },
    { name: 'Agricultural Land', selected: false },

  ];
  budgetOptions = [
    { value: 500000, label: '5 Lacs' },
    { value: 1000000, label: '10 Lacs' },
    { value: 1500000, label: '15 Lacs' },
    { value: 2500000, label: '25 Lacs' },
    { value: 5000000, label: '50 Lacs' },
    { value: 8000000, label: '80 Lacs' },
    { value: 10000000, label: '1 Cr' },
    { value: 100000000, label: '10+ Crores'}
  ];

  bedroomOptions = [
    { name: '1', value: '1', selected: false },
    { name: '2', value: '2', selected: false },
    { name: '3', value: '3', selected: false },
    { name: '4', value: '4', selected: false },
    { name: '5', value: '5', selected: false },
    { name: '6', value: '6', selected: false },
    { name: '7', value: '7', selected: false },
    { name: '8', value: '8', selected: false },
    { name: '9', value: '9', selected: false },
    { name: '10', value: '10', selected: false },
    { name: '10+', value: '10+', selected: false }
  ];

  payload: any = {};

  toggleMore() {
    this.hasMore = !this.hasMore;
  }


  selectPropertyType(propertyType) {
    propertyType.selected = !propertyType.selected;

    if (propertyType.selected) {
      this.userfilter.push(propertyType.name);
      if (!this.selectedType.includes("type")) {
        this.selectedType.push("type");
      }
    } else {
      this.userfilter = this.userfilter.filter(name => name !== propertyType.name);
      if (this.userfilter.length === 0) {
        this.selectedType = this.selectedType.filter(type => type !== "type");
      }
    }

    // Save the selected property types in localStorage
    this.saveSelectedTypesToLocalStorage();

    this.updatePayload();
    this.cdr.detectChanges();
  }

  // Add a method to save selected property types to localStorage
  saveSelectedTypesToLocalStorage() {
    const selectedTypes = this.propertyTypes.filter(pt => pt.selected).map(pt => pt.name);
    localStorage.setItem('search_type', selectedTypes.join(',')); // Save as comma-separated string
  }


  selectBedroom(bedroom) {
    console.log(bedroom);

    bedroom.selected = !bedroom.selected;
    if (bedroom.selected) {
      this.userfilter.push(bedroom.value);
      this.selectedType.push("noOfBadroom");

    } else {
      this.userfilter = this.userfilter.filter(name => name !== bedroom.name);
      this.selectedType = this.selectedType.filter(noOfBadroom => noOfBadroom !== "noOfBadroom");

    }
    this.updatePayload();
    this.cdr.detectChanges();
  }
  onBudgetChange() {
    if (this.selectedMinValue !== null && this.selectedMaxValue !== null) {
      if (this.selectedMaxValue < this.selectedMinValue) {
        this.selectedMaxValue = null;
        return;
      }
      const fromAsking = this.formatCurrency(this.selectedMinValue);
      const toAsking = this.formatCurrency(this.selectedMaxValue);
      const newSelectedBudget = `${fromAsking} - ${toAsking}`;
      if (this.selectedBudget !== newSelectedBudget) {
        this.selectedBudget = newSelectedBudget;
        this.userfilter = this.userfilter.filter(item => !item.includes('-'));
        this.userfilter.push(this.selectedBudget);
        if (!this.selectedType.includes("asking")) {
          this.selectedType.push("asking");
        }

        this.updatePayload();
        this.cdr.detectChanges();
      }
    }
  }


  removeBudget() {
    // Reset budget values and remove budget filter
    this.selectedBudget = '';
    this.selectedMinValue = null;
    this.selectedMaxValue = null;

    // Remove any budget range from userfilter
    this.userfilter = this.userfilter.filter(item => !item.includes('-'));

    // Update payload and detect changes
    this.updatePayload();
    this.cdr.detectChanges();
  }



formatCurrency(value: number): string {
    // Transform the value using CurrencyPipe
    const formattedValue = this.currencyPipe.transform(value, 'INR', 'symbol', '1.0-0');
    // Remove commas and the currency symbol
    return formattedValue?.replace(/[₹,]/g, '') ?? '';
}

  removeFilter(filter: string) {
    console.log(filter);

    this.userfilter = this.userfilter.filter(name => name !== filter);
    this.propertyTypes.forEach(pt => {
      if (pt.name === filter) pt.selected = false;
    });
    this.bedroomOptions.forEach(br => {
      if (br.name === filter) br.selected = false;
    });
    this.updatePayload();
    this.cdr.detectChanges();
  }


  // clearSelection() {
  //   this.propertyTypes.forEach(pt => pt.selected = false);
  //   this.bedroomOptions.forEach(br => br.selected = false);
  //   this.removeBudget();
  //   this.userfilter = [];
  //   this.selectedType = [];
  //   this.updatePayload();
  //   this.cdr.detectChanges();
  //   this.isFilterSidebarOpen = false;
  // }


  applyFilter() {
    console.log('Final store data', this.payload);
    this.payloadEvent.emit(this.payload);
    this.isFilterSidebarOpen = false;
  }

  toggleShowAllBedrooms() {
    this.showAllBedrooms = !this.showAllBedrooms;
  }

  getVisibleBedrooms() {
    return this.showAllBedrooms ? this.bedroomOptions : this.bedroomOptions.slice(0, 5);
  }

  bindFilters() {
    if (!this.payload || !this.payload.filterConditions) return;

    // Restore selected property types
    const typeFilter = this.payload.filterConditions.find(fc => fc.filterName === "type");
    if (typeFilter) {
      this.userfilter = [...typeFilter.filterValue];
      this.propertyTypes.forEach(pt => {
        pt.selected = this.userfilter.includes(pt.name);
      });
    }

    // Restore budget values
    const budgetFilter = this.payload.filterConditions.find(fc => fc.filterName === "budget");
    if (budgetFilter && budgetFilter.filterValue) {
      this.selectedMinValue = budgetFilter.filterValue.min;
      this.selectedMaxValue = budgetFilter.filterValue.max;
    }
  }

  ngOnInit(): void {
    // Fetch search_type from localStorage
    const savedSearchType = localStorage.getItem('search_type');
    if (savedSearchType) {
        const selectedTypes = savedSearchType.split(',');
        this.propertyTypes.forEach(propertyType => {
            if (selectedTypes.includes(propertyType.name)) {
                propertyType.selected = true;
                this.userfilter.push(propertyType.name);
                if (!this.selectedType.includes("type")) {
                    this.selectedType.push("type");
                }
            }
        });
    }

    // Fetch saved filters from localStorage
    const savedFilters = localStorage.getItem('Filter');
    if (savedFilters) {
        this.payload = JSON.parse(savedFilters);

        this.payload.filterConditions.forEach(filter => {
            if (filter.filterName === "noOfBadroom") {
                const selectedBedrooms = filter.filterValue.split(',');
                this.bedroomOptions.forEach(br => {
                    if (selectedBedrooms.includes(br.name)) {
                        br.selected = true;
                        this.userfilter.push(br.name);
                    }
                });
            }
        });
    }

    // Fetch `fromAsking` and `toAsking` from localStorage separately
    const savedMinValue = localStorage.getItem('fromAsking');
    const savedMaxValue = localStorage.getItem('toAsking');

    if (savedMinValue !== null) {
        this.selectedMinValue = parseInt(savedMinValue, 10);
    }
    if (savedMaxValue !== null) {
        this.selectedMaxValue = parseInt(savedMaxValue, 10);
    }

    // Ensure that the budget selection updates in the UI
    if (this.selectedMinValue !== null && this.selectedMaxValue !== null) {
        this.selectedBudget = `${this.formatCurrency(this.selectedMinValue)} - ${this.formatCurrency(this.selectedMaxValue)}`;
        this.userfilter.push(this.selectedBudget);
    }
}


clearSelection() {
    this.propertyTypes.forEach(pt => pt.selected = false);
    this.bedroomOptions.forEach(br => br.selected = false);
    this.userfilter = [];
    this.selectedType = [];
    this.selectedMinValue = null;
    this.selectedMaxValue = null;

    localStorage.removeItem('fromAsking');
    localStorage.removeItem('toAsking');
    localStorage.removeItem('Filter');
    localStorage.removeItem('search_type');

    this.updatePayload();
}
// ngOnDestroy() {
//   this.clearSelection()
// }

updatePayload() {
  this.payload = {
      filterConditions: [],
      sortConditions: [],
      searchValue: null,
      page: 0,
      size: 10
  };

  // Get selected property types, ensuring no duplicates
  const selectedPropertyTypes = Array.from(
      new Set(this.propertyTypes.filter(pt => pt.selected).map(pt => pt.name))
  ).join(',');

  // Remove existing "type" filter
  this.payload.filterConditions = this.payload.filterConditions.filter(fc => fc.filterName !== "type");

  // Only add "type" filter if it has a valid value and does not already exist
  if (selectedPropertyTypes && selectedPropertyTypes.trim().length > 0) {
      const existingTypeFilter = this.payload.filterConditions.find(fc => fc.filterName === "type");
      if (!existingTypeFilter || existingTypeFilter.filterValue !== selectedPropertyTypes) {
          this.payload.filterConditions.push({ filterName: "type", filterValue: selectedPropertyTypes });
      }
  }

  // Handle Bedroom Filters
  const selectedBedrooms = this.bedroomOptions.filter(br => br.selected).map(br => br.name).join(',');
  if (selectedBedrooms) {
      this.payload.filterConditions.push({ filterName: "noOfBadroom", filterValue: selectedBedrooms });
  }

  // Handle Min/Max Asking Price
  if (this.selectedMinValue !== null) {
      this.payload.filterConditions.push({ filterName: "fromAsking", filterValue: this.selectedMinValue.toString() });
      localStorage.setItem('fromAsking', this.selectedMinValue.toString());
  }

  if (this.selectedMaxValue !== null) {
      this.payload.filterConditions.push({ filterName: "toAsking", filterValue: this.selectedMaxValue.toString() });
      localStorage.setItem('toAsking', this.selectedMaxValue.toString());
  }

  // Reload the page with updated filters
  let currentUrl = this.router.url;
  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  this.router.onSameUrlNavigation = 'reload';
  this.router.navigate([currentUrl]);

  // Save to localStorage and emit event
  localStorage.setItem('Filter', JSON.stringify(this.payload));
  this.payloadEvent.emit(this.payload);
}



// updatePayload() {
//   // Build the payload object based on current selected values
//   this.payload = {
//     filterName: this.selectedType,
//     filterValue: this.userfilter,
//   };

//   // Add min and max budget if they are set
//   if (this.selectedMinValue !== null && this.selectedMaxValue !== null) {
//     this.payload.minBudget = this.selectedMinValue;
//     this.payload.maxBudget = this.selectedMaxValue;
//   }

//   // Emit the payload and log it for verification
//   console.log(this.payload);
//   this.payloadEvent.emit(this.payload);
// }





  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }





}
