import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { HomeService } from 'src/app/_services/home.service';
import { ContactemailComponent } from 'src/app/contactemail/contactemail.component';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ErrorMessageService } from 'src/app/services/error-message.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/_services/loading.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  disableEmail: boolean = false;
  EmailId: string | null = null;
  myForm: FormGroup;
  submitted = false;
  isEmailVerified: boolean = false;
  isProfileEmail: boolean = false; // New flag to check if email is from profile

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private authService: AuthService,
    private homeService: HomeService,
    private errorMessageService:ErrorMessageService,
    private router: Router,
    private toastr:ToastrService,
    private loadingService:LoadingService
  ) { }

  ngOnInit() {
    this.getPhoneCode()
    this.myForm = this.formBuilder.group({
      email: ['', [Validators.email ,Validators.required]],
      mobileNumber: ['',],
      mobileCode:[''],
      description: ['', Validators.required]
    });

    const profileData = localStorage.getItem('UserProfile');
    // console.log('profileData',profileData);
    

    if (profileData) {
      const parsedProfileData = JSON.parse(profileData);
      console.log('parsedProfileData',parsedProfileData);
      
      this.selectedPhoneCode = parsedProfileData.payload.payload.mobileCode ? parsedProfileData.payload.mobileCode : "+91";
      console.log('parsedProfileData',parsedProfileData.payload.mobileCode)
      this.myForm.patchValue({
        email: parsedProfileData.payload.payload.email,
        mobileCode: this.selectedPhoneCode
      });
      this.isEmailVerified = true; // Enable the button if profile data exists
      this.isProfileEmail = true;  // Set flag to true if email is from profile
    }
  }
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10); // Limit to 10 digits
    }
  }
  onEmailInputBlur(event: FocusEvent) {
    // const inputElement = event.target as HTMLInputElement;
    // const emailId = inputElement.value;

    // if (!emailId) {
    //   console.error("Email ID is empty or undefined.");
    //   return;
    // }
    const emailId = this.myForm.get('email')?.value;

    if (!emailId) {
      console.error("Email ID is empty or undefined.");
      return;
    }

    this.authService.sendemailotp(emailId).subscribe(
      (res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.showModal(emailId);
        }
        else if (res['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(res['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          }
      },
      // error => {
      //   console.error("Error sending email OTP:", error);
      // }
      (error) => {
        const message = error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }


      }
    );
  }

  showModal(email: string): void {
    const dialogRef = this.dialog.open(ContactemailComponent, {
      width: '450px',
      data: { email: email },
      disableClose: false
    });

    dialogRef.componentInstance.emailVerified.subscribe((status: boolean) => {
      if (status) {
        this.disableEmail = true;
        this.EmailId = email;
        this.myForm.get('email')?.setValue(email);
        this.isEmailVerified = true; // Set email as verified when successful
      }
    });
  }

  get f() {
    return this.myForm.controls;
  }

  // onSubmit() {
  //   if (this.myForm.valid) {
  //     const payload = this.myForm.value;
  //     this.postQuery(payload);
  //   } else {
  //     console.error('Form is invalid');
  //   }
  // }
  onSubmit() {
    this.submitted = true;

    if (this.myForm.invalid) {
      Object.keys(this.myForm.controls).forEach(field => {
        const control = this.myForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
      console.error('Form is invalid');
      return;
    }

    const payload = this.myForm.value;
    this.postQuery(payload);
  }



  postQuery(payload: any) {
    this.homeService.postQuery(payload).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {


          Swal.fire({
            icon: 'success',
            title: 'Your Query Has Been Successfully Submitted.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });


          this.router.navigate(['/']);
        }     else if (response['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }

  mobileCode: string[] = [];
  selectedPhoneCode: string = "+91"; // Always default to +91

  getPhoneCode() {
    this.authService.getphonecode().subscribe(
      (response: any) => {
        if (response && response.errorCode === 0 && response.payload) {
          this.mobileCode = response.payload
            .filter((item: any) => item.phoneCode)
            .map((item: any) => `+${item.phoneCode}`);

          console.log("Filtered Mobile Codes:", this.mobileCode);

          // Ensure +91 is always included and selected
          if (!this.mobileCode.includes("+91")) {
            this.mobileCode.unshift("+91"); // Add +91 if not in the list
          }

          // this.selectedPhoneCode = "+91"; // Always select +91
        }
      },
      (error) => {
        console.error("Error fetching phone codes:", error);
        // this.selectedPhoneCode = "+91"; // Fallback in case of error
      }
    );
  }




}
