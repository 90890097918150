<div class="card " *ngIf="cardData.type !== 'viewAll';">
  <div class="row col-12" (click)="viewDetails($event)">
    <img *ngIf="cardData?.mediaGroup?.length > 0; else checkBanner" 
         [src]="cardData.mediaGroup[0].url" 
         class="Images">
  
    <ng-template #checkBanner>
      <img src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg" 
           class="Images">
    </ng-template>
  </div>
  <div class="row col-12">
    <div class="col-md-12 d-flex flex-column justify-content-start" (click)="viewDetails($event)">
      <span class="title">{{ cardData.title | capitalize }}</span>
    </div>
 
  </div> 
  
  <div class="row col-12 mt-1" >
    <!-- <div class="col-md-12 d-flex flex-column justify-content-start">
      <span class="text" [innerHTML]="(cardData.description | truncateWords: 12)"></span>
    </div> -->
    <div class="listing-description">
        <span class="description-text" [innerHTML]="cardData.description | safeHtml"></span>
      </div>
  </div>
  
  <div class="row col-12 ">
    <div class="col-12 d-flex align-items-center justify-content-between text-nowrap">
        <span class="title">{{ cardData.asking | formatNumber }}</span>
        
        <span *ngIf="cardData?.feature"><span class="title">Size:</span> {{ cardData.feature.size }}&nbsp;{{ cardData.feature.sizeUnit }}</span>
    </div>
</div>
  <div class="col-md-12 mt-1" >
    <div>{{ cardData.details }}</div>
    <div class="target" style="padding-left: 1%;">{{ cardData.cityName }} &nbsp;{{ cardData.stateName }}</div>
  </div>
  
  <div class="row col-12">
    <div class="col-md-8 d-flex flex-column justify-content-start mb-1 mb-md-0">
      <span><span class="title3">Posted By Owner : </span>{{ cardData.createdAt | timeAgo }}</span>
    </div>
    <div class="col-md-4 d-flex flex-column justify-content-start d-flex" (click)="viewDetails($event)">
      <button class="styled-button viewAll">View</button>
    </div>
  </div>
  

</div>