import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../_services/home.service';

@Component({
  selector: 'app-project-inventory',
  templateUrl: './project-inventory.component.html',
  styleUrls: ['./project-inventory.component.scss']
})
export class ProjectInventoryComponent implements OnInit {
  projectId: string | null = null;
  showTopIcon = false; 
  searchValue: string = '';
  IsLoading: boolean = false;
  topList: any[] = [];  // Full dataset
  filteredListings: any[] = []; // Sliced dataset for display

  showButton: boolean;
  builder: boolean | string = '';
  
  currentPage: number = 0;
  pageSize: number = 10;  
  totalPages: number = 0;  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.projectId = params.get('projectId');
      if (this.projectId) {
        localStorage.setItem('projectId', this.projectId);
      }
      this.projectId = localStorage.getItem("projectId");
      this.route.queryParams.subscribe(params => {
        this.showButton = params['showButton'] === 'false' ? false : true;
      });
      this.apiService();
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToAddInventory() {
    let userinfo = localStorage.getItem("auth-token");
    if (userinfo) {
      this.router.navigate(['/add-inventory'], { queryParams: { projectId: this.projectId } });
    } else {
      this.router.navigate(['/login']);
    }
  }

  apiService() {
    this.IsLoading = true;
    this.homeService.getInventoryByProjectId(this.projectId).subscribe((res: any) => {
      this.IsLoading = false;
      if (res.status === 'SUCCESS' && res.payload) {
        this.topList = res.payload;
        this.totalPages = Math.ceil(this.topList.length / this.pageSize);
        this.updatePagination(); 
      } else {
        this.topList = [];  
        this.filteredListings = [];
      }
    },
    () => {
      this.IsLoading = false;
      this.topList = []; 
      this.filteredListings = [];
    });
  }

  updatePagination(): void {
    const startIndex = this.currentPage * this.pageSize;
    this.filteredListings = this.topList.slice(startIndex, startIndex + this.pageSize);
  }

  searchListings(): void {
    const searchTerm = this.searchValue.toLowerCase().trim();
    this.filteredListings = this.topList.filter((listing) => {
      const normalizedTitle = listing.title.replace(/\s+/g, ' ').toLowerCase().trim();
      return normalizedTitle.includes(searchTerm);
    });
    this.currentPage = 0;
    this.totalPages = Math.ceil(this.filteredListings.length / this.pageSize);
    this.updatePagination();
  }

  clearSearch(): void {
    this.searchValue = '';
    this.filteredListings = [...this.topList];
    this.totalPages = Math.ceil(this.topList.length / this.pageSize);
    this.updatePagination();
  }

  changePage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.updatePagination();
    }
  }

  getPages(): number[] {
    const pages: number[] = [];
    const start = Math.max(this.currentPage - 2, 0); 
    const end = Math.min(start + 4, this.totalPages - 1);
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }
}
